import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Table } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { NavLink, Link, useHistory } from 'react-router-dom';
import {
  setSelectedProjectId,
  setProjectsList,
  setProjectEndDate,
} from 'redux/actions/projects';
import {
  deleteMultipleProjects,
  getProjects,
} from 'utils/api/api-requests/admin';
import { setProjectEditModal } from 'redux/actions/modals';
import { toggleDate, updateStateArray } from 'utils/helper-functions';
import { TIMESTAMP_WITH_ZONE } from 'constants/index';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import AssociatedUserRow from './AssociatedEmployeeRow';

const ProjectTable = ({ projects, areProjectsBeingLoaded }) => {
  const [toggledCreationDates, setToggledCreationDates] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  let currentDate = moment().format('YYYY-MM-DD');

  const { workspaceAlias } = useSelector((state) => state);

  const deleteMultipleProjectsHandler = () => {
    if (selectedProjects?.length > 0) {
      deleteMultipleProjects(selectedProjects)
        .then(() => {
          setDeleteModal(false);
          setSelectedProjects([]);
          toast.success('Projects deleted successfully');
          getProjects()
            .then(({ data }) => {
              dispatch(setProjectsList(data));
            })
            .catch(() => {
              toast?.error('Unable to fetch project list.');
            });
        })
        .catch(() => {
          toast.error('Unable to delete selected projects');
        });
    }
  };
  useEffect(() => {
    const intervalId = setInterval(
      () => setCurrentTime(moment(new Date())),
      60000
    );
    return () => clearInterval(intervalId);
  }, []);
  const history = useHistory();
  return (
    <>
      {areProjectsBeingLoaded ? (
        <div className='logs-spinner'>
          <Spinner color='admin' />
        </div>
      ) : projects?.length > 0 ? (
        <>
          {selectedProjects?.length > 0 ? (
            <div className='admin-action-bar actions-bar'>
              <div className='delete-div'>
                <i
                  className='fas fa-trash-alt p-1'
                  title='delete'
                  onClick={() => setDeleteModal(true)}
                />
              </div>
            </div>
          ) : (
            ''
          )}
          <Table
            className='align-items-center table-flush project-table-class'
            responsive
          >
            <thead className='thead-light'>
              <tr>
                <th className='pl-pt-0'>
                  <div className='custom-control custom-checkbox'>
                    <input
                      className='custom-control-input'
                      id='customCheck'
                      type='checkbox'
                      checked={projects?.length === selectedProjects?.length}
                      onClick={(e) => {
                        setSelectedProjects(
                          e?.target?.checked
                            ? projects?.map(({ uid }) => uid)
                            : []
                        );
                      }}
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='customCheck'
                    ></label>
                  </div>
                </th>
                <th scope='col'>Name</th>
                <th scope='col'>Client</th>
                <th scope='col'>Manager</th>
                <th scope='col'>Commenced</th>
                <th scope='col'>Logs</th>
                <th scope='col'>Associated Employees</th>
                <th className='w-5' scope='col' />
              </tr>
            </thead>
            <tbody>
              {projects?.map(
                ({
                  uid,
                  name,
                  start_date,
                  end_date,
                  managers,
                  client,
                  created_at,
                }) => (
                  <tr
                    key={uid}
                    className={
                      currentDate <
                        moment(start_date, 'YYYY-MM-DD').format('YYYY-MM-DD') ||
                      currentDate >
                        moment(end_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
                        ? 'pink-row'
                        : ''
                    }
                  >
                    <td className='pl-pt-0'>
                      <div className='custom-control custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id={uid}
                          type='checkbox'
                          checked={selectedProjects?.includes(uid)}
                          onClick={() =>
                            updateStateArray({
                              array: selectedProjects,
                              setArray: setSelectedProjects,
                              value: uid,
                            })
                          }
                        />
                        <label
                          className='custom-control-label'
                          htmlFor={uid}
                        ></label>
                      </div>
                    </td>
                    <td>
                      <NavLink
                        className='custom-link-class admin-theme-color'
                        activeClassName='custom-link-class-active'
                        to={`/${workspaceAlias}/admin/project/${uid}/details`}
                      >
                        {name}
                      </NavLink>
                    </td>
                    <td>
                      {client?.name ? (
                        <NavLink
                          className='custom-link-class admin-theme-color'
                          activeClassName='custom-link-class-active'
                          to={`/${workspaceAlias}/admin/clients/${client?.uid}`}
                        >
                          {client?.name}
                        </NavLink>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>{managers?.[0]?.name || '-'}</td>
                    <td>
                      <span
                        className='toggled-date'
                        onClick={() =>
                          toggleDate({
                            uid,
                            array: toggledCreationDates,
                            setArray: setToggledCreationDates,
                          })
                        }
                      >
                        {start_date
                          ? toggledCreationDates?.includes(uid)
                            ? moment(start_date, 'YYYY-MM-DD').format('LL')
                            : moment(
                                moment(
                                  start_date +
                                    moment(
                                      created_at,
                                      'YYYY-MM-DDTHH:mm:ssZ'
                                    ).format('HH:mm:ss'),
                                  TIMESTAMP_WITH_ZONE
                                ).format(TIMESTAMP_WITH_ZONE)
                              ).from(moment(currentTime))
                          : '-'}
                      </span>
                    </td>
                    <td className='justify-content-end text-default'>
                      {currentDate <
                      moment(start_date, 'YYYY-MM-DD').format('YYYY-MM-DD') ? (
                        '-'
                      ) : (
                        <Link
                          className='custom-link-class admin-theme-color'
                          to={`/${workspaceAlias}/admin/project/${uid}/logs`}
                        >
                          view logs
                        </Link>
                      )}
                    </td>
                    <td>
                      <AssociatedUserRow projectName={name} projectId={uid} />
                    </td>
                    <td>
                      <div className='d-flex justify-content-between'>
                        <i
                          title='Associate employee'
                          class='fas fa-user-plus fa-lg text-admin'
                          onClick={() =>
                            history.push(
                              `/${workspaceAlias}/admin/project/${uid}`
                            )
                          }
                        />{' '}
                        <i
                          className='fas fa-pencil-alt fa-lg text-admin'
                          onClick={() => {
                            dispatch(setProjectEditModal(true));
                            dispatch(setSelectedProjectId(uid));
                            dispatch(setProjectEndDate(end_date));
                          }}
                          title='edit'
                        />
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </>
      ) : (
        <div className='no-data-div'>There are no projects yet</div>
      )}
      <CustomModal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        headerText='Delete Projects'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={deleteMultipleProjectsHandler}
      >
        Are you sure you want to delete selected projects?
      </CustomModal>
    </>
  );
};

export default ProjectTable;
