export const setCurrentUserRole = (userRole) => ({
  type: 'SET_CURRENT_USER_ROLE',
  payload: userRole,
});
export const setHasForgotPassword = (password) => ({
  type: 'SET_HAS_FORGOT_PASSWORD',
  payload: password,
});
export const setMploUser = (mploUser) => ({
  type: 'SET_MPLO_USER',
  payload: mploUser,
});
export const setWorkspaceAlias = (workspaceAlias) => ({
  type: 'SET_WORKSPACE_ALIAS',
  payload: workspaceAlias,
});
export const setNewWorkspaceObject = (
  workspaceObject = { name: '', alias: '' }
) => ({
  type: 'SET_WORKSPACE_OBJECT',
  payload: workspaceObject,
});

export const setWorkspaceModal = (modal) => ({
  type: 'SET_WORKSPACE_MODAL',
  payload: modal,
});
