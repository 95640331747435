import React, { useState } from 'react';
import {
  updateLog,
  getLogsUser,
  editTrackingLog,
} from 'utils/api/api-requests/user';
import moment from 'moment';
import '../Logs.scss';
import { toast } from 'react-toastify';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import CustomSlider from 'Components/Common/CustomSlider/CustomSlider';
import {
  getCustomProjectLogs,
  createLogApiObject,
} from 'utils/helper-functions';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCustomSliderArray,
  setReasonForLateSitting,
  setTimeLogEndTime,
  setTimeLogStartTime,
  setWorkedOnWhat,
} from 'redux/actions/projects';
import {
  setProjectLogsList,
  setLogEditModal,
  setLogID,
  setLogDate,
  setAreLogsBeingLoaded,
} from 'redux/actions/logs';

const EditModal = () => {
  const isEditingTrackingLog = useSelector(
    ({ isEditingTrackingLog }) => isEditingTrackingLog
  );

  const dispatch = useDispatch();

  const {
    selectedProjectId,
    fromDateFilter,
    toDateFilter,
    workedOnWhat,
    timeLogStartTime,
    timeLogEndTime,
    logDate,
    reasonForLateSitting,
    logEditModal,
    logID,
  } = useSelector((state) => state);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const toggleEdit = (e) => {
    e.preventDefault();
    dispatch(setCustomSliderArray([]));
    dispatch(setTimeLogStartTime(540));
    dispatch(
      setTimeLogEndTime(
        moment().format('YYYY-MM-DD') ===
          moment(logDate).format('YYYY-MM-DD') &&
          moment().minutes() + moment().hours() * 60 < 1080
          ? (Math.ceil(moment().minutes() + moment().hours() * 60 - 1) / 10) *
              10
          : 1080
      )
    );
    dispatch(setWorkedOnWhat(''));
    dispatch(setReasonForLateSitting(''));
    dispatch(setLogID(-1));
    dispatch(setLogDate(moment.utc().format('YYYY-MM-DD')));
    dispatch(setLogEditModal(!logEditModal));
    setIsSubmitButtonClicked(false);
  };

  const editProjectLogHandler = (e) => {
    setIsSubmitButtonClicked(true);
    e?.preventDefault();
    if (
      workedOnWhat &&
      isDataChanged &&
      (timeLogEndTime - timeLogStartTime > 540
        ? reasonForLateSitting
          ? true
          : false
        : true)
    ) {
      const projectLog = createLogApiObject(
        timeLogStartTime,
        timeLogEndTime,
        workedOnWhat,
        timeLogEndTime - timeLogStartTime > 540 ? reasonForLateSitting : '',
        logDate,
        isEditingTrackingLog
      );
      dispatch(setAreLogsBeingLoaded(true));
      if (!isEditingTrackingLog) {
        updateLog(logID, selectedProjectId, projectLog)
          .then(() => {
            toast.success('Project log updated successfully.');
            toggleEdit(e);
            getLogsUser(
              selectedProjectId,
              moment(fromDateFilter).format('YYYY-MM-DD'),
              moment(toDateFilter).format('YYYY-MM-DD')
            )
              .then(({ data }) => {
                dispatch(setProjectLogsList(getCustomProjectLogs(data, false)));
                dispatch(setLogEditModal(false));
                dispatch(setAreLogsBeingLoaded(false));
              })
              .catch(() => {
                toast.error('Unable to fetch project logs.');
                dispatch(setLogEditModal(false));
                dispatch(setAreLogsBeingLoaded(false));
              });
          })
          .catch(() => {
            toast.error('Unable to update project log.');
            dispatch(setLogEditModal(false));
            dispatch(setAreLogsBeingLoaded(false));
          });
      } else {
        editTrackingLog(logID, selectedProjectId, workedOnWhat, false)
          .then(() => {
            toast.success('Project log updated successfully.');
            toggleEdit(e);
            getLogsUser(
              selectedProjectId,
              moment(fromDateFilter).format('YYYY-MM-DD'),
              moment(toDateFilter).format('YYYY-MM-DD')
            )
              .then(({ data }) => {
                dispatch(setProjectLogsList(getCustomProjectLogs(data, false)));
                dispatch(setLogEditModal(false));
                dispatch(setAreLogsBeingLoaded(false));
              })
              .catch(() => {
                toast.error('Unable to fetch project logs.');
                dispatch(setLogEditModal(false));
                dispatch(setAreLogsBeingLoaded(false));
              });
          })
          .catch(() => {
            toast.error('Unable to update project log.');
            dispatch(setLogEditModal(false));
            dispatch(setAreLogsBeingLoaded(false));
          });
      }
    }
  };

  return (
    <>
      <CustomModal
        isOpen={logEditModal}
        toggle={() => dispatch(setLogEditModal(!logEditModal))}
        headerText='Edit Log'
        confirmFunction={editProjectLogHandler}
        confirmButtonText='Submit'
        cancelButtonText='Cancel'
        customClass='time-log-modal'
      >
        <Form className='needs-validation' noValidate>
          <FormGroup className='slider-form edit-slider-form'>
            <div className='slider-component-wrapper'>
              <CustomSlider
                index={100}
                disabled={isEditingTrackingLog}
                min={timeLogStartTime}
                max={timeLogEndTime}
                multipleLogs={false}
                sliderDataChangedHandler={setIsDataChanged}
              />
            </div>
          </FormGroup>

          <div>
            <FormGroup
              className={`mr-3 form-group-w-100 ${
                !workedOnWhat && isSubmitButtonClicked
                  ? 'custom-invalid-feedback-date'
                  : ''
              }`}
            >
              <Label
                className={`${
                  !workedOnWhat && isSubmitButtonClicked
                    ? 'custom-invalid-label'
                    : ''
                }`}
                for='work'
              >
                Add details of your work
              </Label>
              <Input
                type='textarea'
                name='work'
                value={workedOnWhat}
                placeholder='Write complete detail of your work here...'
                className='work-details-text-area'
                rows='4'
                required
                onChange={(e) => {
                  dispatch(setWorkedOnWhat(e?.target?.value));
                  setIsDataChanged(true);
                }}
              />
              <div
                className={`custom-invalid-feedback-text text-sm ${
                  !workedOnWhat && isSubmitButtonClicked ? '' : 'hidden-class'
                }`}
              >
                <i className='fas fa-exclamation-circle' />{' '}
                <span>Please fill this field</span>
              </div>
            </FormGroup>
            {timeLogEndTime - timeLogStartTime > 540 ? (
              <FormGroup
                className={`form-group-w-100 ${
                  !reasonForLateSitting &&
                  isSubmitButtonClicked &&
                  timeLogEndTime - timeLogStartTime > 540
                    ? 'custom-invalid-feedback-date'
                    : ''
                }`}
              >
                <Label
                  className={`${
                    !reasonForLateSitting &&
                    isSubmitButtonClicked &&
                    timeLogEndTime - timeLogStartTime > 540
                      ? 'custom-invalid-label'
                      : ''
                  }`}
                  for='lateSitting'
                >
                  Reason For Late Sitting
                </Label>
                <Input
                  type='textarea'
                  name='lateSitting'
                  value={reasonForLateSitting}
                  placeholder='Write a specific/genuine reason for working late...'
                  className='late-sitting-text-area'
                  rows='3'
                  required
                  onChange={(e) =>
                    dispatch(setReasonForLateSitting(e?.target?.value))
                  }
                />
                <div
                  className={`custom-invalid-feedback-text text-sm ${
                    !reasonForLateSitting && isSubmitButtonClicked
                      ? ''
                      : 'hidden-class'
                  }`}
                >
                  <i className='fas fa-exclamation-circle' />{' '}
                  <span>Please fill this field</span>
                </div>
              </FormGroup>
            ) : (
              ''
            )}
            <div
              className={`custom-invalid-feedback-text text-md ${
                isSubmitButtonClicked && !isDataChanged ? '' : 'hidden-class'
              }`}
            >
              <i className='fas fa-exclamation-circle' />{' '}
              <span>Please change values </span>
            </div>
          </div>
        </Form>
      </CustomModal>
    </>
  );
};

export default EditModal;
