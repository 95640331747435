import React from 'react';
import { Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import AdminCard from './AdminCard';
import { useSelector } from 'react-redux';

const Header = () => {
  const history = useHistory();
  const { workspaceAlias } = useSelector((state) => state);
  return (
    <div
      className={`header bg-gradient-admin pb-7 pt-5 ${
        history.location.pathname === `/${workspaceAlias}/admin/index`
          ? 'pt-md-7'
          : 'pt-md-4'
      }`}
    >
      <Container fluid>
        <div className='header-body'>
          {history.location.pathname === `/${workspaceAlias}/admin/index` ? (
            <AdminCard />
          ) : (
            ''
          )}
        </div>
      </Container>
    </div>
  );
};

export default Header;
