import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { signup, register } from 'utils/api/api-requests/auth';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import VerificationCode from 'Components/Common/VerificationCode/VerificationCode';
import { login } from 'utils/api/api-requests/auth';
import { setNewWorkspaceObject } from 'redux/actions/general';
import { useDispatch } from 'react-redux';
import { useQuery } from 'utils/helper-functions';
import { validateAlias } from 'utils/api/api-requests/auth';

const Signup = () => {
  let query = useQuery(useLocation);
  const [email, setEmail] = useState(query?.get('user_email'));
  const [workspaceName, setWorkspaceName] = useState('');
  const [aliasFlag, setAliasFlag] = useState(false);
  const [message, setMessage] = useState(
    'Name must contain atleast 5 characters'
  );
  const [code, setCode] = useState('');
  const [signupFlag, setSignupFlag] = useState(false);
  const [confirmInviteFlag, setConfirmInviteFlag] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const setEmailHandler = (value) => {
    setEmail(value);
    setIsSubmitButtonClicked(false);
  };
  const setWorkspaceNameHandler = (value) => {
    setWorkspaceName(value);
    setIsSubmitButtonClicked(false);
    setAliasFlag(false);
  };

  const passwordConfirmationHandler = (e) => {
    e.preventDefault();
    const signup = {
      email,
      password,
    };
    register(signup, code)
      .then(() => {
        login({ email, password }).then((resp) => {
          localStorage.setItem('jwt-token', resp.headers['jwt-token']);
          let action = localStorage.getItem('workspaceAction');
          let name = '',
            alias = '';
          if (action === 'add') {
            name = localStorage.getItem('addWorkspace');
            alias = localStorage.getItem('addWorkspaceAlias');
          } else if (action === 'join') {
            alias = localStorage.getItem('joinWorkspaceAlias');
          }
          dispatch(
            setNewWorkspaceObject({
              name,
              alias,
              action,
            })
          );
          history.push(`/redirect/${alias}/${resp.headers['jwt-token']}`);
        });
        setSignupFlag(false);
      })
      .catch(() => {
        toast.error('Error! unable to register');
      });
  };

  const signupHandler = (event) => {
    event.preventDefault();
    setIsSubmitButtonClicked(true);
    if (['join', 'add']?.includes(localStorage.getItem('workspaceAction'))) {
      let reqEmail = {
        email,
      };
      signup(reqEmail)
        .then(() => {
          setMessage(`Code has been sent to ${email}`);
          setIsSubmitButtonClicked(false);
          setSignupFlag(true);
        })
        .catch((error) => {
          if (error === 'user already exists') {
            setMessage('user already exists redirecting to login');
            setTimeout(() => history.push('/auth/login'), 1000);
          } else setMessage(`Invalid email`);
          setSignupFlag(false);
        });
    } else {
      if (workspaceName && workspaceName?.length > 5) {
        const alias = workspaceName
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, '')
          .slice(0, 12);
        validateAlias(alias)
          .then(() => {
            let reqEmail = {
              email,
            };
            signup(reqEmail)
              .then(() => {
                setMessage(`Code has been sent to ${email}`);
                setIsSubmitButtonClicked(false);
                setAliasFlag(false);
                setSignupFlag(true);
                localStorage.setItem('addWorkspace', workspaceName);
                localStorage.setItem('addWorkspaceAlias', alias);
                localStorage.setItem('newUserFlag', 'true');
                localStorage.setItem('workspaceAction', 'add');
              })
              .catch((error) => {
                if (error === 'user already exists') {
                  setMessage('user already exists redirecting to login');
                  setTimeout(() => history.push('/auth/login'), 1000);
                } else setMessage(`Invalid email`);
                setSignupFlag(false);
              });
          })
          .catch(() => {
            setAliasFlag(true);
            setMessage('Name not available! Please try some other name');
          });
      } else {
        setAliasFlag(true);
        setMessage('Name must contain atleast 5 characters');
      }
    }
  };
  return (
    <>
      {!signupFlag && !confirmInviteFlag ? (
        <>
          <div>
            <CustomFormGroup
              id='email'
              placeholder='Email'
              type='text'
              name='loginmail'
              value={email}
              setValue={setEmailHandler}
              iconClassName='ni ni-email-83'
              didSubmitButtonClick={isSubmitButtonClicked}
              invalidText='Invalid email address'
            />
          </div>
          {!['join', 'add']?.includes(
            localStorage.getItem('workspaceAction')
          ) ? (
            <>
              <div
                className={isSubmitButtonClicked && aliasFlag ? 'error' : ''}
              >
                <CustomFormGroup
                  id='workspace'
                  placeholder='Workspace'
                  type='text'
                  name='workspace'
                  value={workspaceName}
                  setValue={setWorkspaceNameHandler}
                  iconClassName='fas fa-network-wired'
                  didSubmitButtonClick={isSubmitButtonClicked}
                  invalidTextFlag={true}
                />
              </div>
              <div
                className={`custom-invalid-feedback-text mt--3
          ${isSubmitButtonClicked && aliasFlag ? '' : 'hidden-class'}`}
              >
                <i className='fas fa-exclamation-circle' /> {message}
              </div>
            </>
          ) : (
            ''
          )}

          <div className='custom-input-group'>
            <input
              className='custom-fw-btn'
              type='submit'
              value='SIGNUP'
              onClick={signupHandler}
            />
          </div>

          <div className='signin-links'>
            <a
              href='# '
              onClick={() => history.push('/auth/login')}
              className='link'
            >
              Already have an account?
            </a>
          </div>
        </>
      ) : signupFlag && !confirmInviteFlag ? (
        <>
          <VerificationCode
            signupEmail={email}
            setCode={setCode}
            setConfirmInviteFlag={setConfirmInviteFlag}
            signupFlag={signupFlag}
            setSignupFlag={setSignupFlag}
          />
        </>
      ) : confirmInviteFlag && !signupFlag ? (
        <>
          <CustomFormGroup
            type='password'
            name='loginPassword'
            id='loginPassword'
            placeholder='Password'
            value={password}
            setValue={setPassword}
            iconClassName='fas fa-lock'
            invalidText='Password is empty'
            didSubmitButtonClick={isSubmitButtonClicked}
          />
          <CustomFormGroup
            type='password'
            name='confirmPassword'
            id='confirmPassword'
            placeholder='Confirm Password'
            value={confirmPassword}
            setValue={setConfirmPassword}
            iconClassName='ni ni-lock-circle-open'
            invalidText='Password is empty'
            didSubmitButtonClick={isSubmitButtonClicked}
          />

          <div className='custom-input-group'>
            <input
              className='custom-fw-btn'
              type='submit'
              value='Confirm'
              onClick={passwordConfirmationHandler}
            />
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default Signup;
