const sideBarLinks = [
  {
    path: '/index',
    name: 'Dashboard',
    icon: 'ni ni-shop admin-theme-color',
    layout: `/admin`,
  },
  {
    path: '/integration',
    name: 'Integration',
    icon: 'ni ni-key-25 admin-theme-color',
    layout: `/admin`,
  },
  {
    path: '/index',
    name: 'Dashboard',
    icon: 'ni ni-shop admin-theme-color',
    layout: `/user`,
  },
  {
    path: '/manage',
    name: 'Manage',
    icon: 'ni ni-settings user-theme-color',
    layout: `/user`,
  },
  {
    path: '/activity',
    name: 'Activity',
    icon: 'fas fa-tasks user-theme-color',
    layout: `/user`,
  },

  {
    collapse: true,
    name: 'Workspace',
    icon: 'ni ni-settings admin-theme-color',
    state: 'manageCollapse',
    views: [
      {
        path: '/users',
        name: 'Employees',
        layout: `/admin`,
      },
      {
        path: '/projects',
        name: 'Projects',
        layout: `/admin`,
      },
      {
        path: '/clients',
        name: 'Clients',
        layout: `/admin`,
      },
      {
        path: '/activity',
        name: 'Activity',
        layout: `/admin`,
      },
    ],
  },
  {
    path: '/password-reset',
    name: 'Change Password',
    icon: 'ni ni-settings-gear-65 admin-theme-color',
    layout: '/auth',
  },
  {
    path: '/login',
    name: 'Logout',
    icon: 'ni ni-user-run admin-theme-color',
    layout: '/auth',
  },
];

export default sideBarLinks;
