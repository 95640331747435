import { makeAPICall } from 'utils/api/api-utils';
import {
  SIGNUP,
  LOGIN,
  VERIFY_INVITE,
  VALIDATE_ALIAS,
  ADD_WORKSPACE,
  GET_WORKSPACES,
  GET_WORKSPACE_INVITES,
  REGISTER,
  JOIN_WORKSPACE_INVITE,
  RESET_PASSWORD_REQUEST,
  VERIFY_RESET_PASSWORD_TOKEN,
  RESET_PASSWORD,
  LOGIN_WITH_EMAIL,
  SEND_LOGIN_EMAIL,
  UPDATE_PASSWORD,
} from 'utils/api/api-urls/auth';

export const login = (login) =>
  makeAPICall({
    method: 'POST',
    url: LOGIN,
    data: login,
  });

export const loginWithEmail = (login) =>
  makeAPICall({
    method: 'POST',
    url: LOGIN_WITH_EMAIL,
    data: login,
  });

export const sendLoginEmail = (email) =>
  makeAPICall({
    method: 'POST',
    url: SEND_LOGIN_EMAIL,
    data: email,
  });

export const signup = (email) =>
  makeAPICall({
    method: 'POST',
    url: SIGNUP,
    data: email,
  });

export const verifyInvite = (token) =>
  makeAPICall({
    url: VERIFY_INVITE(token),
  });

export const validateAlias = (alias) =>
  makeAPICall({
    url: VALIDATE_ALIAS(alias),
    authorizedRequest: true,
  });

export const addWorkspace = (workspace) =>
  makeAPICall({
    method: 'POST',
    url: ADD_WORKSPACE,
    authorizedRequest: true,
    data: workspace,
  });

export const getWorkspaces = () =>
  makeAPICall({
    url: GET_WORKSPACES,
    authorizedRequest: true,
  });
export const getWorkspaceInvites = () =>
  makeAPICall({
    url: GET_WORKSPACE_INVITES,
    authorizedRequest: true,
  });

export const register = (signup, token) =>
  makeAPICall({
    method: 'POST',
    url: REGISTER(token),
    data: signup,
  });

export const acceptWorkspaceInvite = (alias) =>
  makeAPICall({
    method: 'POST',
    url: JOIN_WORKSPACE_INVITE(alias),
    authorizedRequest: true,
  });

export const resetPasswordRequest = (email) =>
  makeAPICall({
    method: 'POST',
    url: RESET_PASSWORD_REQUEST(),
    data: email,
  });

export const verifyResetPasswordToken = (token) =>
  makeAPICall({
    url: VERIFY_RESET_PASSWORD_TOKEN(token),
  });

export const resetPassword = (token, credentials) =>
  makeAPICall({
    method: 'PATCH',
    url: RESET_PASSWORD(token),
    data: credentials,
  });

export const updatePassword = (password) =>
  makeAPICall({
    method: 'POST',
    url: UPDATE_PASSWORD,
    data: password,
    authorizedRequest: true,
  });
