export const employeeDataHr = (employeeData = '', { type, payload }) =>
  type === 'SET_EMPLOYEE_DATA_HR' ? payload : employeeData;

export const employeeEmergencyContactInfo = (
  employeeEmergencyContactInfo = '',
  { type, payload }
) =>
  type === 'SET_EMPLOYEE_EMERGENCY_CONTACT_INFO'
    ? payload
    : employeeEmergencyContactInfo;
export const employeeBankInfo = (employeeBankInfo = '', { type, payload }) =>
  type === 'SET_EMPLOYEE_BANK_INFO' ? payload : employeeBankInfo;
export const employeeRoleCustomSelectArray = (
  employeeRole = [],
  { type, payload }
) =>
  type === 'SET_EMPLOYEE_ROLE_CUSTOM_SELECT_ARRAY' ? payload : employeeRole;

export const userList = (userList = [], { type, payload }) =>
  type === 'SET_USER_LIST' ? payload : userList;
export const welcomeStatus = (status = false, { type, payload }) =>
  type === 'SET_WELCOME_STATUS' ? payload : status;

export const employeeData = (employeeData = '', { type, payload }) =>
  type === 'SET_EMPLOYEE_DATA' ? payload : employeeData;
