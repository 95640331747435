import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setClientList,
  setClientEditModal,
  setClientObject,
} from 'redux/actions/client';
import { getClients, updateClient } from 'utils/api/api-requests/admin';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import { Form } from 'reactstrap';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import { validate } from 'email-validator';
import validator from 'validator';
import { toast } from 'react-toastify';

const ClientModal = () => {
  const dispatch = useDispatch();
  const { clientEditModal } = useSelector((state) => state);
  const { clientObject } = useSelector((state) => state);
  const [clientEmail, setClientEmail] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientSecondaryEmail, setClientSecondaryEmail] = useState('');
  const [clientSecondaryPhone, setClientSecondaryPhone] = useState('');
  const [updatedFlag, setUpdatedFlag] = useState(false);
  const [didEditClientButtonClick, setDidEditClientButtonClicked] =
    useState(false);
  const [secondaryInfoFlag, setSecondaryInfoFlag] = useState(false);

  useEffect(() => {
    setClientName(clientObject?.name);
    setClientEmail(clientObject?.email);
    setClientPhone(clientObject?.contact_number ?? '');
    setClientSecondaryEmail(clientObject?.secondary_email);
    setClientSecondaryPhone(clientObject?.secondary_contact_number ?? '');
  }, [clientObject]);

  const validateCredentials = () =>
    clientName &&
    validate(clientEmail) &&
    validator.isMobilePhone(clientPhone) &&
    (secondaryInfoFlag
      ? validate(clientSecondaryEmail) &&
        validator.isMobilePhone(clientSecondaryPhone)
      : true);

  const handleEditClient = (e) => {
    e?.preventDefault();
    setDidEditClientButtonClicked(true);
    if (validateCredentials() && updatedFlag) {
      setDidEditClientButtonClicked(false);
      clientModalToggler();
      const client = {
        name: clientName,
        email: clientEmail,
        secondary_email: secondaryInfoFlag ? clientSecondaryEmail : null,
        contact_number: clientPhone,
        secondary_contact_number: secondaryInfoFlag
          ? clientSecondaryPhone
          : null,
      };
      updateClient(clientObject?.uid, client)
        .then(() => {
          toast.success('Client updated successfully.');
          getClients()
            .then(({ data }) => dispatch(setClientList(data)))
            .catch((error) => toast.error(error));
        })
        .catch(() => toast.error('Failed to update client.'));
    }
  };

  const clientModalToggler = () => {
    dispatch(setClientEditModal(false));
    setClientEmail('');
    setClientName('');
    setClientPhone('');
    setDidEditClientButtonClicked(false);
    dispatch(setClientObject({}));
    setUpdatedFlag(false);
  };

  return (
    <CustomModal
      isOpen={clientEditModal}
      toggle={clientModalToggler}
      headerText='Update client'
      cancelButtonText='Cancel'
      confirmButtonText='Update'
      confirmFunction={handleEditClient}
    >
      <Form onKeyPress={(e) => (e?.key === 'Enter' ? handleEditClient(e) : {})}>
        <CustomFormGroup
          type='email'
          name='userEmail'
          placeholder='Email'
          value={clientEmail}
          iconClassName='ni ni-email-83'
          setValue={(value) => {
            setClientEmail(value);
            setUpdatedFlag(true);
          }}
          invalidText='Invalid email format'
          didSubmitButtonClick={didEditClientButtonClick}
          layout='admin'
        />

        <CustomFormGroup
          type='text'
          name='clientName'
          placeholder='Name'
          value={clientName}
          iconClassName='ni ni-single-02'
          setValue={(value) => {
            setClientName(value);
            setUpdatedFlag(true);
          }}
          invalidText=' Client name is empty'
          didSubmitButtonClick={didEditClientButtonClick}
          layout='admin'
        />

        <CustomFormGroup
          type='phoneNumber'
          name='clientPhone'
          placeholder='Phone number'
          value={clientPhone}
          iconClassName='fas fa-phone-square-alt'
          setValue={(value) => {
            setClientPhone(value);
            setUpdatedFlag(true);
          }}
          invalidText='Invalid Phone Number Format'
          didSubmitButtonClick={didEditClientButtonClick}
          layout='admin'
        />
        <div className='d-flex flex-row justify-content-end align-item-center mb-2'>
          <label className='mr-3 mb-0 custom-styled-label'>
            Secondary Information
          </label>
          <label className='mb-0 custom-toggle custom-parent-toggler'>
            <input
              type='checkbox'
              onClick={() => {
                setSecondaryInfoFlag(!secondaryInfoFlag);
                setDidEditClientButtonClicked(false);
              }}
            />
            <span className='custom-toggle-slider rounded-circle custom-child-toggler' />
          </label>
        </div>
        {secondaryInfoFlag ? (
          <>
            <CustomFormGroup
              type='text'
              name='secondaryEmail'
              placeholder='Secondary email'
              value={clientSecondaryEmail}
              iconClassName='far fa-envelope'
              setValue={(value) => {
                setClientSecondaryEmail(value);
                setUpdatedFlag(true);
              }}
              invalidText=' Client secondary email is empty'
              didSubmitButtonClick={didEditClientButtonClick}
              layout='admin'
            />

            <CustomFormGroup
              type='text'
              name='secondaryPhoneNumber'
              placeholder='Secondary phone number'
              value={clientSecondaryPhone}
              iconClassName='fas fa-phone'
              setValue={(value) => {
                setClientSecondaryPhone(value);
                setUpdatedFlag(true);
              }}
              invalidText=' Client secondary phone is empty'
              didSubmitButtonClick={didEditClientButtonClick}
              layout='admin'
            />
          </>
        ) : (
          ''
        )}
        {!updatedFlag && didEditClientButtonClick && validateCredentials() ? (
          <div className='custom-invalid-feedback-text'>
            You haven't updated any value. Please change any value to update the
            client.
          </div>
        ) : (
          ''
        )}
      </Form>
    </CustomModal>
  );
};

export default ClientModal;
