import React, { useState } from 'react';
import { Card, CardHeader, Col, Container, Row } from 'reactstrap';
import AdminHeader from '../../Headers/Admin/Admin';
import UserHeader from '../../Headers/User/User';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Input,
  Label,
} from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';

import './ActivityList.scss';
import CurrentUserActivity from './CurrentUserActivity';
import AllUsersActivity from './AllUsersActivity';
import { isAdminLoggedIn } from 'utils/helper-functions';
const ActivityList = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [fromDate, setFromDate] = useState(
    moment().subtract(1, 'days').format('YYYY-MM-DD')
  );
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className='main-content'>
      {isAdminLoggedIn() ? <AdminHeader /> : <UserHeader />}
      <Container className='mt--7' fluid>
        <Row className='mt-5 mb-5'>
          <Col className='mb-5' xl='12'>
            <Card className='shadow'>
              <CardHeader
                className={`border-0 date-${localStorage
                  .getItem('currentUserRole')
                  ?.toLowerCase()}-header `}
              >
                <Row className='align-items-center'>
                  <div className='col'>
                    <h3 className='mb-0'>Activity</h3>
                  </div>
                  <div className='activity-date-container'>
                    <div>
                      <Input
                        className='user-select-none'
                        type='date'
                        value={fromDate}
                        onChange={(e) => {
                          setFromDate(
                            moment(e?.target?.value).format('YYYY-MM-DD')
                          );
                          moment(e?.target?.value).format('YYYY-MM-DD') > toDate
                            ? setToDate(
                                moment(e.target.value)
                                  .add(1, 'days')
                                  .format('YYYY-MM-DD')
                              )
                            : setToDate(toDate);
                        }}
                        name='fromDate'
                        id='fromDate'
                        placeholder='date placeholder'
                      />
                    </div>

                    <div sm='0'>
                      <Label className='custom-label custom-date-to'>To</Label>
                    </div>
                    <div sm='0' className='d-flex'>
                      <Input
                        className='user-select-none'
                        type='date'
                        onChange={(e) =>
                          setToDate(
                            moment(e?.target?.value).format('YYYY-MM-DD')
                          )
                        }
                        min={moment(fromDate)
                          .add(1, 'day')
                          .format('YYYY-MM-DD')}
                        value={toDate}
                        name='toDate'
                        id='toDate'
                        placeholder='date placeholder'
                      />
                    </div>
                  </div>
                </Row>
              </CardHeader>
              {isAdminLoggedIn() ? (
                <>
                  <Nav tabs className='custom-nav-tabs'>
                    <NavItem>
                      <NavLink
                        className={`custom-nav-user-link ${classnames({
                          active: activeTab === '1',
                        })}`}
                        onClick={() => {
                          toggle('1');
                        }}
                      >
                        your activity
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      {isAdminLoggedIn() ? (
                        <NavLink
                          className={`custom-nav-user-link ${classnames({
                            active: activeTab === '2',
                          })}`}
                          onClick={() => {
                            toggle('2');
                          }}
                        >
                          users activity
                        </NavLink>
                      ) : (
                        <NavLink
                          className={`custom-nav-user-link ${classnames({
                            active: activeTab === '2',
                          })}`}
                          onClick={() => {
                            toggle('2');
                          }}
                        >
                          projects activity
                        </NavLink>
                      )}
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId='1'>
                      <CurrentUserActivity
                        fromDate={fromDate}
                        toDate={toDate}
                      />
                    </TabPane>
                    <TabPane tabId='2'>
                      {isAdminLoggedIn() ? (
                        <AllUsersActivity fromDate={fromDate} toDate={toDate} />
                      ) : (
                        ''
                      )}
                    </TabPane>
                  </TabContent>
                </>
              ) : (
                <>
                  <CurrentUserActivity fromDate={fromDate} toDate={toDate} />
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ActivityList;
