import { WORKSPACE } from 'constants/index';
import { API_URL } from 'utils/api/api-constants';
const ADMIN = `${API_URL}/${WORKSPACE}/admin`;
const CLIENT = `${API_URL}/${WORKSPACE}/admin/client`;
const USER = `${API_URL}/${WORKSPACE}/admin/user`;
const STAT = `${API_URL}/${WORKSPACE}/admin/stats`;
export const PROJECT_URL = `${API_URL}/${WORKSPACE}/admin/project`;

/* OWNER ROLES */
export const CHANGE_ROLE_URL = (userId) =>
  `${API_URL}/${WORKSPACE}/owner/user/${userId}/role`;

/* STATS */
export const ADMIN_STATS = STAT;

/* CLIENT */
export const CLIENT_URL = `${CLIENT}`;
export const CLIENT_CRUD = (clientID) => `${CLIENT}/${clientID}`;
export const DELETE_MULTIPLE_CLIENTS = CLIENT;

/* USER */
export const USER_URL = USER;
export const DELETE_MULTIPLE_USERS = USER;
export const ARCHIVE_MULTIPLE_USERS = `${USER}/archive`;
export const UNARCHIVE_MULTIPLE_USERS = `${USER}/unarchive`;
export const USER_CRUD = (userID) => `${USER}/${userID}`;
export const USER_ARCHIVE = (userID) => `${USER_CRUD(userID)}/archive`;
export const USER_UNARCHIVE = (userID) => `${USER_CRUD(userID)}/unarchive`;
export const USER_IS_MANAGER = (userID) => `${USER_CRUD(userID)}/manager`;
// export const GET_USER_URL = (userId) => `${USER}/${userId}`;
export const USER_INVITE = `${USER}/invite`;

/* PROJECTS */
export const PROJECT_CRUD = (projectID) => `${PROJECT_URL}/${projectID}`;
export const DELETE_MULTIPLE_PROJECTS = PROJECT_URL;

/* USER TO PROJECT ASSOCIATION */
export const TEAM_URL = (projectID) => `${PROJECT_CRUD(projectID)}/team`;
export const ASSOCIATE_USER_TO_PROJECT_URL = (projectID, userID, flag) =>
  `${PROJECT_CRUD(projectID)}/team/${userID}?force=${flag ? 'true' : 'false'}`;

/* PROJECT LOGS */
export const LOGS_ADMIN_URL = (projectID, from, to) =>
  PROJECT_CRUD(projectID) + `/log?from=${from}&to=${to}`;
export const GET_USER_LOG_URL = (logId) => `${ADMIN}/log/${logId}`;

/* ACTIVITY */
export const ADMIN_ACTIVTY_URL = (from, to) =>
  `${API_URL}/${WORKSPACE}/activity?from=${from}&to=${to}`;
export const ADMIN_USERS_ACTIVTY_URL = (from, to) =>
  `${USER}/activity?from=${from}&to=${to}`;

/* WORKSPACE */
export const WHOAMI_URL = (workspace) => `${API_URL}/${workspace}/whoami`;

/*INTEGRATIONS*/
export const REQUEST_SLACK_INTEGRATION = `${API_URL}/${WORKSPACE}/owner/integration/slack/connect/auth`;
export const SEND_SLACK_CODE = (code) =>
  `${API_URL}/${WORKSPACE}/owner/integration/slack/connect?code=${code}`;

export const REQUEST_SLACK_UNINTEGRATION = `${API_URL}/${WORKSPACE}/owner/integration/slack/connect`;
export const GET_CHANNEL = `${API_URL}/${WORKSPACE}/owner/integration/slack/channel`;
export const LEAVE_CHANNEL = `${API_URL}/${WORKSPACE}/owner/integration/slack/channel`;
export const SELECT_CHANNEL = (channelId) =>
  `${API_URL}/${WORKSPACE}/owner/integration/slack/channel/${channelId}`;
export const GET_INTEGRATIONS = `${API_URL}/${WORKSPACE}/owner/integration`;
