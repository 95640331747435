import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { UncontrolledTooltip, Button, Spinner } from 'reactstrap';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import {
  changeRole,
  deleteUserFromProject,
  getTeam,
} from 'utils/api/api-requests/admin';
import { toast } from 'react-toastify';
import { setProjectTeamArray } from 'redux/actions/projects';
import { useParams } from 'react-router-dom';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';

const UserRoleRow = ({
  name,
  email,
  role,
  userId,
  managerFlag,
  projectName,
}) => {
  const dispatch = useDispatch();
  const [removeModal, setRemoveModal] = useState(false);
  const [forceRemoveFlag, setForceRemoveFlag] = useState(false);
  const [managerModal, setManagerModal] = useState(false);
  const [roleValue, setRoleValue] = useState(role);
  const [previousRole, setPreviousRole] = useState(role);
  const [saveFlag, setSaveFlag] = useState(false);
  const [spinnerFlag, setSpinnerFlag] = useState(false);
  const { projectId } = useParams();

  const setSelectRoleHandler = (selectedRole) => {
    setPreviousRole(roleValue);
    setRoleValue(selectedRole);
    setSaveFlag(true);
  };

  const roleUpdateUserRoleHandler = (e) => {
    e?.preventDefault();
    setSaveFlag(false);
    setSpinnerFlag(true);

    changeRole(projectId, userId, {
      role: roleValue.toUpperCase(),
    })
      .then(() => {
        getTeam(projectId).then(({ data }) => {
          dispatch(setProjectTeamArray(data));
        });
        setManagerModal(false);

        setTimeout(() => {
          setSpinnerFlag(false);
          toast.success('User role updated successfully');
        }, 1000);
      })
      .catch(() => {
        getTeam(projectId).then(({ data }) =>
          dispatch(setProjectTeamArray(data))
        );

        setManagerModal(false);
        setTimeout(() => {
          setSpinnerFlag(false);
          toast.error('Failed to change user role');
        }, 1000);
      });
  };

  const deleteUserFromProjectHandler = (e) => {
    e?.preventDefault();
    deleteUserFromProject(projectId, userId, forceRemoveFlag)
      .then(() => {
        toast.success('User removed successfully');
        setRemoveModal(false);
        getTeam(projectId).then(({ data }) =>
          dispatch(setProjectTeamArray(data))
        );
      })
      .catch((errorMessage) => {
        if (errorMessage === 'user is currently logging time in this project') {
          // toast.error(errorMessage);
          setForceRemoveFlag(true);
        } else {
          toast.error('Failed to remove user');
        }
      });
  };
  const roleUpdateManagerHandler = (e) => {
    e.preventDefault();
    setManagerModal(true);
  };
  const toggleManager = () => {
    setManagerModal(false);
    setRoleValue(previousRole);
  };

  return (
    <>
      <td className='table-user'>
        <div className='d-flex flex-row'>
          <img
            alt='...'
            className='avatar no-user-img rounded-circle mr-3'
            height='600'
            src={require(`assets/img/theme/no-user-1.jpeg`).default}
          />
          <div>
            <b>{name}</b>
            <br />
            <i className='text-primary cursor-normal'>{email}</i>
          </div>
        </div>
      </td>

      <td className='table-actions d-flex flex-row align-items-baseline justify-content-end'>
        <CustomFormGroup
          type='select'
          name='selectViewUserRole'
          id='selectViewUserRole'
          value={roleValue}
          setValue={setSelectRoleHandler}
          iconClassName='fas fa-user-edit'
          selectBoxText='Select view'
          selectBoxArray={[
            { uid: 'DEVELOPER', name: 'Developer' },
            { uid: 'MANAGER', name: 'Manager' },
            { uid: 'QA', name: 'QA' },
          ]}
          disabled={roleValue === 'MANAGER' && !saveFlag && !managerModal}
        />
        <UncontrolledTooltip delay={0} target='selectViewUserRole'>
          Change Role
        </UncontrolledTooltip>
      </td>

      <td>
        <div className='client-edit-button-child'>
          {saveFlag ? (
            <Button
              color='success'
              className={`save-btn ${!saveFlag ? 'hidden-class' : ''}`}
              size='custom-size'
              onClick={(e) => {
                setSaveFlag(false);
                if (roleValue === 'MANAGER' && managerFlag) {
                  roleUpdateManagerHandler(e);
                } else {
                  roleUpdateUserRoleHandler(e);
                }
              }}
            >
              Save
            </Button>
          ) : (
            <div className='spinner-container'>
              <Spinner
                size='sm'
                color='primary'
                className={` ${!spinnerFlag ? 'hidden-class' : ''}`}
              />
            </div>
          )}
          <button
            className='icons-wrapper-button'
            disabled={roleValue === 'MANAGER' && !saveFlag && !managerModal}
            onClick={() => setRemoveModal(true)}
          >
            <i
              className='fas fa-user-times text-lg ml-3'
              id='tooltip601065234'
            />
          </button>
          <UncontrolledTooltip delay={0} target='tooltip601065234'>
            Remove User
          </UncontrolledTooltip>
        </div>
      </td>

      <CustomModal
        isOpen={removeModal}
        toggle={() => setRemoveModal(!removeModal)}
        headerText='Remove user from project'
        cancelButtonText='Cancel'
        confirmButtonText='Remove'
        confirmFunction={deleteUserFromProjectHandler}
      >
        {forceRemoveFlag ? (
          <>
            {name}
            <i> is currently logging time in this project.</i>
          </>
        ) : (
          ''
        )}
        <p>
          {'Are you sure you want to remove '}
          <i>
            <b>{name}</b>
          </i>
          {' from '}
          <i>
            <b>{projectName}</b>
          </i>
          ?
        </p>
      </CustomModal>
      <CustomModal
        isOpen={managerModal}
        toggle={toggleManager}
        headerText='Change Manager'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={roleUpdateUserRoleHandler}
      >
        <p>
          {
            'There is already a manager of this project. Are you sure you want to change manager?'
          }
        </p>
      </CustomModal>
    </>
  );
};

export default UserRoleRow;
