import moment from 'moment';

export const projectLogsList = (logsList = [], { type, payload }) =>
  type === 'SET_PROJECT_LOGS_LIST' ? payload : logsList;
export const logsViewMode = (viewMode = 'THIS_WEEK', { type, payload }) =>
  type === 'SET_LOGS_VIEW_MODE' ? payload : viewMode;
export const areLogsBeingLoaded = (logs = false, { type, payload }) =>
  type === 'SET_ARE_LOGS_BEING_LOADED' ? payload : logs;
export const logAddModal = (addModal = false, { type, payload }) =>
  type === 'SET_LOG_ADD_MODAL' ? payload : addModal;
export const logEditModal = (editModal = false, { type, payload }) =>
  type === 'SET_LOG_EDIT_MODAL' ? payload : editModal;
export const logDeleteModal = (deleteModal = false, { type, payload }) =>
  type === 'SET_LOG_DELETE_MODAL' ? payload : deleteModal;
export const logID = (logID = -1, { type, payload }) =>
  type === 'SET_LOG_ID' ? payload : logID;
export const logDate = (
  logDate = moment.utc().format('YYYY-MM-DD'),
  { type, payload }
) => (type === 'SET_LOG_DATE' ? payload : logDate);
export const loggingProjects = (projects = [], { type, payload }) =>
  type === 'SET_LOGGING_PROJECTS' ? payload : projects;
export const trackingLogs = (logs = [], { type, payload }) =>
  type === 'SET_TRACKING_LOGS' ? payload : logs;
export const logsPageSize = (size = 4, { type, payload }) =>
  type === 'SET_LOGS_PAGE_SIZE' ? payload : size;
export const activeView = (view = 'list', { type, payload }) =>
  type === 'SET_ACTIVE_VIEW' ? payload : view;
export const isEditingTrackingLog = (log = false, { type, payload }) =>
  type === 'SET_IS_EDITING_TRACKING_LOG' ? payload : log;
