import React, { useState } from 'react';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import VerificationCode from 'Components/Common/VerificationCode/VerificationCode';
import {
  resetPasswordRequest,
  resetPassword,
} from 'utils/api/api-requests/auth';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('Password is empty');
  const [passwordUpdatedFlag, setPasswordUpdatedFlag] = useState(false);
  const [isEmailSentFlag, setIsEmailSentFlag] = useState(false);
  const [codeSentFlag, setCodeSentFlag] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const history = useHistory();

  const setEmailHandler = (value) => {
    setEmail(value);
    setIsSubmitButtonClicked(false);
  };
  const setPasswordHandler = (value) => {
    setPassword(value);
    setIsSubmitButtonClicked(false);
  };
  const setConfirmPasswordHandler = (value) => {
    setConfirmPassword(value);
    setIsSubmitButtonClicked(false);
  };
  const sendEmailHandler = (e) => {
    setIsSubmitButtonClicked(true);
    e.preventDefault();
    resetPasswordRequest({ email })
      .then(() => {
        setIsEmailSentFlag(true);
        setIsSubmitButtonClicked(false);
      })
      .catch(() => toast.error('Failed to send email'));
  };
  const resetPasswordHandler = (e) => {
    setIsSubmitButtonClicked(true);
    e.preventDefault();
    if (password === confirmPassword) {
      setIsSubmitButtonClicked(false);
      resetPassword(code, { email, new_password: password })
        .then(() => {
          setPasswordUpdatedFlag(true);
          setIsSubmitButtonClicked(false);
        })
        .catch(() => toast.error('Failed to reset password'));
    } else setMessage('Passwords must match');
  };
  const loginHandler = (e) => {
    e.preventDefault();
    history.push('/auth/login');
  };

  return (
    <>
      {passwordUpdatedFlag ? (
        <>
          <div className='confirmation-page'>
            <div className='confirmation-page-content'>
              <div class='confirmation-page__sub_heading mb-4'>
                We’ve have updated password for <strong>{email}</strong>. Please
                login again to verify.
              </div>
              <div>
                <input
                  className='custom-fw-btn'
                  type='submit'
                  value='Login'
                  onClick={(e) => loginHandler(e)}
                />
              </div>
            </div>
          </div>
        </>
      ) : isEmailSentFlag ? (
        <VerificationCode
          passwordEmail={email}
          passwordFlag={true}
          setCodeSentFlag={setCodeSentFlag}
          setIsEmailSentFlag={setIsEmailSentFlag}
          setCode={setCode}
        />
      ) : codeSentFlag ? (
        <>
          <CustomFormGroup
            type='password'
            name='loginPassword'
            id='loginPassword'
            placeholder='Password'
            value={password}
            setValue={setPasswordHandler}
            iconClassName='fas fa-key'
            invalidText={message}
            didSubmitButtonClick={isSubmitButtonClicked}
          />
          <CustomFormGroup
            type='password'
            name='confirmPassword'
            id='confirmPassword'
            placeholder='Confirm Password'
            value={confirmPassword}
            setValue={setConfirmPasswordHandler}
            iconClassName='ni ni-lock-circle-open'
            invalidText={message}
            didSubmitButtonClick={isSubmitButtonClicked}
          />
          <div className='custom-input-group'>
            <input
              className='custom-fw-btn'
              type='submit'
              value='Confirm'
              onClick={(e) => resetPasswordHandler(e)}
            />
          </div>
        </>
      ) : (
        <>
          <CustomFormGroup
            id='email'
            placeholder='Email'
            type='text'
            name='loginmail'
            value={email}
            setValue={setEmailHandler}
            iconClassName='ni ni-email-83'
            didSubmitButtonClick={isSubmitButtonClicked}
            invalidText='Invalid email address'
          />

          <div className='custom-input-group'>
            <input
              className='custom-fw-btn'
              type='submit'
              value='Send Email'
              onClick={(e) => sendEmailHandler(e)}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ForgotPassword;
