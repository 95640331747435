import { makeAPICall } from 'utils/api/api-utils';
import {
  ADMIN_STATS,
  TEAM_URL,
  CLIENT_URL,
  CLIENT_CRUD,
  USER_URL,
  USER_CRUD,
  USER_ARCHIVE,
  USER_UNARCHIVE,
  USER_IS_MANAGER,
  USER_INVITE,
  PROJECT_URL,
  PROJECT_CRUD,
  ASSOCIATE_USER_TO_PROJECT_URL,
  LOGS_ADMIN_URL,
  DELETE_MULTIPLE_USERS,
  ARCHIVE_MULTIPLE_USERS,
  UNARCHIVE_MULTIPLE_USERS,
  DELETE_MULTIPLE_PROJECTS,
  DELETE_MULTIPLE_CLIENTS,
  WHOAMI_URL,
  CHANGE_ROLE_URL,
  ADMIN_ACTIVTY_URL,
  ADMIN_USERS_ACTIVTY_URL,
  GET_USER_LOG_URL,
  REQUEST_SLACK_INTEGRATION,
  SEND_SLACK_CODE,
  REQUEST_SLACK_UNINTEGRATION,
  GET_CHANNEL,
  GET_INTEGRATIONS,
  LEAVE_CHANNEL,
  SELECT_CHANNEL,
} from 'utils/api/api-urls/admin';

/* OWNER */
export const changeEmployeeRole = (userId, roles) =>
  makeAPICall({
    method: 'PATCH',
    url: CHANGE_ROLE_URL(userId),
    authorizedRequest: true,
    data: roles,
  });

/* WORKSPACE */
export const whoamiApiRequest = (workspace) =>
  makeAPICall({
    url: WHOAMI_URL(workspace),
    authorizedRequest: true,
  });

/* STATS */
export const getAdminStats = () =>
  makeAPICall({
    url: ADMIN_STATS,
    authorizedRequest: true,
  });

/* CLIENT */

export const getClients = () =>
  makeAPICall({
    url: CLIENT_URL,
    authorizedRequest: true,
  });

export const getClient = (clientID) =>
  makeAPICall({
    url: CLIENT_CRUD(clientID),
    authorizedRequest: true,
  });

export const addClient = (client) =>
  makeAPICall({
    method: 'POST',
    url: CLIENT_URL,
    authorizedRequest: true,
    data: client,
  });

export const updateClient = (clientID, client) =>
  makeAPICall({
    method: 'PATCH',
    url: CLIENT_CRUD(clientID),
    authorizedRequest: true,
    data: client,
  });

export const deleteClient = (clientID) =>
  makeAPICall({
    method: 'DELETE',
    url: CLIENT_CRUD(clientID),
    authorizedRequest: true,
  });

export const deleteMultipleClients = (clientIds) =>
  makeAPICall({
    method: 'DELETE',
    url: DELETE_MULTIPLE_CLIENTS,
    authorizedRequest: true,
    data: clientIds,
  });

/* USER */

export const getUser = (userId) =>
  makeAPICall({
    url: USER_CRUD(userId),
    authorizedRequest: true,
  });

export const getUsers = () =>
  makeAPICall({
    url: USER_URL,
    authorizedRequest: true,
  });

export const deleteUser = (userID) =>
  makeAPICall({
    method: 'DELETE',
    url: USER_CRUD(userID),
    authorizedRequest: true,
  });

export const archiveUser = (userID) =>
  makeAPICall({
    method: 'PATCH',
    url: USER_ARCHIVE(userID),
    authorizedRequest: true,
  });

export const archiveMultipleUsers = (userIds) =>
  makeAPICall({
    method: 'PATCH',
    url: ARCHIVE_MULTIPLE_USERS,
    authorizedRequest: true,
    data: userIds,
  });

export const unarchiveMultipleUsers = (userIds) =>
  makeAPICall({
    method: 'PATCH',
    url: UNARCHIVE_MULTIPLE_USERS,
    authorizedRequest: true,
    data: userIds,
  });

export const deleteMultipleUsers = (userIds) =>
  makeAPICall({
    method: 'DELETE',
    url: DELETE_MULTIPLE_USERS,
    authorizedRequest: true,
    data: userIds,
  });

export const unArchiveUser = (userID) =>
  makeAPICall({
    method: 'PATCH',
    url: USER_UNARCHIVE(userID),
    authorizedRequest: true,
  });

export const checkManager = (userID) =>
  makeAPICall({
    url: USER_IS_MANAGER(userID),
    authorizedRequest: true,
  });

export const inviteUser = (email) =>
  makeAPICall({
    method: 'POST',
    url: USER_INVITE,
    authorizedRequest: true,
    data: email,
  });

/* PROJECT*/

export const getProjects = () =>
  makeAPICall({
    url: PROJECT_URL,
    authorizedRequest: true,
  });

export const getProject = (projectID) =>
  makeAPICall({
    url: PROJECT_CRUD(projectID),
    authorizedRequest: true,
  });

export const addProject = (project) =>
  makeAPICall({
    method: 'POST',
    url: PROJECT_URL,
    authorizedRequest: true,
    data: project,
  });

export const updateProject = (projectID, project) =>
  makeAPICall({
    method: 'PATCH',
    url: PROJECT_CRUD(projectID),
    authorizedRequest: true,
    data: project,
  });

export const deleteProject = (projectID) =>
  makeAPICall({
    method: 'DELETE',
    url: PROJECT_CRUD(projectID),
    authorizedRequest: true,
  });

export const deleteMultipleProjects = (projectIds) =>
  makeAPICall({
    method: 'DELETE',
    url: DELETE_MULTIPLE_PROJECTS,
    authorizedRequest: true,
    data: projectIds,
  });

/* USER TO PROJECT ASSOCIATION */

export const getTeam = (projectID) =>
  makeAPICall({ url: TEAM_URL(projectID), authorizedRequest: true });

export const changeRole = (projectID, userID, role) =>
  makeAPICall({
    method: 'PATCH',
    url: ASSOCIATE_USER_TO_PROJECT_URL(projectID, userID),
    authorizedRequest: true,
    data: role,
  });

export const associateUserToProjectRequest = (projectID, userID, role) =>
  makeAPICall({
    method: 'POST',
    url: ASSOCIATE_USER_TO_PROJECT_URL(projectID, userID),
    authorizedRequest: true,
    data: role,
  });

export const deleteUserFromProject = (projectID, userID, forceRemoveFlag) =>
  makeAPICall({
    method: 'DELETE',
    url: ASSOCIATE_USER_TO_PROJECT_URL(projectID, userID, forceRemoveFlag),
    authorizedRequest: true,
  });

/*LOGS*/

export const getLogsAdmin = (projectID, from, to) =>
  makeAPICall({
    url: LOGS_ADMIN_URL(projectID, from, to),
    authorizedRequest: true,
  });

export const getAdminActivity = (from, to) =>
  makeAPICall({
    url: ADMIN_ACTIVTY_URL(from, to),
    authorizedRequest: true,
  });
export const getAdminUsersActivity = (from, to) =>
  makeAPICall({
    url: ADMIN_USERS_ACTIVTY_URL(from, to),
    authorizedRequest: true,
  });
export const getUserSingleLog = (logId) =>
  makeAPICall({
    url: GET_USER_LOG_URL(logId),
    authorizedRequest: true,
  });

/*Integrations*/

export const requestSlackIntegration = () =>
  makeAPICall({
    url: REQUEST_SLACK_INTEGRATION,
    authorizedRequest: true,
  });

export const sendSlackCode = (code) =>
  makeAPICall({
    url: SEND_SLACK_CODE(code),
    method: 'PUT',
    authorizedRequest: true,
  });

export const requestSlackUnIntegration = () =>
  makeAPICall({
    method: 'DELETE',
    url: REQUEST_SLACK_UNINTEGRATION,
    authorizedRequest: true,
  });

export const getChannels = () =>
  makeAPICall({
    url: GET_CHANNEL,
    authorizedRequest: true,
  });

export const getIntegration = () =>
  makeAPICall({
    url: GET_INTEGRATIONS,
    authorizedRequest: true,
  });

export const leaveChannel = () =>
  makeAPICall({
    method: 'DELETE',
    url: LEAVE_CHANNEL,
    authorizedRequest: true,
  });

export const selectChannel = (channelId) =>
  makeAPICall({
    method: 'PUT',
    url: SELECT_CHANNEL(channelId),
    authorizedRequest: true,
  });
