import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { makeCSVlogObject } from 'utils/helper-functions';
import moment from 'moment';
const CSVExporter = ({ selectedLogs, projectName }) => {
  const { projectLogsList, fromDateFilter, toDateFilter } = useSelector(
    (state) => state
  );
  const [csvLogs, setCsvLogs] = useState(makeCSVlogObject(projectLogsList));
  const [headersArray, setHeadersArray] = useState([]);
  const mploUser = useSelector((state) => state.mploUser);

  useEffect(() => {
    let headersArrayVariable = [{ label: 'Logged By', key: 'loggedBy' }];
    if (localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin')
      headersArrayVariable = [];
    headersArrayVariable?.push(
      { label: 'ID', key: 'id' },
      { label: 'Date', key: 'date' },
      { label: 'Start Time', key: 'timeStart' },
      { label: 'End Time', key: 'timeEnd' },
      { label: 'Hours Logged', key: 'hours' },
      { label: 'Details', key: 'worked' },
      { label: 'Late Sitting', key: 'lateSitting' }
    );
    setHeadersArray(headersArrayVariable);
    setCsvLogs(
      makeCSVlogObject(
        projectLogsList?.filter(({ uid }) => selectedLogs?.includes(uid))
      )
    );
  }, [projectLogsList, selectedLogs]);

  return (
    <>
      <CSVLink
        headers={headersArray}
        data={csvLogs}
        filename={`${projectName}-${moment(fromDateFilter, 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        )}-to-${moment(toDateFilter, 'YYYY-MM-DD').format('DD/MM/YYYY')}${
          mploUser?.role === 'USER' ? `_${mploUser?.name}` : ''
        }`}
        onClick={() => {
          if (selectedLogs?.length === 0) return false;
        }}
      >
        <div>
          <img alt='...' src={require('assets/svg/export-icon.svg').default} />
          <div>Export</div>
        </div>
      </CSVLink>
    </>
  );
};

export default CSVExporter;
