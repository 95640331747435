import React, { useEffect, useState } from 'react';
import {
  useLocation,
  NavLink as NavLinkRRD,
  Link,
  useParams,
  useHistory,
} from 'react-router-dom';

import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  NavbarBrand,
  Row,
  Col,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import {
  isAdminLoggedIn,
  isUserLoggedIn,
} from '../../../utils/helper-functions';
import { setProjectLogsList } from 'redux/actions/logs';

const Sidebar = ({ routes, logo, screenLayout }) => {
  const [linkState, setLinkState] = useState({});
  const [collapseOpen, setCollapseOpen] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const { workspace } = useParams();
  const toggleCollapse = () => setCollapseOpen((data) => !data);
  const closeCollapse = () => setCollapseOpen(false);
  const location = useLocation();
  useEffect(() => {
    setLinkState(getCollapseStates(routes));

    // eslint-disable-next-line
  }, []);
  const activeRoute = (routeName) =>
    location.pathname.indexOf(routeName) > -1 ? 'active' : '';

  const getCollapseStates = (routes) => {
    let initialState = {};
    routes?.map(({ collapse, state, views }) => {
      if (collapse) {
        initialState = {
          [state]: getCollapseInitialState(views),
          ...getCollapseStates(views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes?.length; i++) {
      if (
        routes?.[i]?.collapse &&
        getCollapseInitialState(routes?.[i]?.views)
      ) {
        return true;
      } else if (location?.pathname?.indexOf(routes?.[i]?.path) !== -1) {
        return true;
      }
    }
    return false;
  };

  const closeSidenav = (propName) => {
    if (propName === 'Logout') {
      localStorage.removeItem('jwt-token');
      dispatch(setProjectLogsList([]));
    }
    if (window.innerWidth < 1200) {
      closeCollapse();
    }
  };
  const createLinks = (routes) => {
    return routes?.map(
      (
        {
          redirect,
          collapse,
          state,
          views,
          icon,
          name,
          miniName,
          layout,
          path,
        },
        key
      ) => {
        if (redirect) {
          return null;
        }
        if (collapse) {
          var st = {};
          st[`${state}`] = !linkState[state];
          return (
            <NavItem key={key}>
              <NavLink
                href='#'
                data-toggle='collapse'
                aria-expanded={linkState[`${state}`]}
                className={classnames({
                  active: getCollapseInitialState(views),
                })}
                onClick={(e) => {
                  e?.preventDefault();
                  setLinkState(st);
                }}
              >
                {icon ? (
                  <>
                    <i className={icon} />
                    <span className='nav-link-text'>{name}</span>
                  </>
                ) : miniName ? (
                  <>
                    <span className='sidenav-mini-icon'> {miniName} </span>
                    <span className='sidenav-normal'> {name} </span>
                  </>
                ) : null}
              </NavLink>
              <Collapse isOpen={linkState[`${state}`]}>
                <Nav className='nav-sm flex-column'>
                  <span className='sidenav-child'>{createLinks(views)}</span>
                </Nav>
              </Collapse>
            </NavItem>
          );
        }

        return window.innerWidth > 768 &&
          ['Logout', 'Change Password']?.includes(name) ? (
          ''
        ) : (
          <NavItem className={activeRoute(layout + path)} key={key}>
            <NavLink
              to={`/${workspace}${layout}${path}`}
              activeClassName={icon !== undefined ? 'active' : ''}
              onClick={() => closeSidenav(name)}
              tag={NavLinkRRD}
            >
              {icon !== undefined ? (
                <>
                  <i className={`${icon} ${screenLayout}-theme-color`} />
                  <span className='nav-link-text'>{name}</span>
                </>
              ) : miniName !== undefined ? (
                <>
                  <span className='sidenav-mini-icon'> {miniName} </span>
                  <span className='sidenav-normal'> {name} </span>
                </>
              ) : (
                name
              )}
            </NavLink>
          </NavItem>
        );
      }
    );
  };

  let navbarBrandProps;
  if (logo?.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: '_blank',
    };
  }
  return (
    <Navbar
      className='navbar-vertical fixed-left navbar-light bg-white'
      expand='md'
      id='sidenav-main'
    >
      <Container fluid>
        <button
          className='navbar-toggler'
          type='button'
          onClick={toggleCollapse}
        >
          <span className='navbar-toggler-icon' />
        </button>
        {!location.pathname.includes('/profile') &&
        !location.pathname.includes('/security') ? (
          logo ? (
            <NavbarBrand className='pt-0' {...navbarBrandProps}>
              <img
                alt='...'
                className='navbar-brand-img'
                src={require('assets/svg/Logo.svg').default}
              />
            </NavbarBrand>
          ) : (
            ''
          )
        ) : (
          <div className='navbar-brand-button pt-0' {...navbarBrandProps}>
            <i
              class='fas fa-arrow-left'
              onClick={() =>
                history.push(
                  `/${workspace}/${
                    isAdminLoggedIn() ? 'admin' : isUserLoggedIn() ? 'user' : ''
                  }/index`
                )
              }
            ></i>
            <span>Settings</span>
          </div>
        )}

        <Collapse navbar isOpen={collapseOpen}>
          <div className='navbar-collapse-header d-md-none'>
            <Row>
              {logo ? (
                <Col className='collapse-brand' xs='6'>
                  {logo?.innerLink ? (
                    <Link to={logo?.innerLink}>
                      <img alt={logo?.imgAlt} src={logo?.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo?.imgAlt} src={logo?.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className='collapse-close' xs='6'>
                <button
                  className='navbar-toggler'
                  type='button'
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>

          <Nav
            className={screenLayout === 'user' ? 'user-active-sidebar' : ''}
            navbar
          >
            {!location.pathname.includes('/profile') &&
            !location.pathname.includes('/security') ? (
              createLinks(routes)
            ) : (
              <>
                <NavItem>
                  <NavLink
                    className={`cursor-pointer ${
                      location.pathname.includes('/profile') ? 'active' : ''
                    }`}
                    onClick={() => {
                      history.push(
                        `/${workspace}/${
                          isAdminLoggedIn()
                            ? 'admin'
                            : isUserLoggedIn()
                            ? 'user'
                            : ''
                        }/profile`
                      );
                    }}
                  >
                    <span className='nav-link-text'>Profile</span>
                  </NavLink>{' '}
                  <NavLink
                    className={`cursor-pointer ${
                      location.pathname.includes('/security') ? 'active' : ''
                    }`}
                    onClick={() => {
                      history.push(
                        `/${workspace}/${
                          isAdminLoggedIn()
                            ? 'admin'
                            : isUserLoggedIn()
                            ? 'user'
                            : ''
                        }/security`
                      );
                    }}
                  >
                    <span className='nav-link-text'>Change Password</span>
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>
          <hr className='my-3' />
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

export default Sidebar;
