import { makeAPICall } from 'utils/api/api-utils';
import { CHANGE_USERNAME } from 'utils/api/api-urls/general';

export const changeUsername = (name) =>
  makeAPICall({
    method: 'PATCH',
    url: CHANGE_USERNAME,
    authorizedRequest: true,
    data: name,
  });
