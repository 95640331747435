import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveView } from 'redux/actions/logs';
import './ViewSelector.scss';
const ViewSelector = ({ setSelectedLogs }) => {
  const activeView = useSelector(({ activeView }) => activeView);
  const dispatch = useDispatch();
  const setCurrentView = (view) => {
    dispatch(setActiveView(view));
    localStorage.setItem('currentView', view);
    setSelectedLogs([]);
  };

  useEffect(() => {
    dispatch(setActiveView(localStorage.getItem('currentView') ?? 'list'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='view-selector'>
      <div
        className={`fas fa-list ${
          activeView === 'list'
            ? `list-view
        ${
          localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            ? 'admin-view-background'
            : ''
        }`
            : `
        ${
          localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            ? 'admin-view'
            : ''
        }`
        } 
        
        `}
        onClick={() => setCurrentView('list')}
      />
      <div
        className={`fas fa-th-large ${
          activeView === 'grid'
            ? `grid-view
        ${
          localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            ? 'admin-view-background'
            : ''
        }`
            : `
        ${
          localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            ? 'admin-view'
            : ''
        }`
        }      
        `}
        onClick={() => setCurrentView('grid')}
      />
      <div
        className={`fas fa-calendar-alt ${
          activeView === 'calendar'
            ? `calendar-view
        ${
          localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            ? 'admin-view-background'
            : ''
        }`
            : `
        ${
          localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            ? 'admin-view'
            : ''
        }`
        }      
        `}
        onClick={() => setCurrentView('calendar')}
      />
    </div>
  );
};

export default ViewSelector;
