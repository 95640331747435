import React, { useState } from 'react';
import Header from 'Components/Headers/Header';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  CardBody,
  Input,
  FormGroup,
  Button,
} from 'reactstrap';

import { InputGroup } from 'react-bootstrap';
import { updatePassword } from 'utils/api/api-requests/auth';
import { toast } from 'react-toastify';
const SecurityAndPrivacy = () => {
  const history = useHistory();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isError, setIsError] = useState(false);
  const updatePasswordHandler = () => {
    let password = { old_password: oldPassword, new_password: newPassword };
    if (newPassword === confirmPassword) {
      updatePassword(password)
        .then(() => {
          setOldPassword('');
          setNewPassword('');
          setConfirmPassword('');
          setIsError(false);
          toast.success(`Password updated successfully`);
        })
        .catch(() => {
          setIsError(false);
          toast.error(`Unable to update password`);
        });
    } else {
      setIsError(true);
    }
  };
  const forgotPasswordHandler = () => {
    localStorage.clear();
    history.push(`/auth/forgot-password`);
  };
  return (
    <>
      <Header />
      <Container className='mt--7' fluid>
        <Row className='mt-5 mb-5'>
          <Col className='mb-5 mb-xl-0' xl='12'>
            <Card className='shadow'>
              <CardHeader>
                <Row className='align-items-center'>
                  <div className='col'>
                    <h3 className='mb-0'>Change Password</h3>
                  </div>

                  <div className='col text-right'></div>
                </Row>
              </CardHeader>
              <CardBody className='profile-card-body'>
                <div className='custom-styled-label mb-label'>Old password</div>
                <FormGroup>
                  <InputGroup className='input-group-alternative'>
                    <Input
                      className='input-not-select form-control'
                      value={oldPassword}
                      placeholder='Enter old password'
                      onChange={(e) => setOldPassword(e?.target?.value)}
                      type='password'
                    />
                  </InputGroup>
                </FormGroup>
                <div className='custom-styled-label mb-label'>New password</div>
                <FormGroup
                  className={`${isError ? 'custom-invalid-feedback' : ''}`}
                >
                  <InputGroup className='input-group-alternative'>
                    <Input
                      className='input-not-select form-control'
                      type='password'
                      value={newPassword}
                      placeholder='Enter new password'
                      onChange={(e) => {
                        setNewPassword(e?.target?.value);
                        setIsError(false);
                      }}
                    />
                  </InputGroup>
                </FormGroup>
                <div className='custom-styled-label mb-label'>
                  Confirm new password
                </div>
                <FormGroup
                  className={`mb-3 ${isError ? 'custom-invalid-feedback' : ''}`}
                >
                  <InputGroup className='input-group-alternative'>
                    <Input
                      className='input-not-select form-control'
                      type='password'
                      placeholder='Enter password again'
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e?.target?.value);
                        setIsError(false);
                      }}
                    />
                  </InputGroup>

                  <div
                    className={`custom-invalid-feedback-text ${
                      isError ? '' : ' hidden-class'
                    }`}
                  >
                    {' '}
                    Passwords mismatched
                  </div>
                </FormGroup>
                <div className='update-password-container'>
                  <Button
                    color='primary'
                    className='modal-unfilled'
                    onClick={updatePasswordHandler}
                  >
                    Update Password
                  </Button>
                  <div
                    className='forget-password-text cursor-pointer'
                    onClick={forgotPasswordHandler}
                  >
                    {' '}
                    I forgot my password
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SecurityAndPrivacy;
