import React from 'react';
import validator from 'validator';
import { validate } from 'email-validator';
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
const CustomFormGroup = ({
  type,
  name,
  id,
  placeholder,
  value,
  setValue,
  iconClassName,
  invalidText,
  selectBoxText,
  selectBoxArray,
  didSubmitButtonClick,
  password,
  min,
  max,
  layout,
  disabled,
  invalidTextFlag,
  marginBottomZero,
}) => (
  <>
    <FormGroup
      className={`${
        name === 'selectViewUserRole' || marginBottomZero ? 'mb-0' : 'mb-3'
      } ${
        (name?.includes('confirm')
          ? value !== password
          : name?.includes('Phone')
          ? !validator.isMobilePhone(value, 'any', true)
          : !name?.includes('mail')
          ? !value
          : !validate(value)) && didSubmitButtonClick
          ? 'custom-invalid-feedback'
          : ''
      }`}
    >
      <InputGroup className='input-group-alternative'>
        <InputGroupAddon addonType='prepend'>
          <InputGroupText className={`icon-width ${layout}-theme-color`}>
            <i className={iconClassName} />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            type === 'number'
              ? e?.target?.value <= 48 && e?.target?.value >= min
                ? setValue(e?.target?.value)
                : setValue(min)
              : setValue(e?.target?.value);
          }}
          min={min ?? ''}
          max={max ?? ''}
          className={
            type !== 'select'
              ? 'input-not-select'
              : ` ${value && !disabled ? 'main-text-color' : ''}`
          }
          required
          disabled={disabled}
        >
          {type === 'select' ? (
            <>
              {selectBoxText ? (
                <option value={0} selected={!value} disabled={value}>
                  {selectBoxText}
                </option>
              ) : (
                ''
              )}
              {selectBoxArray?.map(({ uid, name }) => (
                <option value={uid} key={uid} selected={uid === value}>
                  {name}
                </option>
              ))}
            </>
          ) : (
            <></>
          )}
        </Input>
      </InputGroup>
    </FormGroup>
    {name !== 'selectView' && !invalidTextFlag ? (
      <div
        className={`custom-invalid-feedback-text mt--3
          ${
            (name?.includes('confirm')
              ? value !== password
              : name?.includes('Phone')
              ? !validator.isMobilePhone(value)
              : !name?.includes('mail')
              ? !value && didSubmitButtonClick
              : !validate(value)) && didSubmitButtonClick
              ? ''
              : ' hidden-class'
          }`}
      >
        <i className='fas fa-exclamation-circle' /> <span>{invalidText}</span>
      </div>
    ) : (
      ''
    )}
  </>
);

export default CustomFormGroup;
