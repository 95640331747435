import React, { useState } from 'react';
import { Collapse, NavItem, NavLink, Nav } from 'reactstrap';
import { useParams } from 'react-router-dom';
const WorkspaceDropdownItem = ({ name, alias, roles }) => {
  const { workspace } = useParams();
  const [linkState, setLinkState] = useState(false);
  const changeWorkspaceHandler = (role) => {
    localStorage.setItem('workspace', alias);
    localStorage.setItem(
      'currentUserRole',
      role?.toLowerCase() === 'admin' ? 'admin' : 'user'
    );
    window.location.replace(
      `/${alias}/${
        ['employee', 'user']?.includes(role?.toLowerCase()) ? 'user' : 'admin'
      }/index`
    );
  };
  const workspaceNameHandler = (e) => {
    e?.preventDefault();
    setLinkState(!linkState);
    if (roles?.length === 1 && workspace !== alias)
      changeWorkspaceHandler(roles?.[0]);
  };
  return (
    <>
      <NavItem key={name}>
        <NavLink
          className={`custom-nav-link ${
            roles?.length > 1 || workspace !== alias ? 'cursor-pointer' : ''
          } nav-link-text`}
          onClick={(e) => workspaceNameHandler(e)}
          aria-checked={workspace === alias}
        >
          <span
            className='custom-nav-link-text'
            data-toggle={roles?.length > 1 ? 'collapse' : ''}
            aria-expanded={roles?.length > 1 ? linkState : false}
          >
            {' '}
            {name}{' '}
          </span>
        </NavLink>
        {roles?.length > 1 ? (
          <Collapse isOpen={linkState}>
            <Nav className='nav-sm flex-column'>
              <span
                className={`custom-nav-link ${
                  workspace === alias &&
                  localStorage.getItem('currentUserRole') === 'admin'
                    ? ''
                    : 'cursor-pointer'
                }`}
                onClick={() => changeWorkspaceHandler('admin')}
                aria-selected={
                  workspace === alias &&
                  localStorage.getItem('currentUserRole') === 'admin'
                }
              >
                Admin
              </span>
              <span
                className={`custom-nav-link 
                ${
                  workspace === alias &&
                  localStorage.getItem('currentUserRole') === 'user'
                    ? ''
                    : 'cursor-pointer'
                }  
                `}
                aria-selected={
                  workspace === alias &&
                  localStorage.getItem('currentUserRole') === 'user'
                }
                onClick={() => changeWorkspaceHandler('user')}
              >
                User
              </span>
            </Nav>
          </Collapse>
        ) : (
          ''
        )}
      </NavItem>
    </>
  );
};

export default WorkspaceDropdownItem;
