import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { getAdminUsersActivity } from 'utils/api/api-requests/admin';
import ActivityCard from '../ActivityCard/ActivityCard';

const AllUsersActivity = ({ fromDate, toDate }) => {
  const [usersActivityLogs, setUsersActivityLogs] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  useEffect(() => {
    setIsDataLoading(true);
    getAdminUsersActivity(fromDate, toDate)
      .then(({ data }) => {
        setUsersActivityLogs(data);
        setIsDataLoading(false);
      })
      .catch(() => {
        setIsDataLoading(false);
      });
  }, [fromDate, toDate]);
  return isDataLoading ? (
    <div className='p-5 text-center'>
      <Spinner color='success' size='lg' />
    </div>
  ) : (
    <div className='activity-list'>
      {usersActivityLogs?.length > 0 ? (
        usersActivityLogs?.map((log) => <ActivityCard log={log} />)
      ) : (
        <div className='no-activity-data'>No activity found</div>
      )}
    </div>
  );
};

export default AllUsersActivity;
