import React, { useState, useRef } from 'react';
import { verifyInvite, loginWithEmail } from 'utils/api/api-requests/auth';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { whoamiApiRequest } from 'utils/api/api-requests/admin';
import { verifyResetPasswordToken } from 'utils/api/api-requests/auth';
import { setMploUser } from '../../../redux/actions/general';
import { useDispatch } from 'react-redux';

const VerificationCode = ({
  loginEmail,
  signupEmail,
  setCode,
  setConfirmInviteFlag,
  signupFlag,
  setSignupFlag,
  passwordEmail,
  passwordFlag,
  setCodeSentFlag,
  setIsEmailSentFlag,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [invalidTextFlag, setInvalidTextFlag] = useState(false);
  const [invalidText, setInvalidText] = useState('Failed to verify the code');
  const [character1, setCharacter1] = useState('');
  const [character2, setCharacter2] = useState('');
  const [character3, setCharacter3] = useState('');
  const [character4, setCharacter4] = useState('');
  const [character5, setCharacter5] = useState('');
  const [character6, setCharacter6] = useState('');
  let input1 = useRef(null);
  let input2 = useRef(null);
  let input3 = useRef(null);
  let input4 = useRef(null);
  let input5 = useRef(null);
  let input6 = useRef(null);

  // helper functions
  const inputValidator = (value) => {
    if (/^[a-zA-Z0-9_]*$/.test(value) && value?.length === 1) {
      return true;
    }
  };

  const regexValidator = (value) => {
    if (!/^[a-zA-Z0-9_]*$/.test(value)) {
      return true;
    }
  };

  const characterExistenceChecker = () => {
    if (character1 && character2 && character3 && character4 && character5) {
      return true;
    }
  };

  const clearCharacters = () => {
    setCharacter1('');
    setCharacter2('');
    setCharacter3('');
    setCharacter4('');
    setCharacter5('');
    setCharacter6('');
  };

  const convertInputToUpperCase = (value) => {
    return value.toUpperCase();
  };

  // onChange handlers

  const handleTextChange1 = (e) => {
    setInvalidTextFlag(false);
    if (inputValidator(e?.target?.value)) {
      input2.focus();
      setCharacter1(e?.target?.value);
    } else if (regexValidator(e?.target?.value)) {
      input1.focus();
      setCharacter1('');
    }
  };

  const handleTextChange2 = (e) => {
    setInvalidTextFlag(false);
    if (inputValidator(e?.target?.value)) {
      input3.focus();
      setCharacter2(e?.target?.value);
    } else if (regexValidator(e?.target?.value)) {
      input2.focus();
      setCharacter2('');
    }
  };

  const handleTextChange3 = (e) => {
    setInvalidTextFlag(false);
    if (inputValidator(e?.target?.value)) {
      input4.focus();
      setCharacter3(e?.target?.value);
    } else if (regexValidator(e?.target?.value)) {
      input3.focus();
      setCharacter3('');
    }
  };

  const handleTextChange4 = (e) => {
    setInvalidTextFlag(false);
    if (inputValidator(e?.target?.value)) {
      input5.focus();
      setCharacter4(e?.target?.value);
    } else if (regexValidator(e?.target?.value)) {
      input4.focus();
      setCharacter4('');
    }
  };

  const handleTextChange5 = (e) => {
    setInvalidTextFlag(false);
    if (inputValidator(e?.target?.value)) {
      input6.focus();
      setCharacter5(e?.target?.value);
    } else if (regexValidator(e?.target?.value)) {
      input5.focus();
      setCharacter5('');
    }
  };

  const handleTextChange6 = (e) => {
    setInvalidTextFlag(false);
    let confirmationCode = '';
    if (
      characterExistenceChecker() &&
      e?.target?.value &&
      inputValidator(e?.target?.value)
    ) {
      confirmationCode = convertInputToUpperCase(character1).concat(
        convertInputToUpperCase(character2),
        convertInputToUpperCase(character3),
        '-',
        convertInputToUpperCase(character4),
        convertInputToUpperCase(character5),
        convertInputToUpperCase(e?.target?.value)
      );
    }

    if (inputValidator(e?.target?.value)) {
      signupEmail &&
      characterExistenceChecker() &&
      e?.target?.value &&
      signupFlag &&
      !passwordFlag
        ? verifyInvite(confirmationCode)
            .then(({ data: { message } }) => {
              setSignupFlag(false);
              setConfirmInviteFlag(true);
              setCode(confirmationCode);
            })
            .catch(() => {
              input1?.focus();
              clearCharacters();
              setInvalidTextFlag(true);
            })
        : loginEmail &&
          characterExistenceChecker() &&
          e?.target?.value &&
          !signupFlag &&
          !passwordFlag
        ? loginWithEmail({
            email: loginEmail,
            password: confirmationCode,
          })
            .then((resp) => {
              localStorage.setItem('jwt-token', resp.headers['jwt-token']);
              let lastWorkspace = resp.data?.workspaces_joined?.[0]?.alias;
              localStorage.setItem('workspace', lastWorkspace);
              localStorage.setItem('workspace', lastWorkspace);
              toast.success('Logged-in successfully');
              whoamiApiRequest(lastWorkspace)
                .then(({ data: { name, roles, uid, email } }) => {
                  const mploUser = {
                    name,
                    roles,
                    uid,
                    email,
                  };
                  dispatch(setMploUser(mploUser));
                  roles?.includes('ADMIN')
                    ? localStorage.setItem('currentUserRole', 'admin')
                    : roles?.includes('EMPLOYEE')
                    ? localStorage.setItem('currentUserRole', 'user')
                    : localStorage.setItem('currentUserRole', 'null');

                  history.push(`/${lastWorkspace}/admin/index`);
                })
                .catch(() => {
                  toast.error('Failed to get logged in user data');
                });
            })
            .catch(() => {
              input1?.focus();
              clearCharacters();
              setInvalidTextFlag(true);
            })
        : passwordEmail &&
          characterExistenceChecker() &&
          e?.target?.value &&
          !signupFlag &&
          passwordFlag
        ? verifyResetPasswordToken(confirmationCode)
            .then(() => {
              toast.success('Verified');
              setIsEmailSentFlag(false);
              setCodeSentFlag(true);
              setCode(confirmationCode);
            })
            .catch(() => {
              setInvalidTextFlag(true);
            })
        : setInvalidText('Please fill all the characters');
    }
  };
  return (
    <>
      <div className='confirmation-page'>
        <div className='confirmation-page-content'>
          <h2 class='confirmation-page__heading'>
            Check your email for a code
          </h2>
          <div class='confirmation-page__sub_heading'>
            <div>
              We’ve sent a 6-character code to
              <strong>
                {loginEmail ? loginEmail : signupEmail ? signupEmail : ''}
              </strong>
              .
            </div>
            <span>The code expires shortly, so please enter it soon.</span>
          </div>
          <div class='p-get_started_confirmation_code__input_container'>
            <fieldset class='p-get_started_confirmation_code__input_fieldset'>
              <legend class='offscreen'>6-digit confirmation code</legend>
              <div
                class='display_flex align_items_center margin_bottom_50'
                data-qa='confirmation_code_input'
                data-state=''
              >
                <div class='display_flex'>
                  <div
                    className={`split_input_item ${
                      invalidTextFlag ? ' invalid' : ''
                    }`}
                  >
                    <input
                      type='text'
                      ref={(input) => (input1 = input)}
                      onKeyDown={(e) => {
                        if (e.key === 'ArrowRight') {
                          input2.focus();
                        }
                      }}
                      maxlength='1'
                      aria-label='digit 1 of 6'
                      aria-disabled='false'
                      aria-invalid='false'
                      className='text-transformation'
                      value={character1}
                      onChange={handleTextChange1}
                    />
                  </div>
                  <div
                    className={`split_input_item ${
                      invalidTextFlag ? ' invalid' : ''
                    }`}
                  >
                    <input
                      type='text'
                      maxlength='1'
                      ref={(input) => (input2 = input)}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          if (!character2) {
                            input1.focus();
                            setCharacter1('');
                          } else {
                            setCharacter2('');
                          }
                        }
                        if (e.key === 'ArrowLeft') {
                          input1.focus();
                        } else if (e.key === 'ArrowRight') {
                          input3.focus();
                        }
                      }}
                      aria-label='digit 2 of 6'
                      aria-disabled='false'
                      aria-invalid='false'
                      className='text-transformation'
                      value={character2}
                      onChange={handleTextChange2}
                    />
                  </div>
                  <div
                    className={`split_input_item ${
                      invalidTextFlag ? ' invalid' : ''
                    }`}
                  >
                    <input
                      type='text'
                      maxlength='1'
                      ref={(input) => (input3 = input)}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          if (!character3) {
                            input2.focus();
                            setCharacter2('');
                          } else {
                            setCharacter3('');
                          }
                        }
                        if (e.key === 'ArrowLeft') {
                          input2.focus();
                        } else if (e.key === 'ArrowRight') {
                          input4.focus();
                        }
                      }}
                      aria-label='digit 3 of 6'
                      aria-disabled='false'
                      aria-invalid='false'
                      className='text-transformation'
                      value={character3}
                      onChange={handleTextChange3}
                    />
                  </div>
                </div>
                <div class='confirmation_code_span_cell margin_50'>—</div>
                <div class='display_flex'>
                  <div
                    className={`split_input_item ${
                      invalidTextFlag ? ' invalid' : ''
                    }`}
                  >
                    <input
                      type='text'
                      maxlength='1'
                      ref={(input) => (input4 = input)}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          if (!character4) {
                            input3.focus();
                            setCharacter3('');
                          } else {
                            setCharacter4('');
                          }
                        }
                        if (e.key === 'ArrowLeft') {
                          input3.focus();
                        } else if (e.key === 'ArrowRight') {
                          input5.focus();
                        }
                      }}
                      aria-label='digit 4 of 6'
                      aria-disabled='false'
                      aria-invalid='false'
                      className='text-transformation'
                      value={character4}
                      onChange={handleTextChange4}
                    />
                  </div>
                  <div
                    className={`split_input_item ${
                      invalidTextFlag ? ' invalid' : ''
                    }`}
                  >
                    <input
                      type='text'
                      maxlength='1'
                      ref={(input) => (input5 = input)}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          if (!character5) {
                            input4.focus();
                            setCharacter4('');
                          } else {
                            setCharacter5('');
                          }
                        }
                        if (e.key === 'ArrowLeft') {
                          input4.focus();
                        } else if (e.key === 'ArrowRight') {
                          input6.focus();
                        }
                      }}
                      aria-label='digit 5 of 6'
                      aria-disabled='false'
                      aria-invalid='false'
                      className='text-transformation'
                      value={character5}
                      onChange={handleTextChange5}
                    />
                  </div>
                  <div
                    className={`split_input_item ${
                      invalidTextFlag ? ' invalid' : ''
                    }`}
                  >
                    <input
                      type='text'
                      maxlength='1'
                      ref={(input) => (input6 = input)}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          if (!character6) {
                            input5.focus();
                            setCharacter5('');
                          } else {
                            setCharacter6('');
                          }
                        }
                        if (e.key === 'ArrowLeft') {
                          input5.focus();
                        }
                      }}
                      aria-label='digit 6 of 6'
                      aria-disabled='false'
                      aria-invalid='false'
                      className='text-transformation'
                      value={character6}
                      onChange={handleTextChange6}
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <div
              className={`custom-invalid-feedback-text mt--3 pt-3 text-lg
        ${invalidTextFlag ? '' : ' hidden-class'}`}
            >
              <i className='fas fa-exclamation-circle' />
              <span>{invalidText}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationCode;
