import axios from 'utils/api/axios';

// MAKE AN API REQUEST & RESOLVE PROMISE AFTER AXIOS REQUEST
export const makeAPICall = ({
  url = '',
  method = 'GET',
  data = {},
  authorizedRequest,
}) => {
  return new Promise((resolve, reject) => {
    const config = { url, method, data, authorizedRequest };
    axios
      .request(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error?.response) {
          if (error?.response?.status === 400) {
            if (
              error?.response?.data?.message === 'user already exists' ||
              error?.response?.data?.message ===
                'user is currently logging time in this project'
            ) {
              reject(error?.response?.data?.message);
            } else reject('Bad Request');
          } else if (error?.response?.status === 401) {
            reject('Unauthorized Access');
          } else if (error?.response?.status === 403) {
            reject('Forbidden Action');
          } else if (error?.response?.status === 404) {
            reject('Unable to find requested record');
          } else if (error?.response?.status === 406) {
            reject('Not Acceptable');
          } else if (error?.response?.status === 500) {
            reject('Internal Server Error');
          } else if (error?.response?.status === 502) {
            reject('Bad Gateway');
          } else {
            reject('Could not Process Request');
          }
        }
        reject('Could not Process Request');
      });
  });
};

export const getJWT = () => {
  return localStorage.getItem('jwt-token');
};
