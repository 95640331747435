import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle,
  NavItem,
  NavLink,
} from 'reactstrap';
import { getWorkspaces } from 'utils/api/api-requests/auth';
import './WorkspaceDropdown.scss';
import WorkspaceDropdownItem from './WorkspaceDropdownItem';
import { toast } from 'react-toastify';
import WorkspaceModal from './WorkspaceModal';
import { useDispatch, useSelector } from 'react-redux';
import { setWorkspaceModal } from 'redux/actions/general';
const WorkspaceDropdown = () => {
  const { workspace } = useParams();
  const [workspacesList, setWorkspacesList] = useState([]);
  const [carotFlag, setCarotFlag] = useState(false);
  const {
    workspaceModal,
    employeeRoleModal,
    mploUser: { roles },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    getWorkspaces()
      .then(({ data }) => {
        setWorkspacesList(data);
      })
      .catch(() => toast.error('Failed to fetch workspaces'));
  }, [workspaceModal, employeeRoleModal]);
  return (
    <>
      <Dropdown
        nav
        isOpen={carotFlag}
        toogle={() => setCarotFlag(!carotFlag)}
        rootClose
      >
        <DropdownToggle className='p-0 pr-3' nav>
          <DropdownItem
            className='dropdown-heading nav-heading dropdown-item-nav'
            onClick={() => setCarotFlag(!carotFlag)}
          >
            <span
              className='custom-nav-link-text'
              data-toggle='collapse'
              aria-expanded={true}
            >
              {' '}
              <span className='nav-heading-text'>{workspace}</span>{' '}
            </span>
          </DropdownItem>
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-center custom-dropdown-menu'>
          <DropdownItem className='dropdown-heading'>
            <span>Workspaces</span>
          </DropdownItem>

          {workspacesList.length > 0 ? (
            workspacesList?.map(({ name, alias, roles }) => (
              <WorkspaceDropdownItem
                name={name}
                alias={alias}
                roles={roles?.filter((role) => role !== 'OWNER')}
              />
            ))
          ) : (
            <WorkspaceDropdownItem name='No workspace joined' />
          )}
          {roles?.map((role) => role?.toLowerCase())?.includes('admin') ? (
            <NavItem key='add-new-workspace'>
              <NavLink
                className='custom-nav-link cursor-pointer nav-link-text add-workspace-link'
                onClick={(e) => {
                  e?.preventDefault();
                  dispatch(setWorkspaceModal(true));
                }}
              >
                <span className='custom-nav-link-text add-workspace'>
                  <i className='fas fa-plus' />
                  <div>Add workspace</div>
                </span>
              </NavLink>
            </NavItem>
          ) : (
            ''
          )}
        </DropdownMenu>
      </Dropdown>
      <WorkspaceModal />
    </>
  );
};

export default WorkspaceDropdown;
