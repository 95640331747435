import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardHeader, CardBody, Button, Row, Spinner } from 'reactstrap';
import ProjectLogTable from './TableView/Table';
import LogsFilter from './LogsFilter';
import CSVExporter from './CSVExporter';
import { useHistory, useParams } from 'react-router-dom';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import { setLogAddModal, setLogsViewMode } from 'redux/actions/logs';
import { filterViews } from './SampleViewData';
import {
  setAreLogsBeingLoaded,
  setIsEditingTrackingLog,
  setProjectLogsList,
} from 'redux/actions/logs';
import {
  setCustomSliderArray,
  setReasonForLateSitting,
  setTimeLogEndTime,
  setTimeLogStartTime,
  setWorkedOnWhat,
  setProjectsList,
} from 'redux/actions/projects';
import { getUserDashboard } from 'utils/api/api-requests/user';
import { getProjects } from 'utils/api/api-requests/admin';
import ViewSelector from 'Components/Common/ViewSelector/ViewSelector';
import GridView from './GridView/GridView';
import CalendarView from './CalendarView/CalendarView';
import './LogsContainer.scss';
import { deleteMultipleLogs, getLogsUser } from 'utils/api/api-requests/user';
import { toast } from 'react-toastify';
import { getCustomProjectLogs } from 'utils/helper-functions';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import { formatLoggedTime, getZonedTime } from 'utils/helper-functions';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_INCREMENT } from 'constants/index';
import moment from 'moment';
import { setLogDate, setLogEditModal, setLogID } from 'redux/actions/logs';
const ProjectLogTableContainer = ({ refreshData }) => {
  const mploUser = useSelector((state) => state.mploUser);

  const dispatch = useDispatch();
  const {
    logsViewMode,
    activeView,
    fromDateFilter,
    toDateFilter,
    projectLogsList,
    areLogsBeingLoaded,
    workspaceAlias,
  } = useSelector((state) => state);
  const history = useHistory();
  const { projectId } = useParams();
  const [projectName, setProjectName] = useState('');
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedLogs, setSelectedLogs] = useState([]);
  useEffect(() => {
    setPageSize(DEFAULT_PAGE_SIZE);
  }, []);

  useEffect(() => {
    if (localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin') {
      getProjects().then(({ data }) => {
        dispatch(setProjectsList(data));
        setProjectName(
          data?.filter(({ uid }) => uid === projectId)?.[0]?.name || ''
        );
      });
    } else if (
      localStorage.getItem('currentUserRole')?.toLowerCase() === 'user'
    ) {
      getUserDashboard()
        .then(({ data: { user_project_details } }) => {
          dispatch(setProjectsList(user_project_details));
          setProjectName(
            user_project_details?.filter(({ uid }) => uid === projectId)?.[0]
              ?.name || ''
          );
        })
        .catch(() => {
          dispatch(setProjectsList([]));
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLogs]);
  const editLogHandler = ({
    uid,
    date,
    timeStart,
    timeEnd,
    worked,
    lateSitting,
    tracking,
  }) => {
    dispatch(setIsEditingTrackingLog(tracking));
    let startMinutes = moment
      .duration(moment(timeStart, 'HH::mm A').format('HH:mm:ss'))
      .asMinutes();
    let endMinutes = moment
      .duration(
        moment(
          tracking === false ? timeEnd : moment().format('HH::mm A'),
          'HH::mm A'
        ).format('HH:mm:ss')
      )
      .asMinutes();

    dispatch(setCustomSliderArray([]));
    dispatch(setLogID(uid));
    dispatch(setTimeLogStartTime(startMinutes));
    dispatch(setTimeLogEndTime(endMinutes));
    dispatch(setWorkedOnWhat(worked));
    dispatch(setReasonForLateSitting(lateSitting === '-' ? '' : lateSitting));
    dispatch(setLogDate(moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')));
    dispatch(setLogEditModal(true));
  };

  const deleteMultipleLogsHandler = () => {
    if (selectedLogs.length > 0) {
      deleteMultipleLogs(selectedLogs, projectId)
        .then(() => {
          setDeleteModal(false);
          setSelectedLogs([]);
          toast.success('Logs deleted successfully');
          dispatch(setAreLogsBeingLoaded(true));
          getLogsUser(projectId, fromDateFilter, toDateFilter)
            .then(({ data }) => {
              dispatch(setProjectLogsList(getCustomProjectLogs(data, false)));
              dispatch(setAreLogsBeingLoaded(false));
            })
            .catch(() => toast?.error('Unable to fetch project logs list'));
        })
        .catch(() => {
          toast.error('Unable to delete log');
        });
    }
  };
  return (
    <>
      <div className='col'>
        <Card className='shadow mb-5'>
          <CardHeader className='border-0'>
            <Row className='align-items-center'>
              <div className='table-container-header'>
                <div className='table-container-heading-select'>
                  {localStorage.getItem('currentUserRole')?.toLowerCase() ===
                  'admin' ? (
                    <div className='col custom-breadcrumb-admin no-button'>
                      <span
                        onClick={() =>
                          history.push(`/${workspaceAlias}/admin/projects`)
                        }
                      >
                        {'Projects '}
                      </span>
                      /{` ${projectName}`}
                    </div>
                  ) : (
                    <div className='col custom-breadcrumb-user'>
                      <span
                        onClick={() => history.push(`/${workspaceAlias}/user`)}
                      >
                        {'Dashboard '}
                      </span>
                      /{` ${projectName}`}
                    </div>
                  )}

                  <div className='table-container-select-group'>
                    <div>
                      <CustomFormGroup
                        type='select'
                        name='selectLogsView'
                        id='selectLogsView'
                        value={logsViewMode}
                        setValue={(value) => {
                          dispatch(setLogsViewMode(value));
                          setSelectedLogs([]);
                        }}
                        iconClassName='fas fa-history fa-lg'
                        invalidText='No view is selected'
                        selectBoxText='Select view'
                        selectBoxArray={filterViews}
                        layout={`${
                          localStorage
                            .getItem('currentUserRole')
                            ?.toLowerCase() === 'admin'
                            ? 'admin'
                            : 'user'
                        }`}
                        invalidTextFlag={true}
                      />
                    </div>

                    {activeView === 'calendar' ? (
                      ''
                    ) : (
                      <>
                        <div
                          className='custom-action-card'
                          onClick={() =>
                            setSelectedLogs(
                              selectedLogs?.length > 0 &&
                                projectLogsList?.length === selectedLogs?.length
                                ? []
                                : projectLogsList?.map(({ uid }) => uid)
                            )
                          }
                        >
                          <span className='styled-checkbox'>
                            <input
                              type='checkbox'
                              checked={
                                selectedLogs?.length > 0 &&
                                projectLogsList?.length === selectedLogs?.length
                              }
                            />

                            <span className='checkbox-text'>Select all</span>
                            <span className='checkmark'></span>
                          </span>
                        </div>

                        <div
                          className={`custom-action-card ${
                            selectedLogs?.length === 0 ? 'disabled' : ''
                          }`}
                        >
                          <CSVExporter
                            selectedLogs={selectedLogs}
                            projectName={projectName}
                          />
                        </div>
                      </>
                    )}
                    {['ADMIN', 'SUPER_ADMIN'].includes(mploUser?.role) ? (
                      ''
                    ) : (
                      <div
                        className={`delete custom-action-card ${
                          selectedLogs?.length === 0 ? 'visibility-hidden' : ''
                        }`}
                        onClick={() => setDeleteModal(true)}
                      >
                        <i className='fa fa-trash-alt delete-icon' />
                        <div>
                          Delete{' '}
                          {selectedLogs?.length > 0 &&
                          projectLogsList?.length === selectedLogs?.length
                            ? 'all'
                            : 'checked'}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='table-container-dates'>
                  <LogsFilter
                    refreshData={refreshData}
                    currentView={logsViewMode}
                  />
                </div>

                <div className='table-container-add-view'>
                  {localStorage.getItem('currentUserRole')?.toLowerCase() ===
                  'admin' ? (
                    ''
                  ) : (
                    <div className='add-log-button-container'>
                      <Button
                        color='primary'
                        className='user-theme-button add-log-button custom-btn-size'
                        onClick={() => dispatch(setLogAddModal(true))}
                      >
                        <span>
                          <i className='fas fa-plus' />
                          <span className='icon-button-text'>Time logger</span>
                        </span>
                      </Button>
                    </div>
                  )}
                  <ViewSelector setSelectedLogs={setSelectedLogs} />
                </div>
              </div>
            </Row>
          </CardHeader>
          <CardBody className='logs-card-body-container'></CardBody>
          <>
            {areLogsBeingLoaded ? (
              <div className='logs-spinner'>
                <Spinner
                  color={mploUser?.role === 'ADMIN' ? 'admin' : 'user'}
                />
              </div>
            ) : projectLogsList?.length > 0 ? (
              <>
                <div className='position-relative'>
                  {activeView === 'list' ? (
                    <ProjectLogTable
                      pageSize={pageSize}
                      selectedLogs={selectedLogs}
                      setSelectedLogs={setSelectedLogs}
                      editLogHandler={editLogHandler}
                    />
                  ) : activeView === 'grid' ? (
                    <GridView
                      pageSize={pageSize}
                      selectedLogs={selectedLogs}
                      setSelectedLogs={setSelectedLogs}
                      editLogHandler={editLogHandler}
                    />
                  ) : activeView === 'calendar' ? (
                    <CalendarView editLogHandler={editLogHandler} />
                  ) : (
                    ''
                  )}

                  <div className='total-hours-container display-flex'>
                    <div className='total-hours'>
                      <span className='total-hours-text'>
                        Total time logged: &nbsp;
                      </span>
                      <span>
                        {formatLoggedTime({
                          hours: getZonedTime({
                            type: 'hours',
                            value: projectLogsList
                              ?.map(({ hours }) => (isNaN(hours) ? 0 : hours))
                              ?.reduce((total, sum) => total + sum),
                          }),
                          minutes: getZonedTime({
                            type: 'minutes',
                            value: projectLogsList
                              ?.map(({ hours }) => (isNaN(hours) ? 0 : hours))
                              ?.reduce((total, sum) => total + sum),
                          }),
                        })}
                      </span>
                    </div>
                    <div>
                      {pageSize < projectLogsList?.length &&
                      activeView !== 'calendar' ? (
                        <span
                          className={`load-more-btn ${
                            ['ADMIN', 'SUPER_ADMIN'].includes(mploUser?.role)
                              ? ''
                              : 'user-load-btn'
                          }`}
                          onClick={() =>
                            setPageSize(pageSize + PAGE_SIZE_INCREMENT)
                          }
                        >
                          LOAD MORE...
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className='no-data-div'>
                Nothing logged{' '}
                <span className='lowercase-text'>
                  {logsViewMode === 'CUSTOM_DATE'
                    ? `from ${fromDateFilter} to ${toDateFilter}`
                    : logsViewMode?.replace(/_/g, ' ')}
                </span>
              </div>
            )}
          </>
        </Card>
      </div>
      <CustomModal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        headerText='Delete Logs'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={deleteMultipleLogsHandler}
      >
        Are you sure you want to delete selected logs?
      </CustomModal>
    </>
  );
};

export default ProjectLogTableContainer;
