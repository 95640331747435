import React from 'react';
import { useDispatch } from 'react-redux';
import AdminHeader from 'Components/Headers/Admin/Admin';
import ClientTable from './Table/Table';
import { Card, CardHeader, Col, Container, Button, Row } from 'reactstrap';
import { setClientAddModal } from 'redux/actions/client';
import ClientAddModal from './Modals/AddModal';
import ClientEditModal from './Modals/EditModal';
import ClientDeleteModal from './Modals/DeleteModal';

const Client = () => {
  const dispatch = useDispatch();
  return (
    <>
      <div className='main-content'>
        <AdminHeader />
        <Container className='mt--7' fluid>
          <Row className='mt-5 mb-5'>
            <Col className='mb-5' xl='12'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <Row className='align-items-center'>
                    <div className='col'>
                      <h3 className='mb-0'>Clients</h3>
                    </div>
                    <div className='col text-right'>
                      <Button
                        className='admin-theme-button custom-btn-size'
                        color='primary'
                        onClick={() => dispatch(setClientAddModal(true))}
                      >
                        <span>
                          <i className='fas fa-plus' /> Add
                        </span>
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <ClientTable />
              </Card>
            </Col>
          </Row>
          <ClientAddModal />
          <ClientEditModal />
          <ClientDeleteModal />
        </Container>
      </div>
    </>
  );
};

export default Client;
