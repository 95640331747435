import React, { useEffect, useState } from 'react';
import { getProjects } from 'utils/api/api-requests/admin';
import { Card, CardHeader, Col, Container, Button, Row } from 'reactstrap';
import AdminHeader from 'Components/Headers/Admin/Admin';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ProjectTable from './Table/Table';
import DeleteModal from 'Components/Admin/Project/Modals/DeleteModal';
import AddModal from 'Components/Admin/Project/Modals/AddModal';
import EditModal from 'Components/Admin/Project/Modals/EditModal';
import { setProjectsList } from 'redux/actions/projects';
import { setProjectAddModal } from 'redux/actions/modals';

const ProjectContainer = () => {
  const { projectsList } = useSelector((state) => state);
  const [areProjectsBeingLoaded, setAreProjectsBeingLoaded] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setAreProjectsBeingLoaded(true);
    getProjects()
      .then(({ data }) => {
        dispatch(setProjectsList(data));
        setAreProjectsBeingLoaded(false);
      })
      .catch(() => {
        toast?.error('Unable to fetch project list.');
        setAreProjectsBeingLoaded(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='main-content'>
        <AdminHeader />

        <Container className='mt--7' fluid>
          <Row className='mt-5 mb-5'>
            <Col className='mb-5' xl='12'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <Row className='align-items-center'>
                    <div className='col'>
                      <h3 className='mb-0'>Projects</h3>
                    </div>
                    <div className='col text-right'>
                      <Button
                        color='primary'
                        onClick={(e) => {
                          e?.preventDefault();
                          dispatch(setProjectAddModal(true));
                        }}
                        className='admin-theme-button custom-btn-size'
                      >
                        <span>
                          <i className='fas fa-plus' /> Add a new project
                        </span>
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <ProjectTable
                  projects={projectsList}
                  areProjectsBeingLoaded={areProjectsBeingLoaded}
                />
              </Card>
            </Col>
          </Row>
          <DeleteModal />
          <AddModal />
          <EditModal />
        </Container>
      </div>
    </>
  );
};

export default ProjectContainer;
