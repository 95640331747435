import RedirectSpinner from 'Components/Common/RedirectSpinner/RedirectSpinner';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'utils/helper-functions';
import { whoamiApiRequest } from 'utils/api/api-requests/admin';
import { setMploUser } from 'redux/actions/general';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const Logout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let query = useQuery(useLocation);
  useEffect(() => {
    let refresh = query?.get('refresh');
    let lastWorkspace = localStorage.getItem('workspace');
    if (refresh === 'true') {
      if (localStorage.getItem('jwt-token')) {
        whoamiApiRequest(lastWorkspace)
          .then(({ data: { name, roles, uid, email } }) => {
            const mploUser = {
              name,
              roles,
              uid,
              email,
            };
            dispatch(setMploUser(mploUser));
            if (roles?.includes('ADMIN')) {
              if (
                localStorage.getItem('currentUserRole')?.toLowerCase() ===
                'admin'
              ) {
                localStorage.setItem('currentUserRole', 'admin');
                history.push(`/${lastWorkspace}/admin/index`);
              } else if (roles?.includes('EMPLOYEE')) {
                localStorage.setItem('currentUserRole', 'user');
                history.push(`/${lastWorkspace}/user/index`);
              }
            } else if (roles?.includes('EMPLOYEE')) {
              if (
                localStorage.getItem('currentUserRole')?.toLowerCase() ===
                'user'
              ) {
                localStorage.setItem('currentUserRole', 'user');
                history.push(`/${lastWorkspace}/user/index`);
              } else if (
                localStorage.getItem('currentUserRole')?.toLowerCase() ===
                'admin'
              ) {
                localStorage.setItem('currentUserRole', 'admin');
                history.push(`/${lastWorkspace}/admin/index`);
              }
            }
          })
          .catch(() => {
            toast.error('Failed to get logged in user data');
            history.push('/auth/login');
          });
      } else history.push('/auth/login');
    } else {
      localStorage.clear();
      let workspace = query?.get('workspaceName');
      let alias = query?.get('workspace');
      let action = query?.get('action');
      let newUserFlag = query?.get('new_user');
      let userEmail = query?.get('user_email');

      if (action === 'join' && alias) {
        localStorage.setItem('joinWorkspaceAlias', alias);
      } else if (action === 'add' && workspace && alias) {
        localStorage.setItem('addWorkspace', workspace);
        localStorage.setItem('addWorkspaceAlias', alias);
      }
      localStorage.setItem('newUserFlag', newUserFlag);
      localStorage.setItem('userEmail', userEmail);
      localStorage.setItem('workspaceAction', action);

      setTimeout(
        () =>
          history.push(
            `/auth/${
              action === 'join'
                ? alias
                  ? newUserFlag === 'true'
                    ? 'signup'
                    : 'login'
                  : 'login'
                : action === 'add'
                ? workspace && alias
                  ? newUserFlag === 'true'
                    ? 'signup'
                    : 'login'
                  : 'login'
                : 'login'
            }`
          ),
        1000
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='redirect-header'>
      <center className='d-flex flex-column align-items-center '>
        <RedirectSpinner text='Redirect .... page' />
      </center>
    </div>
  );
};

export default Logout;
