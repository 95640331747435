import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getClients, deleteClient } from 'utils/api/api-requests/admin';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import { toast } from 'react-toastify';
import {
  setClientList,
  setClientObject,
  setClientDeleteModal,
} from 'redux/actions/client';

const DeleteModal = () => {
  const { clientDeleteModal, clientObject } = useSelector((state) => state);
  const dispatch = useDispatch();

  const toggleDelete = (clientObject) => {
    dispatch(setClientObject(clientObject));
    dispatch(setClientDeleteModal(!clientDeleteModal));
  };
  const handleDeleteClient = (e) => {
    e?.preventDefault();
    deleteClient(clientObject?.uid)
      .then(() => {
        toast.success('Client deleted successfully.');
        getClients().then(({ data }) => dispatch(setClientList(data)));
      })
      .catch(() => toast.error('Failed to delete client.'));

    dispatch(setClientDeleteModal(false));
  };

  return (
    <>
      <CustomModal
        isOpen={clientDeleteModal}
        toggle={toggleDelete}
        headerText='Delete project'
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        confirmFunction={handleDeleteClient}
      >
        Are you sure you want to delete this client?
      </CustomModal>
    </>
  );
};

export default DeleteModal;
