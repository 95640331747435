import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { getUserActivity } from 'utils/api/api-requests/user';
import { isUserLoggedIn, isAdminLoggedIn } from 'utils/helper-functions';
import { getAdminActivity } from 'utils/api/api-requests/admin';
import ActivityCard from '../ActivityCard/ActivityCard';

const CurrentUserActivity = ({ fromDate, toDate }) => {
  const [adminActivityLogs, setAdminActivityLogs] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    setIsDataLoading(true);
    if (isAdminLoggedIn()) {
      getAdminActivity(fromDate, toDate)
        .then(({ data }) => {
          setAdminActivityLogs(data);
          setIsDataLoading(false);
        })
        .catch(() => {
          setIsDataLoading(false);
        });
    } else if (isUserLoggedIn()) {
      getUserActivity(fromDate, toDate)
        .then(({ data }) => {
          setAdminActivityLogs(data);
          setIsDataLoading(false);
        })
        .catch(() => {
          setIsDataLoading(false);
        });
    }
  }, [fromDate, toDate]);
  return isDataLoading ? (
    <div className='activity-spinner-padding text-center'>
      <Spinner
        color={
          localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            ? 'admin'
            : 'user'
        }
        size='lg'
      />
    </div>
  ) : (
    <div className='activity-list'>
      {adminActivityLogs?.length > 0 ? (
        adminActivityLogs?.map((log) => <ActivityCard log={log} />)
      ) : (
        <div className='no-activity-data'>No activity found</div>
      )}
    </div>
  );
};

export default CurrentUserActivity;
