export const setClientObject = (clientObject) => ({
  type: 'SET_CLIENT_OBJECT',
  payload: clientObject,
});

export const setClientList = (clientList) => ({
  type: 'SET_CLIENT_LIST',
  payload: clientList,
});

export const setClientAddModal = (clientAddModal) => ({
  type: 'SET_CLIENT_ADD_MODAL',
  payload: clientAddModal,
});

export const setClientEditModal = (clientEditModal) => ({
  type: 'SET_CLIENT_EDIT_MODAL',
  payload: clientEditModal,
});

export const setClientDeleteModal = (clientDeleteModal) => ({
  type: 'SET_CLIENT_DELETE_MODAL',
  payload: clientDeleteModal,
});
