import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  isAdminLoggedIn,
  isUserLoggedIn,
} from '../../../utils/helper-functions';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from 'reactstrap';
import { setProjectLogsList } from 'redux/actions/logs';

import WorkspaceDropdown from './WorkspaceDropdown/WorkspaceDropdown';

const AdminNavbar = (props) => {
  const { workspace } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const mploUser = useSelector((state) => state.mploUser);

  return (
    <>
      <Navbar className='navbar-top navbar-dark' expand='md' id='navbar-main'>
        <Container fluid>
          <Link
            className='h4 mb-0 text-white text-uppercase d-none d-lg-inline-block'
            to='/'
          >
            {props.brandText}
          </Link>
          <Nav className='align-items-center d-none d-md-flex' navbar>
            <WorkspaceDropdown />
            <UncontrolledDropdown nav>
              <DropdownToggle className='pr-0 pl-0' nav>
                <Media className='align-items-center'>
                  <span className='avatar avatar-sm rounded-circle'>
                    <img
                      alt='...'
                      src={require('assets/img/theme/no-user-1.jpeg').default}
                    />
                  </span>
                  <Media className='ml-2 d-none d-lg-block'>
                    <span
                      className={`mb-0 text-sm font-weight-bold  ${
                        localStorage
                          .getItem('currentUserRole')
                          ?.toLowerCase() === 'admin'
                          ? 'topbar-admin-name'
                          : 'topbar-user-name'
                      }`}
                    >
                      {mploUser?.name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-arrow' right>
                <DropdownItem
                  onClick={() => {
                    history.push(
                      `/${workspace}/${
                        isAdminLoggedIn()
                          ? 'admin'
                          : isUserLoggedIn()
                          ? 'user'
                          : ''
                      }/profile`
                    );
                  }}
                  className='dropdown-logout'
                >
                  <i className='ni ni-settings' />
                  <span>Settings</span>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    localStorage.clear();
                    dispatch(setProjectLogsList([]));
                    history.push(`/logout`);
                  }}
                  className='dropdown-logout'
                >
                  <i className='ni ni-user-run' />
                  <span>Logout From Workspace</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
