import React, { useState } from 'react';
import { validateAlias, addWorkspace } from 'utils/api/api-requests/auth';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  InputGroup,
  Form,
} from 'reactstrap';
import { setWorkspaceModal } from 'redux/actions/general';
const WorkspaceModal = () => {
  const { workspaceModal } = useSelector((state) => state);
  const [workspaceName, setWorkspaceName] = useState('');
  const [didSubmitButtonClick, setDidSubmitButtonClick] = useState(false);
  const [message, setMessage] = useState(
    'Name must be longer than 5 characters'
  );
  const [aliasFlag, setAliasFlag] = useState(false);

  const dispatch = useDispatch();
  const toggle = () => {
    dispatch(setWorkspaceModal(!workspaceModal));
    setDidSubmitButtonClick(false);
    setWorkspaceName('');
    setMessage('Name must be longer than 5 characters');
  };
  const addWorkspaceHandler = (e) => {
    e.preventDefault();
    setDidSubmitButtonClick(true);
    let workspace = {
      name: workspaceName,
      alias: workspaceName
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '')
        .slice(0, 12),
    };
    if (workspaceName && workspaceName?.length > 5) {
      validateAlias(
        workspaceName
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, '')
          .slice(0, 12)
      )
        .then(() =>
          addWorkspace(workspace)
            .then(() => {
              toast.success('Workspace created successfully');

              dispatch(setWorkspaceModal(!workspaceModal));
              setDidSubmitButtonClick(false);
            })
            .catch(() => {
              toast.error('Invalid input! Unable to add workspace');
              dispatch(setWorkspaceModal(!workspaceModal));
              setDidSubmitButtonClick(false);
            })
        )
        .catch(() => {
          setAliasFlag(true);
          setMessage('Name not available! Please try some other name');
        });
    } else {
      setAliasFlag(true);
    }
  };

  return (
    <div>
      <Modal isOpen={workspaceModal} toggle={toggle} centered>
        <ModalHeader className='workspace-modal-header'>
          Write your workspace name
        </ModalHeader>
        <ModalBody className='workspace-modal-body'>
          <Form
            onKeyPress={(e) =>
              e?.key === 'Enter' ? addWorkspaceHandler(e) : {}
            }
          >
            <InputGroup size='sm'>
              <input
                type='text'
                value={workspaceName}
                placeholder='e.g. Developers State'
                className={
                  didSubmitButtonClick && aliasFlag ? 'invalid-input' : ''
                }
                onChange={(e) => {
                  setWorkspaceName(e?.target?.value);
                  setDidSubmitButtonClick(false);
                  setAliasFlag(false);
                }}
              />
            </InputGroup>
            <p
              className={`error-message ${
                didSubmitButtonClick && aliasFlag ? '' : 'hidden-class'
              }`}
            >
              {message}
            </p>
          </Form>
        </ModalBody>
        <ModalFooter className='workspace-modal-footer'>
          <button
            className='modal-filled'
            onClick={(e) => addWorkspaceHandler(e)}
          >
            Submit
          </button>
          <button
            className='modal-unfilled'
            onClick={() => dispatch(setWorkspaceModal(false))}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default WorkspaceModal;
