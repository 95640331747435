import React, { useState } from 'react';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import { login } from 'utils/api/api-requests/auth';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { sendLoginEmail } from 'utils/api/api-requests/auth';
import VerificationCode from 'Components/Common/VerificationCode/VerificationCode';
import { whoamiApiRequest } from 'utils/api/api-requests/admin';
import { setNewWorkspaceObject } from 'redux/actions/general';
import { useDispatch } from 'react-redux';
import { setMploUser } from '../../redux/actions/general';

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [signinWithEmail, setSigninWithEmail] = useState(true);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [verificationCodeFlag, setVerificationCodeFlag] = useState(false);
  const [email, setEmail] = useState('');
  const setEmailHandler = (value) => {
    setEmail(value);
    setIsSubmitButtonClicked(false);
  };

  const loginHandler = (event) => {
    event.preventDefault();
    setIsSubmitButtonClicked(true);
    if (signinWithEmail) {
      login({ email, password })
        .then((resp) => {
          localStorage.setItem('jwt-token', resp.headers['jwt-token']);
          let lastWorkspace = resp.data?.workspaces_joined?.[0]?.alias;
          let action = localStorage.getItem('workspaceAction');
          let name = '',
            alias = '';
          if (action === 'add') {
            name = localStorage.getItem('addWorkspace');
            alias = localStorage.getItem('addWorkspaceAlias');
            dispatch(
              setNewWorkspaceObject({
                name,
                alias,
                action,
              })
            );
            history.push(`/redirect/${alias}/${resp.headers['jwt-token']}`);
          } else if (action === 'join') {
            alias = localStorage.getItem('joinWorkspaceAlias');
            dispatch(
              setNewWorkspaceObject({
                name,
                alias,
                action,
              })
            );
            history.push(`/redirect/${alias}/${resp.headers['jwt-token']}`);
          } else {
            localStorage.setItem('workspace', lastWorkspace);
            alias = lastWorkspace;
            whoamiApiRequest(lastWorkspace)
              .then(({ data: { name, roles, uid, email } }) => {
                const mploUser = {
                  name,
                  roles,
                  uid,
                  email,
                };
                dispatch(setMploUser(mploUser));
                roles?.includes('ADMIN')
                  ? localStorage.setItem('currentUserRole', 'admin')
                  : roles?.includes('EMPLOYEE')
                  ? localStorage.setItem('currentUserRole', 'user')
                  : localStorage.setItem('currentUserRole', 'null');

                history.push(`/redirect/${alias}/${resp.headers['jwt-token']}`);
              })
              .catch(() => {
                toast.error('Failed to get logged in user data');
              });
          }
          toast.success('Logged-in successfully');
        })
        .catch(() => {
          toast.error('Invalid email or password.');
        });
    } else {
      sendLoginEmail({ email })
        .then(() => {
          setVerificationCodeFlag(true);
        })
        .catch(() => {
          toast.error('Invalid email');
        });
    }
  };

  return (
    <>
      {!verificationCodeFlag ? (
        <>
          <CustomFormGroup
            id='email'
            placeholder='Email'
            type='text'
            name='loginmail'
            value={email}
            setValue={setEmailHandler}
            iconClassName='ni ni-email-83'
            didSubmitButtonClick={isSubmitButtonClicked}
            invalidText='Invalid email address'
          />
          {!signinWithEmail ? (
            ''
          ) : (
            <CustomFormGroup
              type='password'
              name='loginPassword'
              id='loginPassword'
              placeholder='Password'
              value={password}
              setValue={setPassword}
              iconClassName='ni ni-lock-circle-open'
              invalidText='Password is empty'
              didSubmitButtonClick={isSubmitButtonClicked}
            />
          )}
          <div className='custom-input-group'>
            <input
              type='submit'
              className='custom-fw-btn'
              value='LOGIN'
              onClick={(e) => loginHandler(e)}
            />
          </div>
          <div className='signin-links'>
            <a
              href
              className='link'
              onClick={() => history.push('/auth/signup')}
            >
              Create account
            </a>
            <a
              href
              className='link'
              onClick={() => history.push('/auth/forgot-password')}
            >
              Forgot password?
            </a>
          </div>
          <div className='magic-link-container'>
            {signinWithEmail ? (
              <div>
                You can{' '}
                <span onClick={() => setSigninWithEmail(!signinWithEmail)}>
                  sign in with a magic code instead.
                </span>
              </div>
            ) : (
              <div>
                We'll email you a magic code for a password-free sign in. Or you
                can{' '}
                <span onClick={() => setSigninWithEmail(!signinWithEmail)}>
                  sign in manually instead.
                </span>
              </div>
            )}
          </div>
        </>
      ) : (
        <VerificationCode loginEmail={email} />
      )}
    </>
  );
};

export default Login;
