import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Spinner,
} from 'reactstrap';

import UserHeader from 'Components/Headers/User/User';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import './User.scss';
import { setProjectsList } from 'redux/actions/projects';
import { setWelcomeStatus } from 'redux/actions/employee';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import LogTimer from 'Components/Common/LogTimer/LogTimer';
import CustomDay from 'Components/Common/CustomDay/CustomDay';
import { getWeekdays, toggleName } from 'utils/helper-functions';
import {
  addTrackingLog,
  getTrackingLogs,
  getUserDashboard,
} from 'utils/api/api-requests/user';
import { setTrackingLogs } from 'redux/actions/logs';
import { formatLoggedTime } from 'utils/helper-functions';

const User = () => {
  const projectsList = useSelector(({ projectsList }) => projectsList);
  const trackingLogs = useSelector(({ trackingLogs }) => trackingLogs);
  const { workspaceAlias } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  let currentDate = moment().format('YYYY-MM-DD');
  const [toggleManager, setToggleManager] = useState([]);
  const [logDescription, setLogDescription] = useState('');
  const [isStartTimerBtnClicked, setIsStartTimerBtnClicked] = useState(false);
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [totalHoursLoggedThisWeek, setTotalHoursLoggedThisWeek] = useState({
    hours: '',
    minutes: '',
  });
  const [totalHoursLoggedThisDay, setTotalHoursLoggedThisDay] = useState({
    hours: '',
    minutes: '',
  });

  const mploUser = useSelector((state) => state.mploUser);

  useEffect(() => {
    getTrackingLogs().then(({ data }) => {
      dispatch(setTrackingLogs(data));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDashboardLoading(true);
    getUserDashboard()
      .then(
        ({
          data: {
            user_project_details,
            show_tips,
            total_hours_logged_current_day,
            total_hours_logged_current_week,
          },
        }) => {
          dispatch(setProjectsList(user_project_details));
          setDashboardLoading(false);
          setTotalHoursLoggedThisWeek(total_hours_logged_current_week);
          setTotalHoursLoggedThisDay(total_hours_logged_current_day);
          dispatch(setWelcomeStatus(show_tips));
        }
      )
      .catch(() => {
        dispatch(setProjectsList([]));
        setTotalHoursLoggedThisWeek({ hours: '', minutes: '' });
        setTotalHoursLoggedThisDay({ hours: '', minutes: '' });
        setDashboardLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingLogs]);
  return (
    <>
      <div className='main-content'>
        <UserHeader />
        <Container className='mt--7' fluid>
          <Row className='mt-5 mb-5'>
            <Col className='mb-5' xl='12'>
              <Card className='shadow'>
                <CardHeader className='border-0 projects-header'>
                  <Row className='align-items-center'>
                    <div className='col'>
                      <h3 className='mb-0'>Projects</h3>
                    </div>
                    <div
                      className='d-flex justify-content-between'
                      style={{ marginRight: '15px' }}
                    >
                      <Card className='user-dashboard-card'>
                        <h3>Total time logged this week</h3>
                        {dashboardLoading ? (
                          <div className='user-dashboard-card-loader' />
                        ) : (
                          <p>
                            {formatLoggedTime(
                              totalHoursLoggedThisWeek,
                              'this week'
                            )}
                          </p>
                        )}
                      </Card>
                      <Card className='user-dashboard-card ml-3'>
                        <h3>Total time logged this day</h3>
                        {dashboardLoading ? (
                          <div className='user-dashboard-card-loader' />
                        ) : (
                          <p>
                            {formatLoggedTime(totalHoursLoggedThisDay, 'today')}
                          </p>
                        )}
                      </Card>
                    </div>
                  </Row>
                </CardHeader>
                {dashboardLoading ? (
                  <div className='logs-spinner'>
                    <Spinner color='user' />
                  </div>
                ) : (
                  <>
                    {projectsList?.length > 0 ? (
                      <Table
                        className='align-items-center table-flush user-project-table'
                        responsive
                      >
                        <thead className='thead-light'>
                          <tr>
                            <th scope='col'>Name</th>
                            <th scope='col'>Manager</th>
                            <th />
                            <th />
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {projectsList?.map(
                            ({
                              uid,
                              name,
                              managers,
                              start_date,
                              end_date,
                              log_details,
                            }) => (
                              <tr key={uid}>
                                <td>
                                  <NavLink
                                    className='custom-link-class  user-theme-color'
                                    activeClassName='custom-link-class-active'
                                    to={`/${workspaceAlias}/user/project/${uid}/logs`}
                                  >
                                    {name}
                                  </NavLink>
                                </td>
                                <td>
                                  {managers?.[0]?.name ? (
                                    mploUser?.name !== managers?.[0]?.name ? (
                                      <div>{managers?.[0]?.name}</div>
                                    ) : (
                                      <div
                                        className='toggle-name'
                                        onClick={() =>
                                          toggleName({
                                            uid,
                                            array: toggleManager,
                                            setArray: setToggleManager,
                                          })
                                        }
                                      >
                                        {toggleManager?.includes(uid)
                                          ? managers?.[0]?.name
                                          : 'You'}
                                      </div>
                                    )
                                  ) : (
                                    '-'
                                  )}
                                </td>
                                <td>
                                  <div className='d-flex'>
                                    {getWeekdays()?.map((day, index) => (
                                      <CustomDay
                                        day={day}
                                        key={index}
                                        index={index}
                                        projectId={uid}
                                        logDetails={log_details}
                                        missed={
                                          !log_details
                                            ?.map(({ log_date }) =>
                                              moment(
                                                log_date,
                                                'YYYY-MM-DD'
                                              ).format('YYYY-MM-DD')
                                            )
                                            ?.includes(
                                              moment()
                                                .startOf('isoweek')
                                                .add(index, 'days')
                                                .format('YYYY-MM-DD')
                                            )
                                        }
                                      ></CustomDay>
                                    ))}
                                  </div>
                                </td>
                                <td className='justify-content-end text-default'>
                                  {currentDate <
                                  moment(start_date, 'YYYY-MM-DD').format(
                                    'YYYY-MM-DD'
                                  ) ? (
                                    '-'
                                  ) : (
                                    <div
                                      className='view-logs-btn'
                                      onClick={() =>
                                        history.push(
                                          `/${workspaceAlias}/user/project/${uid}/logs`
                                        )
                                      }
                                    >
                                      View logs
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {trackingLogs
                                    ?.map(({ project_uid }) => project_uid)
                                    ?.includes(uid) ? (
                                    <LogTimer
                                      projectId={uid}
                                      startTime={
                                        trackingLogs?.filter(
                                          ({ project_uid }) =>
                                            project_uid === uid
                                        )?.[0]?.time_start
                                      }
                                    />
                                  ) : (
                                    <>
                                      <div
                                        className={`log-time-btn ${
                                          trackingLogs?.length > 0 &&
                                          !trackingLogs
                                            ?.map(
                                              ({ project_uid }) => project_uid
                                            )
                                            ?.includes(uid)
                                            ? 'disabled-btn'
                                            : ''
                                        }`}
                                        id={`log-time-${uid}`}
                                        onClick={() => {
                                          setLogDescription('');
                                          setIsStartTimerBtnClicked(false);
                                        }}
                                      >
                                        Time tracker
                                      </div>
                                      <UncontrolledPopover
                                        trigger='legacy'
                                        placement='left'
                                        target={`log-time-${uid}`}
                                      >
                                        <PopoverHeader>Add logs</PopoverHeader>
                                        <PopoverBody>
                                          <CustomFormGroup
                                            type='textarea'
                                            name='logDescription'
                                            placeholder='Description'
                                            value={logDescription}
                                            iconClassName='fas fa-quote-right'
                                            setValue={setLogDescription}
                                            invalidText='Description cannot be empty'
                                            didSubmitButtonClick={
                                              isStartTimerBtnClicked
                                            }
                                          />
                                          <center>
                                            <Button
                                              className='start-timer-btn mb-2'
                                              onClick={() => {
                                                setIsStartTimerBtnClicked(true);
                                                if (logDescription) {
                                                  addTrackingLog({
                                                    projectId: uid,
                                                    log: {
                                                      time_start:
                                                        moment().format(
                                                          'YYYY-MM-DDTHH:mm:ssZ'
                                                        ),
                                                      working_on_what:
                                                        logDescription,
                                                    },
                                                  }).then(() => {
                                                    getTrackingLogs().then(
                                                      ({ data }) => {
                                                        dispatch(
                                                          setTrackingLogs(
                                                            data ?? []
                                                          )
                                                        );
                                                      }
                                                    );
                                                  });
                                                  document.body.click();
                                                }
                                              }}
                                            >
                                              Start timer
                                            </Button>
                                          </center>
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                    </>
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    ) : (
                      <div className='no-data-div'>
                        There are no projects yet
                      </div>
                    )}
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default User;
