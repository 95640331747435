import React, { useState, useEffect } from 'react';

import { Row } from 'reactstrap';
import StatCard from 'Components/Headers/StatCard/StatCard';
import { getAdminStats } from 'utils/api/api-requests/admin';
import {
  generateCardInfoLogs,
  generateCardInfoUsers,
  generateCustomComponent,
} from 'utils/helper-functions';

const AdminCard = () => {
  const [timeLoggedMonthly, setTimeLoggedMonthly] = useState({
    hours: '',
    minutes: '',
  });
  const [percentageHoursThisMonth, setPercentageHoursThisMonth] = useState('');
  const [
    percentageChangeTotalHoursMonthly,
    setPercentageChangeTotalHoursMonthly,
  ] = useState('');
  const [hoursAllowedThisMonth, setHoursAllowedThisMonth] = useState('');
  const [activeUsersMonthly, setActiveUsersMonthly] = useState('');

  const [timeLoggedWeekly, setTimeLoggedWeekly] = useState({
    hours: '',
    minutes: '',
  });
  const [percentageHoursThisWeek, setPercentageHoursThisWeek] = useState('');
  const [
    percentageChangeTotalHoursWeekly,
    setPercentageChangeTotalHoursWeekly,
  ] = useState('');
  const [hoursAllowedThisWeek, setHoursAllowedThisWeek] = useState('');
  const [activeUsersWeekly, setActiveUsersWeekly] = useState('');

  const [timeLoggedDaily, setTimeLoggedDaily] = useState({
    hours: '',
    minutes: '',
  });
  const [percentageHoursThisDay, setPercentageHoursThisDay] = useState('');
  const [percentageChangeTotalHoursDaily, setPercentageChangeTotalHoursDaily] =
    useState('');
  const [hoursAllowedThisDay, setHoursAllowedThisDay] = useState('');
  const [activeUsersDaily, setActiveUsersDaily] = useState('');

  const [percentageActiveUsersWeekly, setPercentageActiveUsersWeekly] =
    useState('');

  const [numberOfActiveUsersWeekly, setNumberOfActiveUsersWeekly] =
    useState('');

  useEffect(() => {
    getAdminStats()
      .then(
        ({
          data: {
            time_logged_this_month,
            percentage_hours_logged_this_month,
            percentage_hours_change_from_last_month,
            hours_allowed_this_month,
            active_users_this_month,
            time_logged_this_week,
            percentage_hours_logged_this_week,
            percentage_hours_change_from_last_week,
            hours_allowed_this_week,
            active_users_this_week,
            time_logged_this_day,
            percentage_hours_logged_this_day,
            percentage_hours_change_from_last_day,
            hours_allowed_this_day,
            active_users_this_day,
            percentage_weekly_change_in_active_users,
          },
        }) => {
          setTimeLoggedMonthly(time_logged_this_month);
          setPercentageHoursThisMonth(percentage_hours_logged_this_month);
          setPercentageChangeTotalHoursMonthly(
            percentage_hours_change_from_last_month
          );
          setHoursAllowedThisMonth(hours_allowed_this_month);
          setActiveUsersMonthly(active_users_this_month);
          setTimeLoggedWeekly(time_logged_this_week);
          setPercentageChangeTotalHoursWeekly(
            percentage_hours_change_from_last_week
          );
          setPercentageHoursThisWeek(percentage_hours_logged_this_week);
          setHoursAllowedThisWeek(hours_allowed_this_week);
          setActiveUsersWeekly(active_users_this_week);
          setTimeLoggedDaily(time_logged_this_day);
          setPercentageChangeTotalHoursDaily(
            percentage_hours_change_from_last_day
          );
          setPercentageHoursThisDay(percentage_hours_logged_this_day);
          setHoursAllowedThisDay(hours_allowed_this_day);
          setActiveUsersDaily(active_users_this_day);
          setPercentageActiveUsersWeekly(
            percentage_weekly_change_in_active_users
          );
          setNumberOfActiveUsersWeekly(active_users_this_week?.length);
        }
      )
      .catch((error) => {
        setTimeLoggedMonthly({ hours: 0, minutes: 0 });
        setPercentageHoursThisMonth(0);
        setPercentageChangeTotalHoursMonthly(0);
        setHoursAllowedThisMonth(0);
        setActiveUsersMonthly([]);
        setTimeLoggedWeekly({ hours: 0, minutes: 0 });
        setPercentageHoursThisWeek(0);
        setPercentageChangeTotalHoursWeekly(0);
        setHoursAllowedThisWeek(0);
        setActiveUsersWeekly([]);
        setTimeLoggedDaily({ hours: 0, minutes: 0 });
        setPercentageHoursThisDay(0);
        setPercentageChangeTotalHoursDaily(0);
        setHoursAllowedThisDay(0);
        setActiveUsersDaily([]);
        setPercentageActiveUsersWeekly(0);
        setNumberOfActiveUsersWeekly(0);
      });
  }, []);
  const statCards = [
    generateCardInfoLogs(
      'This month',
      timeLoggedMonthly,
      percentageHoursThisMonth,
      percentageChangeTotalHoursMonthly,
      hoursAllowedThisMonth,
      activeUsersMonthly,
      'Since last month',
      () => generateCustomComponent('danger', 'chart-bar')
    ),

    generateCardInfoLogs(
      'This week',
      timeLoggedWeekly,
      percentageHoursThisWeek,
      percentageChangeTotalHoursWeekly,
      hoursAllowedThisWeek,
      activeUsersWeekly,
      'Since last week',
      () => generateCustomComponent('warning', 'chart-pie')
    ),
    generateCardInfoLogs(
      'Today',
      timeLoggedDaily,
      percentageHoursThisDay,
      percentageChangeTotalHoursDaily,
      hoursAllowedThisDay,
      activeUsersDaily,
      'Since last day',
      () => generateCustomComponent('info', 'percent'),
      0
    ),
    generateCardInfoUsers(
      'This week',
      numberOfActiveUsersWeekly,
      percentageActiveUsersWeekly,
      activeUsersWeekly,
      'Since last week',
      () => generateCustomComponent('yellow', 'users')
    ),
  ];

  return (
    <>
      <Row>
        {statCards?.map(
          (
            {
              title,
              hours,
              minutes,
              progressBarValue,
              hoursAllowed,
              userValue,
              userText,
              activeUsersList,
              percentageArrow,
              percentageValue,
              sinceTimeText,
              CustomComponent,
              activeUsers,
            },
            cardNo
          ) => (
            <StatCard
              cardTitle={title}
              hours={hours}
              minutes={minutes}
              progressBarValue={progressBarValue}
              hoursAllowed={hoursAllowed}
              userValue={userValue}
              userText={userText}
              percentageArrow={percentageArrow}
              percentageValue={percentageValue}
              sinceTimeText={sinceTimeText}
              CustomComponent={CustomComponent}
              activeUsers={activeUsers}
              activeUsersList={activeUsersList}
              customClass='custom-stat-card'
              cardNo={cardNo}
            />
          )
        )}
      </Row>
    </>
  );
};

export default AdminCard;
