import React, { useEffect, useState } from 'react';
import { getUsers, getProjects } from 'utils/api/api-requests/admin';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Spinner,
} from 'reactstrap';
import AdminHeader from 'Components/Headers/Admin/Admin';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { toggleDate } from 'utils/helper-functions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Index = () => {
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [toggledJoiningDates, setToggledJoiningDates] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [areProjectsBeingLoaded, setAreProjectsBeingLoaded] = useState(false);
  const [areEmployeesBeingLoaded, setAreEmployeesBeingLoaded] = useState(false);
  const { workspaceAlias } = useSelector((state) => state);
  let currentDate = moment().format('YYYY-MM-DD');
  const mploUser = useSelector((state) => state.mploUser);

  useEffect(() => {
    const intervalId = setInterval(
      () => setCurrentTime(moment(new Date())),
      60000
    );
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    setAreProjectsBeingLoaded(true);
    setAreEmployeesBeingLoaded(true);
    getProjects()
      .then(({ data }) => {
        setProjects(data);
        setAreProjectsBeingLoaded(false);
      })
      .catch(() => {
        //toast.error('Failed to fetch projects');
        setAreProjectsBeingLoaded(false);
      });
    getUsers()
      .then(({ data }) => {
        setUsers(data);
        setAreEmployeesBeingLoaded(false);
      })
      .catch(() => {
        //toast.error('Failed to fetch employees');
        setAreEmployeesBeingLoaded(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage]);

  return (
    <>
      <div className='main-content'>
        <AdminHeader />
        <Container className='mt--7' fluid>
          <Row className='mt-5 mb-5'>
            <Col className='mb-5' xl='7'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <Row className='align-items-center'>
                    <div className='col'>
                      <h3 className='mb-0'>Projects</h3>
                    </div>
                    <div className='col text-right'>
                      <Link to={`/${workspaceAlias}/admin/projects`}>
                        <Button
                          color='primary'
                          className='admin-theme-button custom-btn-size'
                        >
                          <span className='list-button'>
                            <i className='fas fa-list' /> Show all
                          </span>
                        </Button>
                      </Link>
                    </div>
                  </Row>
                </CardHeader>
                {areProjectsBeingLoaded ? (
                  <div className='logs-spinner'>
                    <Spinner color='admin' />
                  </div>
                ) : projects && projects?.length > 0 ? (
                  <Table
                    className='align-items-center table-flush dashboard-project-table'
                    responsive
                  >
                    <thead className='thead-light'>
                      <tr>
                        <th scope='col'>Name</th>
                        <th scope='col'>Client</th>
                        <th scope='col'>Manager</th>
                        <th scope='col' />
                      </tr>
                    </thead>
                    <tbody>
                      {projects?.map(
                        ({
                          uid,
                          name,
                          client,
                          managers,
                          start_date,
                          end_date,
                        }) => (
                          <tr key={uid}>
                            <td>
                              <NavLink
                                className='custom-link-class admin-theme-color'
                                activeClassName='custom-link-class-active'
                                to={`/${workspaceAlias}/admin/project/${uid}/details`}
                              >
                                {name}
                              </NavLink>
                            </td>
                            <td>
                              <Link
                                className='custom-link-class admin-theme-color'
                                to={`/${workspaceAlias}/admin/clients/${client?.uid}`}
                              >
                                {client?.name}{' '}
                              </Link>
                            </td>
                            <td>{managers?.[0]?.name}</td>
                            <td>
                              {' '}
                              {currentDate <
                                moment(start_date, 'YYYY-MM-DD').format(
                                  'YYYY-MM-DD'
                                ) ||
                              currentDate >
                                moment(end_date, 'YYYY-MM-DD').format(
                                  'YYYY-MM-DD'
                                ) ? (
                                '-'
                              ) : (
                                <>
                                  <OverlayTrigger
                                    placement='right'
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>View Logs</Tooltip>}
                                  >
                                    <Link
                                      className='custom-link-class admin-theme-color'
                                      to={`/${workspaceAlias}/admin/project/${uid}/logs`}
                                    >
                                      <i className='fas fa-eye fa-lg' />
                                    </Link>
                                  </OverlayTrigger>
                                </>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <div className='no-data-div'>There are no projects yet</div>
                )}
              </Card>
            </Col>
            <Col className='mb-5' xl='5'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <Row className='align-items-center'>
                    <div className='col'>
                      <h3 className='mb-0'>Employees</h3>
                    </div>
                    <div className='col text-right'>
                      <Button
                        color='primary'
                        onClick={() =>
                          history.push(`/${workspaceAlias}/admin/users`)
                        }
                        className='admin-theme-button custom-btn-size'
                      >
                        <span className='list-button'>
                          <i className='fas fa-list' /> Show all
                        </span>
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                {areEmployeesBeingLoaded ? (
                  <div className='logs-spinner'>
                    <Spinner color='admin' />
                  </div>
                ) : users?.length > 0 ? (
                  <Table
                    className='align-items-center table-flush dashboard-employee-table'
                    responsive
                  >
                    <thead className='thead-light'>
                      <tr>
                        <th scope='col'>Name</th>
                        <th scope='col'>Joined On</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users
                        ?.filter(({ roles }) =>
                          mploUser?.roles?.includes('OWNER')
                            ? true
                            : !roles?.includes('OWNER')
                        )
                        ?.map(
                          ({ user_type, uid, name, joined_on, created_at }) =>
                            !['ADMIN', 'SUPER_ADMIN']?.includes(user_type) ? (
                              <tr key={uid}>
                                <td>{name}</td>
                                <td
                                  className='toggled-date'
                                  onClick={() =>
                                    toggleDate({
                                      uid,
                                      array: toggledJoiningDates,
                                      setArray: setToggledJoiningDates,
                                    })
                                  }
                                >
                                  {toggledJoiningDates?.includes(uid)
                                    ? moment(joined_on, 'YYYY-MM-DD').format(
                                        'LL'
                                      )
                                    : moment(joined_on).isSame(
                                        moment().format('YYYY-MM-DD')
                                      )
                                    ? moment(created_at).from(
                                        moment(currentTime)
                                      )
                                    : moment(joined_on).from(
                                        moment(currentTime)
                                      )}
                                </td>
                              </tr>
                            ) : (
                              ''
                            )
                        )}
                    </tbody>
                  </Table>
                ) : (
                  <div className='no-data-div'>There are no users yet</div>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;
