import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserList, setEmployeeData } from 'redux/actions/employee';
import {
  getUsers,
  archiveMultipleUsers,
  unarchiveMultipleUsers,
  deleteMultipleUsers,
} from 'utils/api/api-requests/admin';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import { Table, Spinner } from 'reactstrap';
import { toggleDate, updateStateArray } from 'utils/helper-functions';
import moment from 'moment';
import { toast } from 'react-toastify';
import './Table.scss';
import { setEmployeeRoleModal } from 'redux/actions/modals';
import RolesModal from '../Modals/RolesModal';

const UserTable = ({ data, updateUsersStatus, areArchivedEmployees }) => {
  const mploUser = useSelector((state) => state.mploUser);

  const dispatch = useDispatch();
  const [toggledJoiningDates, setToggledJoiningDates] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [areEmployeesBeingLoaded, setAreEmployeesBeingLoaded] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [unArchiveModal, setUnArchiveModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedActiveUsers, setSelectedActiveUsers] = useState([]);
  const [selectedArchivedUsers, setSelectedArchivedUsers] = useState([]);

  useEffect(() => {
    const intervalId = setInterval(
      () => setCurrentTime(moment(new Date())),
      60000
    );
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setAreEmployeesBeingLoaded(true);
    getUsers()
      .then(({ data }) => {
        dispatch(setUserList(data));
        updateUsersStatus(data);
        setAreEmployeesBeingLoaded(false);
      })
      .catch(() => {
        toast.error('Unable to fetch user list');
        setAreEmployeesBeingLoaded(false);
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const unarchiveMultipleUsersHandler = () => {
    if (selectedArchivedUsers?.length > 0) {
      unarchiveMultipleUsers(selectedArchivedUsers)
        .then(() => {
          setSelectedArchivedUsers([]);
          setUnArchiveModal(false);
          toast.success('Users are successfully unarchived');
          getUsers()
            .then(({ data }) => {
              dispatch(setUserList(data));
              setAreEmployeesBeingLoaded(false);
              updateUsersStatus(data);
            })
            .catch(() => {
              toast.error('Unable to fetch user list');
              setAreEmployeesBeingLoaded(false);
            });
        })
        .catch(() => {
          toast.success('Unable to unarchive users');
        });
    }
  };
  const archiveMultipleUsersHandler = () => {
    if (selectedActiveUsers?.length > 0) {
      archiveMultipleUsers(selectedActiveUsers)
        .then(() => {
          setSelectedActiveUsers([]);
          setArchiveModal(false);
          toast.success('Users are successfully archived');
          getUsers()
            .then(({ data }) => {
              dispatch(setUserList(data));
              setAreEmployeesBeingLoaded(false);
              updateUsersStatus(data);
            })
            .catch(() => {
              toast.error('Unable to fetch user list');
              setAreEmployeesBeingLoaded(false);
            });
        })
        .catch(() => {
          toast.success('Unable to archive users');
        });
    }
  };
  const deleteMultipleUsersHandler = () => {
    if (selectedArchivedUsers?.length > 0) {
      deleteMultipleUsers(selectedArchivedUsers)
        .then(() => {
          setSelectedActiveUsers([]);
          setSelectedArchivedUsers([]);
          setDeleteModal(false);
          toast.success('Users are successfully deleted');
          setArchiveModal(false);
          getUsers()
            .then(({ data }) => {
              dispatch(setUserList(data));
              updateUsersStatus(data);
              setAreEmployeesBeingLoaded(false);
            })
            .catch(() => {
              toast.error('Unable to fetch user list');
              setAreEmployeesBeingLoaded(false);
            });
        })
        .catch(() => {
          toast.success('Unable to delete users');
        });
    }
  };

  return (
    <>
      {areEmployeesBeingLoaded ? (
        <div className='logs-spinner'>
          <Spinner color='admin' />
        </div>
      ) : data?.length > 0 ? (
        <>
          {selectedActiveUsers?.length > 0 ? (
            <div className='admin-action-bar actions-bar archive'>
              <img
                alt='...'
                className='avatar no-user-img rounded-circle mr-3 archive-icon'
                src={require('assets/svg/archive.svg').default}
                title={'archive'}
                onClick={setArchiveModal}
              />
            </div>
          ) : selectedArchivedUsers?.length > 0 ? (
            <div className='admin-action-bar actions-bar archive'>
              <div>
                <img
                  alt='...'
                  className='avatar no-user-img rounded-circle mr-3'
                  src={require('assets/svg/unarchive.svg').default}
                  title={'unarchive'}
                  onClick={setUnArchiveModal}
                />
                <i
                  className='fas fa-trash-alt'
                  title={'delete'}
                  onClick={setDeleteModal}
                />
              </div>
            </div>
          ) : (
            ''
          )}
          <Table
            className={`align-items-center table-flush employee-table ${
              areArchivedEmployees && 'archived'
            }`}
            responsive
          >
            <thead className='thead-light'>
              <tr>
                <th className='pl-pt-0'>
                  <div className='custom-control custom-checkbox'>
                    <input
                      className='custom-control-input'
                      id={
                        areArchivedEmployees
                          ? 'archivedCustomCheck'
                          : 'customCheck'
                      }
                      type='checkbox'
                      checked={
                        areArchivedEmployees
                          ? selectedArchivedUsers?.length === data?.length
                          : selectedActiveUsers?.length ===
                              data?.filter(
                                ({ roles }) => !roles?.includes('OWNER')
                              )?.length && selectedActiveUsers?.length !== 0
                      }
                      onClick={(e) => {
                        if (!areArchivedEmployees) {
                          setSelectedActiveUsers(
                            e?.target?.checked
                              ? data
                                  ?.filter(
                                    ({ roles }) => !roles?.includes('OWNER')
                                  )
                                  ?.map(({ uid }) => uid)
                              : []
                          );
                        } else {
                          setSelectedArchivedUsers(
                            e?.target?.checked
                              ? data?.map(({ uid }) => uid)
                              : []
                          );
                        }
                      }}
                      disabled={
                        areArchivedEmployees
                          ? false
                          : data?.filter(({ roles }) =>
                              roles?.includes('OWNER')
                            )?.length > 0 && data?.length === 1
                      }
                    />
                    <label
                      className='custom-control-label'
                      htmlFor={
                        areArchivedEmployees
                          ? 'archivedCustomCheck'
                          : 'customCheck'
                      }
                    ></label>
                  </div>
                </th>
                <th scope='col'>Name</th>
                <th scope='col'>Email</th>
                <th scope='col'>Roles</th>
                <th scope='col'>Joined</th>

                {mploUser?.roles?.includes('OWNER') ? <th scope='col' /> : ''}
              </tr>
            </thead>
            <tbody>
              {data
                ?.filter(({ roles }) =>
                  mploUser?.roles?.includes('OWNER')
                    ? true
                    : !roles?.includes('OWNER')
                )
                ?.map(({ uid, name, roles, email, joined_on }) => (
                  <tr key={uid}>
                    <td className='pl-pt-0'>
                      <div className='custom-control custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id={uid}
                          type='checkbox'
                          checked={
                            !areArchivedEmployees
                              ? selectedActiveUsers?.includes(uid)
                              : selectedArchivedUsers?.includes(uid)
                          }
                          onClick={() =>
                            updateStateArray({
                              array: !areArchivedEmployees
                                ? selectedActiveUsers
                                : selectedArchivedUsers,
                              setArray: !areArchivedEmployees
                                ? setSelectedActiveUsers
                                : setSelectedArchivedUsers,
                              value: uid,
                            })
                          }
                          disabled={roles?.includes('OWNER')}
                        />
                        <label
                          className='custom-control-label'
                          htmlFor={uid}
                        ></label>
                      </div>
                    </td>
                    <td>{name}</td>
                    <td>{email}</td>
                    <td>
                      {roles?.map((role, index) => (
                        <>
                          <span
                            className={`${
                              roles?.includes('OWNER') && role !== 'EMPLOYEE'
                                ? 'font-italic disabled-text'
                                : ''
                            }`}
                          >
                            {`${role?.[0]?.toUpperCase()}${role
                              ?.substring(1)
                              ?.toLowerCase()}${
                              index + 1 < roles?.length ? ', ' : ''
                            }`}
                          </span>
                        </>
                      ))}
                    </td>

                    <td
                      className='toggled-date'
                      onClick={() =>
                        toggleDate({
                          uid,
                          array: toggledJoiningDates,
                          setArray: setToggledJoiningDates,
                        })
                      }
                    >
                      {toggledJoiningDates?.includes(uid)
                        ? moment(joined_on, 'YYYY-MM-DD').format('LL')
                        : moment(joined_on).isSame(
                            moment().format('YYYY-MM-DD')
                          )
                        ? 'today'
                        : moment(joined_on).from(moment(currentTime))}
                    </td>
                    {mploUser?.roles?.includes('OWNER') &&
                    !areArchivedEmployees ? (
                      <td>
                        <i
                          className='fas fa-users fa-lg admin-theme-color'
                          onClick={() => {
                            dispatch(setEmployeeData({ uid, roles, name }));
                            dispatch(setEmployeeRoleModal(true));
                          }}
                        />
                      </td>
                    ) : (
                      ''
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      ) : !areArchivedEmployees ? (
        <div className='no-data-div'>There are no users yet</div>
      ) : (
        <div className='no-data-div'>There are no archived users</div>
      )}

      <CustomModal
        isOpen={archiveModal}
        toggle={() => setArchiveModal(!archiveModal)}
        headerText='Archive Users'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={archiveMultipleUsersHandler}
      >
        Are you sure you want to archive selected users?
      </CustomModal>
      <CustomModal
        isOpen={unArchiveModal}
        toggle={() => setUnArchiveModal(!unArchiveModal)}
        headerText='Unarchive Users'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={unarchiveMultipleUsersHandler}
      >
        Are you sure you want to unarchive selected users?
      </CustomModal>
      <CustomModal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        headerText='Delete Users'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={deleteMultipleUsersHandler}
      >
        Deleting account will result in permanent deletion of all the user's
        data also, are you sure you want to delete selected users?
      </CustomModal>

      <CustomModal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        headerText='Delete Users'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={deleteMultipleUsersHandler}
      >
        Deleting account will result in permanent deletion of all the user's
        data also, are you sure you want to delete selected users?
      </CustomModal>
      <RolesModal />
    </>
  );
};

export default UserTable;
