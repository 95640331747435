/* eslint-disable array-callback-return */
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLogAddModal, setLogDate } from 'redux/actions/logs';
import './CustomDay.scss';
import { useHistory } from 'react-router-dom';

const CustomDay = ({ day, index, projectId, missed, logDetails }) => {
  const { workspaceAlias } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const date = moment()
    .startOf('isoweek')
    .add(index, 'days')
    .format('YYYY-MM-DD');
  const currentDayFlag = moment(date)?.isSame(moment().format('YYYY-MM-DD'));
  const logTime = () => {
    if (missed) {
      dispatch(setLogAddModal(true));
      dispatch(setLogDate(date));
      if (projectId && day) {
        push(
          `/${workspaceAlias}/user/project/${projectId}/logs?add=true&date=${date}`
        );
      }
    } else {
      dispatch(setLogDate(date));
      if (projectId && day) {
        push(`/${workspaceAlias}/user/project/${projectId}/logs`);
      }
    }
  };
  const totalLoggedTime = () => {
    let loggedTime = '';
    logDetails?.map(
      ({ log_date, time_logged: { hours, minutes, seconds } }) => {
        if (log_date === date) {
          loggedTime = `${
            hours === 1 ? hours + ' hr' : hours > 1 ? hours + ' hrs' : ''
          } ${
            minutes === 1
              ? minutes + ' min'
              : minutes > 1
              ? minutes + ' mins'
              : ''
          } ${
            seconds === 1
              ? seconds + ' sec'
              : seconds > 1
              ? seconds + ' secs'
              : ''
          }`;
        }
      }
    );
    return loggedTime;
  };
  return (
    <div className='days'>
      <span
        className={`custom-day ${missed ? 'missed' : 'logged'} ${
          currentDayFlag ? 'current-day' : ''
        } ${
          moment(date)?.isAfter(moment()) ? 'disabled-integration-button' : ''
        }`}
        onClick={logTime}
        title={
          missed
            ? `You have nothing logged on ${moment(day, 'ddd')
                .format('dddd')
                .toLowerCase()}`
            : totalLoggedTime()
        }
      >
        {day}
      </span>
      <span
        className={`custom-day-border ${missed ? 'missed' : 'logged'}`}
        onClick={missed ? logTime : () => {}}
        title={
          missed
            ? `You have nothing logged on ${moment(day, 'ddd')
                .format('dddd')
                .toLowerCase()}`
            : totalLoggedTime()
        }
      ></span>
    </div>
  );
};

export default CustomDay;
