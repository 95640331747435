import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getLogsUser } from 'utils/api/api-requests/user';
import { getLogsAdmin } from 'utils/api/api-requests/admin';
import { TIMESTAMP_WITH_ZONE } from 'constants/index';
import {
  setSelectedProjectId,
  setFromDateFilter,
  setToDateFilter,
} from 'redux/actions/projects';
import {
  setProjectLogsList,
  setAreLogsBeingLoaded,
  setLogsViewMode,
} from 'redux/actions/logs';
import moment from 'moment';
import LogsContainer from './LogsContainer/LogsContainer';
import './Logs.scss';
import { toast } from 'react-toastify';
import { Container, Row } from 'reactstrap';
import Header from 'Components/Headers/Header.js';
import {
  getCustomProjectLogs,
  setFilterDatesInverse,
} from 'utils/helper-functions';
import LogAddModal from './Modal/AddModal';
import LogEditModal from './Modal/EditModal';
import LogDeleteModal from './Modal/DeleteModal';

const Logs = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { fromDateFilter, toDateFilter } = useSelector((state) => state);

  useEffect(() => {
    if (!projectId || projectId <= 0) {
      const redirectRoute = `/${
        localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
          ? 'admin'
          : 'user'
      }/project/${parseInt(localStorage.getItem('projectId'))}/logs`;
      history?.push(redirectRoute);
    }
    dispatch(setSelectedProjectId(projectId));

    let filterDateFrom = moment(
      moment(moment(new Date()).subtract(1, 'month')).toDate(),
      TIMESTAMP_WITH_ZONE
    ).format('YYYY-MM-DD');
    let filterDateTo = moment(
      moment(moment(new Date())).toDate(),
      TIMESTAMP_WITH_ZONE
    ).format('YYYY-MM-DD');

    refreshData(
      projectId,
      fromDateFilter ?? filterDateFrom,
      toDateFilter ?? filterDateTo
    );

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, projectId, fromDateFilter, toDateFilter]);

  const refreshData = (projectId, fromDate, toDate) => {
    if (fromDate > toDate) {
      toast.error('Invalid date range selected');
      return;
    }
    if (localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin') {
      dispatch(setAreLogsBeingLoaded(true));
      getLogsAdmin(projectId, fromDate, toDate)
        .then(({ data }) => {
          dispatch(setProjectLogsList(getCustomProjectLogs(data, true)));
          dispatch(setAreLogsBeingLoaded(false));
        })
        .catch(() => toast?.error('Unable to fetch project logs list'));
    } else if (
      localStorage.getItem('currentUserRole')?.toLowerCase() === 'user'
    ) {
      dispatch(setAreLogsBeingLoaded(true));
      getLogsUser(projectId, fromDate, toDate)
        .then(({ data }) => {
          dispatch(setProjectLogsList(getCustomProjectLogs(data, false)));
          dispatch(setAreLogsBeingLoaded(false));
        })
        .catch(() => toast?.error('Unable to fetch project logs list'));
    }
    setFilterDatesInverse({
      fromDate: fromDateFilter,
      toDate: toDateFilter,
      dispatch,
      setLogsViewMode,
    });
  };
  useEffect(() => {
    dispatch(
      setFromDateFilter(
        moment(moment().week() + 1, 'w')
          .isoWeekday(1)
          .format('YYYY-MM-DD')
      )
    );
    dispatch(setToDateFilter(moment().format('YYYY-MM-DD')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='main-content'>
        <Header />
        <Container className='mt--5' fluid>
          <Row>
            <LogsContainer refreshData={refreshData} />
          </Row>
        </Container>
        <LogAddModal />
        <LogEditModal />
        <LogDeleteModal />
      </div>
    </>
  );
};

export default Logs;
