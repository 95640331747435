import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectsList } from 'redux/actions/projects';
import {
  getUserDashboard,
  updateTrackingLog,
} from 'utils/api/api-requests/user';
import { setTrackingLogs } from 'redux/actions/logs';
import './LogTimer.scss';
import { getTimerValue } from 'utils/helper-functions';
import { getTrackingLogs } from 'utils/api/api-requests/user';
const LogTimer = ({ projectId, startTime }) => {
  const trackingLogs = useSelector(({ trackingLogs }) => trackingLogs);
  const [timerValue, setTimerValue] = useState(
    getTimerValue(moment().diff(moment(startTime), 'seconds')) || '00:00:00'
  );
  const dispatch = useDispatch();

  useEffect(() => {
    let seconds = moment().diff(
      moment(
        trackingLogs?.filter(
          ({ project_uid }) => project_uid === projectId
        )?.[0]?.time_start
      ),
      'seconds'
    );

    setTimerValue(getTimerValue(seconds));
  }, [projectId, trackingLogs]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const time = moment(timerValue, 'HH:mm:ss')
        .add(1, 'second')
        .format('HH:mm:ss');

      setTimerValue(time);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [projectId, timerValue]);

  return (
    <div className='log-timer-container'>
      <span
        className='log-timer'
        onClick={() => {
          updateTrackingLog({
            projectId,
            trackingId: trackingLogs?.filter(
              ({ project_uid }) => projectId === project_uid
            )?.[0]?.tracking_uid,
            log: {
              time_end: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
            },
            finish: true,
          }).then(() => {
            getTrackingLogs().then(({ data }) => {
              dispatch(setTrackingLogs(data ?? []));
            });
            getUserDashboard()
              .then(({ data: { user_project_details } }) => {
                dispatch(setProjectsList(user_project_details));
              })
              .catch(() => {
                dispatch(setProjectsList([]));
              });
          });
        }}
      >
        <i className='fas fa-pause' />
        <div className='timer-count'>{timerValue}</div>
      </span>
    </div>
  );
};

export default LogTimer;
