import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClientList, setClientAddModal } from 'redux/actions/client';
import { getClients, addClient } from 'utils/api/api-requests/admin';
import { toast } from 'react-toastify';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import { Form } from 'reactstrap';
import { validate } from 'email-validator';
import validator from 'validator';

const AddModal = () => {
  const dispatch = useDispatch();
  const { clientAddModal } = useSelector((state) => state);
  const [clientEmail, setClientEmail] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [didAddClientButtonClick, setDidAddClientButtonClicked] =
    useState(false);

  const validateCredentials = () =>
    clientName && validate(clientEmail) && validator.isMobilePhone(clientPhone);
  const handleAddClient = (e) => {
    e?.preventDefault();
    setDidAddClientButtonClicked(true);
    if (validateCredentials()) {
      setDidAddClientButtonClicked(false);
      clientModalToggler();
      const client = {
        name: clientName,
        email: clientEmail,
        secondary_email: '',
        contact_number: clientPhone,
        secondary_contact_number: '',
      };
      addClient(client)
        .then(() => {
          toast.success('Client added successfully.');
          getClients()
            .then(({ data }) => dispatch(setClientList(data)))
            .catch((error) => toast.error(error));
        })
        .catch(() => toast.error('Unable to add client.'));
    }
  };

  const clientModalToggler = () => {
    dispatch(setClientAddModal(false));
    setClientEmail('');
    setClientName('');
    setClientPhone('');
    setDidAddClientButtonClicked(false);
  };
  return (
    <CustomModal
      isOpen={clientAddModal}
      toggle={clientModalToggler}
      headerText='Add client'
      cancelButtonText='Cancel'
      confirmButtonText='Add'
      confirmFunction={handleAddClient}
    >
      <Form onKeyPress={(e) => (e?.key === 'Enter' ? handleAddClient(e) : {})}>
        <CustomFormGroup
          type='email'
          name='userEmail'
          placeholder='Email'
          value={clientEmail}
          iconClassName='ni ni-email-83'
          setValue={setClientEmail}
          invalidText='Invalid email format'
          didSubmitButtonClick={didAddClientButtonClick}
          layout='admin'
        />

        <CustomFormGroup
          type='text'
          name='clientName'
          placeholder='Name'
          value={clientName}
          iconClassName='ni ni-single-02'
          setValue={setClientName}
          invalidText=' Client name is empty'
          didSubmitButtonClick={didAddClientButtonClick}
          layout='admin'
        />

        <CustomFormGroup
          type='phoneNumber'
          name='clientPhone'
          placeholder='Phone number'
          value={clientPhone}
          iconClassName='fas fa-phone-square-alt'
          setValue={setClientPhone}
          invalidText='Invalid Phone Number Format'
          didSubmitButtonClick={didAddClientButtonClick}
          layout='admin'
        />
      </Form>
    </CustomModal>
  );
};

export default AddModal;
