import React, { useState } from 'react';
import {
  UncontrolledTooltip,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover,
} from 'reactstrap';
import { Link } from 'react-router-dom';

const LoggedByEmployeeRow = ({
  loggedEmployeeList,
  name,
  hours,
  activeUsers,
  cardNo,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <>
      {!loggedEmployeeList ? (
        <div className='card-employee-logged-loader' />
      ) : (
        <>
          {loggedEmployeeList?.length > 0 ? (
            <div className='d-flex align-items-baseline'>
              <span className='logged-by'>{'Logged by'}</span>
              <div className='avatar-group d-flex align-items-center'>
                {loggedEmployeeList
                  ?.filter((_, index) => index <= 3)
                  ?.map(({ employee_name, employee_uid, name, uid }) => (
                    <>
                      <span
                        className='avatar avatar-sm rounded-circle'
                        id={`employee-${cardNo}-${employee_uid || uid}-${name
                          ?.split('')
                          ?.filter((e) => e?.trim()?.length)
                          ?.join('')}`}
                      >
                        <Link to={`user/${employee_uid || uid}/profile`}>
                          <img
                            alt={`${employee_name}-img`}
                            src={
                              require('assets/img/theme/no-user-1.jpeg').default
                            }
                          />
                        </Link>
                      </span>
                      <UncontrolledTooltip
                        delay={0}
                        target={`employee-${cardNo}-${
                          employee_uid || uid
                        }-${name
                          ?.split('')
                          ?.filter((e) => e?.trim()?.length)
                          ?.join('')}`}
                      >
                        {employee_name || name}
                      </UncontrolledTooltip>
                    </>
                  ))}
                {loggedEmployeeList?.length > 4 ? (
                  <>
                    <span
                      className='avatar avatar-sm rounded-circle cursor-pointer'
                      id={`employee-${cardNo}-logged-${name}`}
                      onClick={(e) => e?.preventDefault()}
                      onMouseEnter={() => setPopoverOpen(true)}
                    >
                      {`+${loggedEmployeeList?.length - 4}`}
                    </span>
                    <UncontrolledPopover
                      trigger='legacy'
                      onMouseLeave={() => setPopoverOpen(false)}
                      placement='left'
                      isOpen={popoverOpen}
                      target={`employee-${cardNo}-logged-${name}`}
                      toggle={toggle}
                      fade={true}
                      rootClose
                    >
                      <PopoverHeader>
                        <div className='d-flex flex-row align-items-baseline justify-content-between'>
                          Users List
                          <i
                            class='fas fa-times'
                            onClick={() => setPopoverOpen(false)}
                          />
                        </div>
                      </PopoverHeader>
                      <PopoverBody>
                        <div>
                          {loggedEmployeeList?.map(
                            (
                              { employee_name, employee_uid, name, uid },
                              index
                            ) => (
                              <>
                                <span
                                  className='avatar avatar-sm rounded-circle cursor-pointer'
                                  id={`employee-${cardNo}-${
                                    employee_uid || uid
                                  }-${index}`}
                                >
                                  <Link
                                    to={`user/${employee_uid || uid}/profile`}
                                  >
                                    <img
                                      alt={`${employee_name || name}-img`}
                                      src={
                                        require('assets/img/theme/no-user-1.jpeg')
                                          .default
                                      }
                                    />
                                  </Link>
                                </span>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`employee-${cardNo}-${
                                    employee_uid || uid
                                  }-${index}`}
                                >
                                  {employee_name || name}
                                </UncontrolledTooltip>
                              </>
                            )
                          )}
                        </div>
                      </PopoverBody>
                      <div className='text-center p-2'>
                        <span>
                          {hours
                            ? `Total hours logged: ${hours}`
                            : `Active users: ${activeUsers}`}
                        </span>
                      </div>
                    </UncontrolledPopover>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            <span className='no-user-logged'>No users logged time</span>
          )}
        </>
      )}
    </>
  );
};
export default LoggedByEmployeeRow;
