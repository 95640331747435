import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  associateUserToProjectRequest,
  getUsers,
  getTeam,
} from 'utils/api/api-requests/admin';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import { toast } from 'react-toastify';
import { Form, Label, Col, Row } from 'reactstrap';
import {
  setProjectTeamArray,
  setSelectedProjectId,
} from 'redux/actions/projects';
import { setProjectUserModal } from 'redux/actions/modals';
import {
  setEmployeeRoleCustomSelectArray,
  setProjectTeamCustomSelectArray,
} from 'redux/actions/employee';
import { useParams } from 'react-router';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';

const UserModal = ({ projectName }) => {
  const { projectId } = useParams();
  const { projectUserModal, projectTeamArray } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const [managerModal, setManagerModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);

  useEffect(() => {
    getUsers()
      .then(({ data }) => setUserList(data))
      .catch(() => toast?.error('Unable to fetch user list.'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (projectTeamArray?.length === 0) {
      setSelectedRole('');
    }
  }, [projectTeamArray?.length]);
  const toggleUser = (id) => {
    dispatch(setSelectedProjectId(id));
    dispatch(setProjectUserModal(!projectUserModal));
    dispatch(setEmployeeRoleCustomSelectArray([]));
    dispatch(setProjectTeamCustomSelectArray([]));
    setSelectedRole('');
    setSelectedEmployee('');

    setIsSubmitButtonClicked(false);
  };

  const associateProjectHelper = () => {
    toggleUser(projectId);
    associateUserToProjectRequest(projectId, selectedEmployee, {
      role: selectedRole,
      hours_allowed_per_week: 40,
    })
      .then(() => {
        dispatch(setProjectUserModal(false));
        setManagerModal(false);
        toast.success('Project associated to user successfully.');
        getTeam(projectId).then(({ data }) => {
          dispatch(setProjectTeamArray(data));
        });
      })
      .catch(() => {
        setManagerModal(false);
        toast?.error('Unable to associate project with user.');
      });
  };
  const handleAssociationWithProject = (e) => {
    e.preventDefault();
    setIsSubmitButtonClicked(true);
    if (selectedRole && selectedEmployee) {
      dispatch(setProjectUserModal(false));
      if (
        selectedRole === 'MANAGER' &&
        projectTeamArray?.map(({ role }) => role)?.includes('MANAGER')
      ) {
        setManagerModal(true);
      } else {
        associateProjectHelper();
      }
    }
  };
  const toggleManager = () => setManagerModal(!managerModal);
  return (
    <>
      <CustomModal
        isOpen={projectUserModal}
        toggle={toggleUser}
        headerText={`Associate Employee to ${projectName}`}
        cancelButtonText='Cancel'
        confirmButtonText='Associate'
        confirmFunction={handleAssociationWithProject}
        customSize='sm'
        customClass='associate-user-modal'
      >
        <Form
          onKeyPress={(e) =>
            e?.key === 'Enter' ? handleAssociationWithProject(e) : {}
          }
        >
          <Row className='justify-content-center'>
            <div className='select-option'>
              <Label className='pl-3 custom-styled-label' for='selectRole'>
                Role
              </Label>
              <Col>
                <CustomFormGroup
                  type='select'
                  name='selectRole'
                  id='selectRole'
                  value={selectedRole}
                  setValue={setSelectedRole}
                  iconClassName='fas fa-user-edit'
                  selectBoxText='Select role'
                  didSubmitButtonClick={isSubmitButtonClicked}
                  invalidText='Please select a role'
                  selectBoxArray={[
                    { uid: 'DEVELOPER', name: 'Developer' },
                    { uid: 'MANAGER', name: 'Manager' },
                    { uid: 'QA', name: 'QA' },
                  ]}
                />
              </Col>
            </div>

            <div className='select-option'>
              <Label className='pl-3 custom-styled-label' for='selectEmployee'>
                Employee
              </Label>
              <Col>
                <CustomFormGroup
                  type='select'
                  name='selectEmployee'
                  id='selectEmployee'
                  value={selectedEmployee}
                  setValue={setSelectedEmployee}
                  iconClassName='fas fa-users'
                  selectBoxText='Select employee'
                  didSubmitButtonClick={isSubmitButtonClicked}
                  invalidText='Please select an employee'
                  selectBoxArray={userList
                    ?.filter(
                      ({ uid }) =>
                        !projectTeamArray?.find((member) => member?.uid === uid)
                    )
                    ?.map(({ uid, name }) => ({
                      uid,
                      name,
                    }))}
                  disabled={
                    !userList
                      ?.filter(
                        ({ uid }) =>
                          !projectTeamArray?.find(
                            (member) => member?.uid === uid
                          )
                      )
                      ?.map(({ uid, name }) => ({
                        uid,
                        name,
                      }))?.length > 0
                  }
                />
              </Col>
            </div>
          </Row>
        </Form>
      </CustomModal>
      <CustomModal
        isOpen={managerModal}
        toggle={toggleManager}
        headerText='Change Manager'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={associateProjectHelper}
      >
        <p>
          {
            'There is already a manager of this project. Are you sure you want to change manager?'
          }
        </p>
      </CustomModal>
    </>
  );
};

export default UserModal;
