import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Header from 'Components/Headers/Admin/Admin';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  Spinner,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { getProject } from 'utils/api/api-requests/admin';
import moment from 'moment';
const ProjectDetails = () => {
  const { projectId } = useParams();
  const { push } = useHistory();
  const [project, setProject] = useState({});
  const [didProjectsLoad, setDidProjectsLoad] = useState(false);
  const [isDateInFromNowFormat, setIsDateInFromNowFormat] = useState(true);

  const { workspaceAlias } = useSelector((state) => state);

  useEffect(() => {
    getProject(projectId)
      .then(({ data }) => {
        setDidProjectsLoad(true);
        setProject(data);
      })
      .catch(() => {
        setDidProjectsLoad(false);
        setProject(null);
      });
  }, [projectId]);
  const renderClientAttribute = (key, value) => (
    <div className='client-attribute'>
      <div className='client-key'>{key}</div>
      <div className='client-value'>
        {key === 'commenced' ? (
          <span
            className={`${key === 'commenced' ? 'cursor-pointer' : ''}`}
            onClick={() => setIsDateInFromNowFormat(!isDateInFromNowFormat)}
          >
            {value}
          </span>
        ) : (
          <>{value}</>
        )}
      </div>
    </div>
  );
  return (
    <div>
      <Header />
      <Container className='mt--7' fluid>
        <Row className='mt-5 mb-5'>
          <Col className='mb-5' xl='12'>
            <Card className='shadow'>
              {!didProjectsLoad && !project ? (
                <div className='not-found-message'>Project not found.</div>
              ) : !didProjectsLoad ? (
                <div className='logs-spinner'>
                  <Spinner color='info' />
                </div>
              ) : (
                <>
                  <CardHeader className='border-0 details-card-header'>
                    <Row className='align-items-center'>
                      <div className='col custom-breadcrumb-admin'>
                        <span
                          onClick={() =>
                            push(`/${workspaceAlias}/admin/projects`)
                          }
                        >
                          Projects{' '}
                        </span>
                        / {project?.name}
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody className='details-card-body'>
                    <div className='client-details'>
                      {renderClientAttribute('name', project?.name)}
                      {project?.client?.name &&
                        renderClientAttribute('client', project?.client?.name)}
                      {project?.manager?.name &&
                        renderClientAttribute(
                          'manager',
                          project?.manager?.name
                        )}

                      {project?.associated_users_count !== null &&
                        project?.associated_users_count !== undefined &&
                        renderClientAttribute(
                          'associated users',
                          project?.associated_users_count
                        )}
                      {project?.start_date &&
                        renderClientAttribute(
                          'commenced',
                          isDateInFromNowFormat
                            ? moment(
                                project?.start_date,
                                'YYYY-MM-DD'
                              ).fromNow()
                            : moment(project?.start_date, 'YYYY-MM-DD').format(
                                'LL'
                              )
                        )}
                    </div>
                  </CardBody>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProjectDetails;
