import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';
import 'index.scss';
import 'font.scss';
import reducers from './redux/reducers';
import App from './App';
import thunk from 'redux-thunk';

ReactDOM.render(
  <Provider
    store={createStore(
      reducers,
      compose(
        applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__
          ? window.__REDUX_DEVTOOLS_EXTENSION__()
          : (f) => f
      )
    )}
  >
    <App />
  </Provider>,

  document.getElementById('root')
);
