import { combineReducers } from 'redux';
import {
  projectsList,
  projectObject,
  selectedProjectId,
  projectTeamArray,
  isProjectEditButtonClicked,
  selectedProjectObject,
  timeLogStartTime,
  timeLogEndTime,
  customSliderArray,
  workedOnWhat,
  reasonForLateSitting,
  selectedProjectName,
  fromDateFilter,
  toDateFilter,
  validateFilterDate,
  projectStartDate,
  projectEndDate,
  projectTeamCustomSelectArray,
  endDateFlag,
} from './projects';
import {
  currentUserRole,
  hasForgotPassword,
  mploUser,
  workspaceAlias,
  newWorkspaceObject,
  workspaceModal,
} from './general';
import {
  employeeDataHr,
  employeeData,
  employeeEmergencyContactInfo,
  employeeBankInfo,
  employeeRoleCustomSelectArray,
  userList,
  welcomeStatus,
} from './employee';
import {
  projectAddModal,
  projectEditModal,
  projectUserModal,
  projectDeleteModal,
  isMissingModalOpen,
  employeeRoleModal,
} from './modals';
import {
  projectLogsList,
  logsViewMode,
  areLogsBeingLoaded,
  logAddModal,
  logEditModal,
  logDeleteModal,
  logID,
  logDate,
  loggingProjects,
  trackingLogs,
  logsPageSize,
  activeView,
  isEditingTrackingLog,
} from './logs';
import {
  clientObject,
  clientList,
  clientAddModal,
  clientEditModal,
  clientDeleteModal,
} from './client';
import { slackChannelsArrays, integrationFlag } from './integration/slack';
export default combineReducers({
  projectsList,
  projectLogsList,
  logsViewMode,
  areLogsBeingLoaded,
  logAddModal,
  logEditModal,
  logDeleteModal,
  logID,
  logDate,
  loggingProjects,
  trackingLogs,
  logsPageSize,
  mploUser,
  workspaceAlias,
  activeView,
  isEditingTrackingLog,
  newWorkspaceObject,
  workspaceModal,

  projectObject,
  selectedProjectId,
  projectAddModal,
  projectEditModal,
  projectUserModal,
  projectDeleteModal,
  isMissingModalOpen,
  employeeRoleModal,
  clientAddModal,
  clientEditModal,
  clientDeleteModal,
  projectTeamArray,
  isProjectEditButtonClicked,
  selectedProjectObject,
  currentUserRole,
  hasForgotPassword,
  timeLogStartTime,
  timeLogEndTime,
  customSliderArray,
  workedOnWhat,
  reasonForLateSitting,
  selectedProjectName,
  fromDateFilter,
  toDateFilter,
  validateFilterDate,
  endDateFlag,

  projectStartDate,
  projectEndDate,
  employeeDataHr,
  employeeData,
  employeeEmergencyContactInfo,
  employeeBankInfo,
  employeeRoleCustomSelectArray,
  welcomeStatus,

  projectTeamCustomSelectArray,
  userList,
  clientObject,
  clientList,

  slackChannelsArrays,
  integrationFlag,
});
