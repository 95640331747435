import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';
import './CustomModal.scss';
const CustomModal = ({
  isOpen,
  toggle,
  headerText,
  cancelButtonText,
  confirmButtonText,
  confirmFunction,
  children,
  customClass,
  customSize,
  buttonIconClass,
  roleModal,
  noButtons,
  customBodyClass,
}) => (
  <Modal
    className={customClass ?? ''}
    isOpen={isOpen}
    toggle={toggle}
    size={customSize || 'md'}
    centered
  >
    <ModalHeader className='pb-0' toggle={toggle}>
      <h2 className={`${noButtons ? 'pl-3' : ''}`}>{headerText}</h2>
    </ModalHeader>
    <ModalBody
      className={`pb-0 ${roleModal ? 'pt-0' : ''} ${
        noButtons ? 'pl-0 pr-0' : ''
      }`}
    >
      {children}
    </ModalBody>

    <ModalFooter className={`${roleModal ? 'pt-0' : ''}`}>
      {noButtons ? (
        ''
      ) : (
        <>
          <Button
            color='primary'
            onClick={(e) => confirmFunction(e)}
            className={`custom-btn-size2 ${
              localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
                ? 'admin'
                : 'user'
            }-theme-button
         ${confirmButtonText === 'Add' ? 'btn-width' : ''}`}
          >
            {buttonIconClass && <i className={buttonIconClass} />}{' '}
            {confirmButtonText}
          </Button>
          <Button
            color='secondary'
            onClick={toggle}
            className='custom-btn-size2'
          >
            {cancelButtonText}
          </Button>
        </>
      )}
    </ModalFooter>
  </Modal>
);

export default CustomModal;
