import Logs from 'Components/Admin/Logs/Logs.js';
import ProjectContainer from 'Components/Admin/Project/ProjectContainer';
import Screen from 'Components/Admin/Project/Screen/Screen';
import User from 'Components/Admin/User/User';
import Client from 'Components/Admin/Client/Client';
import ClientDetails from 'Components/Admin/Client/ClientDetails';
import AdminDashboard from 'Components/Dashboards/Admin';
import UserDashboard from 'Components/Dashboards/User';
import ActivityList from 'Components/Common/ActivityList/ActivityList';
import UserDetails from 'Components/Admin/User/UserDetails';
import LogDetails from 'Components/Admin/Logs/LogDetails';
import Manage from 'Components/Admin/User/Manage/Manage';
import ProjectActivity from 'Components/Common/ActivityList/ProjectActivity';
import ProjectDetails from 'Components/Admin/Project/ProjectDetails';
import Redirect from 'Components/Auth/RedirectPage';
import IntegrationCards from 'Components/Integration/IntegrationCards';
import Login from 'Components/Auth/Login';
import Signup from 'Components/Auth/SignUp';
import ForgotPassword from 'Components/Auth/ForgotPassword';
import ChangePassword from 'Components/Auth/ChangePassword';
import UserProfile from 'Components/Admin/User/UserProfile/UserProfile';
import SecurityAndPrivacy from 'Components/Admin/User/UserProfile/SecurityAndPrivacy';

const routes = [
  {
    path: 'login',
    name: 'Login',
    component: Login,
    layout: `/auth`,
  },
  {
    path: 'signup',
    name: 'SignUp',
    component: Signup,
    layout: `/auth`,
  },
  {
    path: 'forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    layout: `/auth`,
  },
  {
    path: 'change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    layout: `/auth`,
  },
  {
    path: 'redirect',
    name: 'Redirect',
    icon: '',
    component: Redirect,
    layout: `/:workspace/admin`,
  },
  {
    path: 'integration',
    name: 'Integration',
    icon: '',
    component: IntegrationCards,
    layout: `/:workspace/admin`,
  },
  {
    path: 'index',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-danger',
    component: AdminDashboard,
    layout: `/:workspace/admin`,
  },
  {
    path: 'index',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-primary',
    component: UserDashboard,
    layout: `/:workspace/user`,
  },
  {
    path: 'manage',
    name: 'Manage',
    icon: 'ni ni-tv-2 text-primary',
    component: Manage,
    layout: `/:workspace/user`,
  },
  {
    path: 'projects/:projectId/activity',
    name: 'Project Activity',
    icon: 'ni ni-tv-2 text-primary',
    component: ProjectActivity,
    layout: `/:workspace/user`,
  },
  {
    path: 'users',
    name: 'Manage Employees',
    icon: 'ni ni-single-02 text-yellow',
    component: User,
    layout: `/:workspace/admin`,
  },
  {
    path: 'project/:projectId/details',
    name: 'Project Details',
    icon: 'ni ni-bullet-list-67 text-red',
    component: ProjectDetails,
    layout: `/:workspace/admin`,
  },
  {
    path: 'user/:userId',
    name: 'Employee',
    icon: 'ni ni-single-02 text-yellow',
    component: UserDetails,
    layout: `/:workspace/admin`,
  },
  {
    path: 'user/:userId',
    name: 'Employee',
    icon: 'ni ni-single-02 text-yellow',
    component: UserDetails,
    layout: `/:workspace/user`,
  },
  {
    path: 'project/:projectId/logs',
    name: 'Project Logs',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Logs,
    layout: `/:workspace/admin`,
  },
  {
    path: 'log/:logId',
    name: 'Project Log',
    icon: 'ni ni-bullet-list-67 text-red',
    component: LogDetails,
    layout: `/:workspace/admin`,
  },
  {
    path: 'log/:logId',
    name: 'Project Log',
    icon: 'ni ni-bullet-list-67 text-red',
    component: LogDetails,
    layout: `/:workspace/user`,
  },
  {
    path: 'project/:projectId/logs',
    name: 'Project Logs',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Logs,
    layout: `/:workspace/user`,
  },
  {
    path: 'projects',
    name: 'Projects',
    icon: 'ni ni-paper-diploma text-blue',
    component: ProjectContainer,
    layout: `/:workspace/admin`,
  },
  {
    path: 'clients/:clientId',
    name: 'Client',
    icon: 'ni ni-paper-diploma text-blue',
    component: ClientDetails,
    layout: `/:workspace/admin`,
  },
  {
    path: 'project/:projectId',
    name: 'Project Screen',
    icon: 'ni ni-paper-diploma text-blue',
    component: Screen,
    layout: `/:workspace/admin`,
  },
  {
    path: 'activity',
    name: 'Activity',
    icon: 'ni ni-paper-diploma text-blue',
    component: ActivityList,
    layout: `/:workspace/admin`,
  },
  {
    path: 'activity',
    name: 'Activity',
    icon: 'fas fa-tasks text-blue',
    component: ActivityList,
    layout: `/:workspace/user`,
  },
  {
    path: 'projects',
    name: 'Projects',
    icon: 'ni ni-paper-diploma text-blue',
    component: ProjectContainer,
    layout: `/:workspace/user`,
  },
  {
    path: 'clients',
    name: 'Manage Clients',
    icon: 'ni ni-badge text-default',
    component: Client,
    layout: `/:workspace/admin`,
  },
  {
    path: 'profile',
    name: 'User Profile',
    icon: 'ni ni-badge text-default',
    component: UserProfile,
    layout: `/:workspace/admin`,
  },
  {
    path: 'profile',
    name: 'User Profile',
    icon: 'ni ni-badge text-default',
    component: UserProfile,
    layout: `/:workspace/user`,
  },
  {
    path: 'security',
    name: 'Security And Privacy',
    icon: 'ni ni-badge text-default',
    component: SecurityAndPrivacy,
    layout: `/:workspace/admin`,
  },
  {
    path: 'security',
    name: 'Security And Privacy',
    icon: 'ni ni-badge text-default',
    component: SecurityAndPrivacy,
    layout: `/:workspace/user`,
  },
];
export default routes;
