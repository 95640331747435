import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useParams,
} from 'react-router-dom';
import AdminNavbar from 'Components/Common/Navbars/Admin.js';
import Sidebar from 'Components/Common/Sidebar/Sidebar.js';
import routes from 'routes.js';
import Toast from 'Components/Common/Toast/Toast';
import sideBarLinks from '../sidebar-links';
import { redirectToLandingPage } from 'utils/helper-functions';
import { useDispatch, useSelector } from 'react-redux';
import { whoamiApiRequest } from '../utils/api/api-requests/admin';
import { setMploUser } from '../redux/actions/general';
import { toast } from 'react-toastify';

const UserLayout = (props) => {
  const { workspace } = useParams();
  const mainContent = React.useRef(null);
  const history = useHistory();
  const mploUser = useSelector((state) => state.mploUser);
  const dispatch = useDispatch();
  useEffect(() => {
    const rolesArray = mploUser?.roles;
    if (!localStorage.getItem('jwt-token')) {
      redirectToLandingPage('login');
    }
    whoamiApiRequest(workspace)
      .then(
        ({ data: { name, roles, uid, email, workspace: workspaceJson } }) => {
          const mploUser = {
            name,
            roles,
            uid,
            email,
          };
          dispatch(setMploUser(mploUser));
          document.title = workspaceJson?.name;
          if (roles?.includes('ADMIN')) {
            if (
              localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            )
              localStorage.setItem('currentUserRole', 'admin');
            else if (roles?.includes('EMPLOYEE'))
              localStorage.setItem('currentUserRole', 'user');
          } else if (roles?.includes('EMPLOYEE')) {
            if (
              localStorage.getItem('currentUserRole')?.toLowerCase() === 'user'
            )
              localStorage.setItem('currentUserRole', 'user');
            else if (
              localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            )
              localStorage.setItem('currentUserRole', 'admin');
          }
        }
      )
      .catch(() => {
        toast.error('Failed to get logged in user data');
      });
    if (
      rolesArray?.length > 0 &&
      rolesArray?.includes('ADMIN') &&
      localStorage?.getItem('currentUserRole')?.toLowerCase() === 'admin'
    ) {
      history.push(`/${workspace}/admin/index`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, localStorage]);

  const getRoutes = (routes) => {
    return routes?.map((prop, key) => {
      if (prop.layout === `/:workspace/user`) {
        return (
          <Route
            path={`${prop.layout}/${prop.path}`}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return '';
  };

  return (
    <>
      <Toast />
      <Sidebar
        {...props}
        routes={sideBarLinks?.filter(
          ({ layout, name }) =>
            [`/user`, '/auth']?.includes(layout) || name === 'Logout'
        )}
        logo={{
          innerLink: `/${workspace}/user/index`,
          imgSrc: require('assets/svg/mplo_horizontal_grey.svg').default,
          imgAlt: '...',
        }}
        screenLayout='user'
      />
      <div className='main-content mb-5' ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />

        <Switch>
          {getRoutes(routes)}
          <Redirect from='*' to={`/${workspace}/user/index`} />
        </Switch>
      </div>
    </>
  );
};

export default UserLayout;
