import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { getClient } from 'utils/api/api-requests/admin';
import Header from 'Components/Headers/Admin/Admin';
import './ClientDetails.scss';
import { useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  Spinner,
} from 'reactstrap';
const ClientDetails = () => {
  const { workspaceAlias } = useSelector((state) => state);
  const { clientId } = useParams();
  const { push } = useHistory();
  const [client, setClient] = useState({});
  const [didClientsLoad, setDidClientsLoad] = useState(false);

  useEffect(() => {
    getClient(clientId)
      .then(({ data }) => {
        setDidClientsLoad(true);
        setClient(data);
      })
      .catch(() => {
        setDidClientsLoad(false);
        setClient(null);
      });
  }, [clientId]);
  const renderClientAttribute = (key, value) => (
    <div className='client-attribute'>
      <div className='client-key'>{key}</div>
      <div className='client-value'>{value}</div>
    </div>
  );
  return (
    <div>
      <Header />
      <Container className='mt--7' fluid>
        <Row className='mt-5 mb-5'>
          <Col className='mb-5' xl='12'>
            <Card className='shadow'>
              {!didClientsLoad && !client ? (
                <div className='not-found-message'>Client not found.</div>
              ) : !didClientsLoad ? (
                <div className='logs-spinner'>
                  <Spinner color='info' />
                </div>
              ) : (
                <>
                  <CardHeader className='border-0 details-card-header'>
                    <Row className='align-items-center'>
                      <div className='col custom-breadcrumb-admin'>
                        <span
                          onClick={() =>
                            push(`/${workspaceAlias}/admin/clients`)
                          }
                        >
                          Clients
                        </span>{' '}
                        / {client?.name}
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody className='details-card-body'>
                    <div className='client-details'>
                      {renderClientAttribute('name', client?.name)}
                      {client?.organization_name &&
                        renderClientAttribute(
                          'destination',
                          client?.designation
                        )}
                      {client?.organization_name &&
                        renderClientAttribute(
                          'organization',
                          client?.organization_name
                        )}

                      {client?.email &&
                        renderClientAttribute('email', client?.email)}
                      {client?.secondary_email &&
                        renderClientAttribute(
                          'secondary email',
                          client?.secondary_email
                        )}
                      {client?.contact_number &&
                        renderClientAttribute(
                          'contact number',
                          client?.contact_number
                        )}
                      {client?.secondary_contact_number &&
                        renderClientAttribute(
                          'secondary contact number',
                          client?.secondary_contact_number
                        )}
                    </div>
                  </CardBody>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ClientDetails;
