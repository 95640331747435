import React, { useState, useEffect } from 'react';
import {
  getProjects,
  updateProject,
  getClients,
} from 'utils/api/api-requests/admin';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import { Form, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import moment from 'moment';
import { setProjectEditModal } from 'redux/actions/modals';
import { setProjectsList, setSelectedProjectId } from 'redux/actions/projects';
import { setValidatedDateRange } from 'utils/helper-functions';
import { setProjectEndDate } from 'redux/actions/projects';

const EditModal = () => {
  const { selectedProjectId, projectEditModal, projectEndDate } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();
  const [clients, setClients] = useState([]);

  const [projectName, setProjectName] = useState('');
  const [projectStartDate, setProjectStartDate] = useState(
    moment().format('YYYY-MM-DD')
  );

  const [clientId, setClientId] = useState('');
  const [managerId, setManagerId] = useState('');

  const [endDateFlag, setEndDateFlag] = useState(projectEndDate ? true : false);
  const [didUpdateButtonClick, setDidUpdateButtonClick] = useState(false);
  const [updatedFlag, setUpdatedFlag] = useState(false);
  const [clientSpecificFlag, setClientSpecificFlag] = useState(false);

  useEffect(() => {
    getClients().then(({ data }) => setClients(data));
    getProjects().then(({ data }) => {
      dispatch(setProjectsList(data));
      const project = data?.filter(({ uid }) => uid === selectedProjectId)?.[0];
      setProjectName(project?.name || '');
      setProjectStartDate(project?.start_date || '');
      setEndDateFlag(projectEndDate ? true : false);
      setClientId(project?.client?.uid);
      setClientSpecificFlag(project?.client?.uid ? true : false);
      setManagerId(project?.manager?.uid);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId]);

  const setEndDateFlagHandler = (value) => {
    if (value)
      dispatch(
        setProjectEndDate(
          moment(projectStartDate, 'YYYY-MM-DD')
            .add(7, 'days')
            .format('YYYY-MM-DD')
        )
      );
    setEndDateFlag(value);
    setUpdatedFlag(true);
  };

  const toggleEdit = (e) => {
    e?.preventDefault();
    dispatch(setProjectEditModal(!projectEditModal));
    dispatch(setSelectedProjectId(null));
    dispatch(setProjectEndDate(''));
  };
  const handleAPIRequest = (e) => {
    e?.preventDefault();
    setDidUpdateButtonClick(true);
    if (projectName && updatedFlag && (clientSpecificFlag ? clientId : true)) {
      setDidUpdateButtonClick(false);
      const projectObject = {
        name: projectName,
        client_uid: clientSpecificFlag ? clientId : null,
        manager_uid: managerId,
        start_date: moment(projectStartDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        end_date: endDateFlag
          ? moment(projectEndDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : null,
      };

      updateProject(selectedProjectId, projectObject)
        .then(() => {
          toast.success('Project updated successfully.');
          toggleEdit();
          getProjects().then(({ data }) => dispatch(setProjectsList(data)));
        })
        .catch(() => {
          toast.error('Failed to update project.');
          toggleEdit();
        });

      dispatch(setProjectEditModal(false));
    }
  };

  return (
    <>
      <CustomModal
        isOpen={projectEditModal}
        toggle={toggleEdit}
        headerText='Edit project'
        cancelButtonText='Cancel'
        confirmButtonText='Update'
        confirmFunction={handleAPIRequest}
      >
        <Form
          onKeyPress={(e) => (e?.key === 'Enter' ? handleAPIRequest(e) : {})}
        >
          <Label className='custom-styled-label'>Details</Label>
          <CustomFormGroup
            type='text'
            name='projectName'
            id='name'
            placeholder='Project name'
            value={projectName}
            setValue={(value) => {
              setProjectName(value);
              setDidUpdateButtonClick(false);
              setUpdatedFlag(true);
            }}
            iconClassName='fas fa-project-diagram'
            invalidText='Project name is empty'
            didSubmitButtonClick={didUpdateButtonClick}
            layout='admin'
          />

          {clientSpecificFlag ? (
            <CustomFormGroup
              type='select'
              name='selectClient'
              id='selectClient'
              value={clientId}
              setValue={(value) => {
                setClientId(value);
                setDidUpdateButtonClick(false);
                setUpdatedFlag(true);
              }}
              iconClassName='fas fa-users'
              invalidText='No Client is selected'
              selectBoxText='Select client'
              selectBoxArray={clients}
              didSubmitButtonClick={didUpdateButtonClick}
              layout='admin'
            />
          ) : (
            ''
          )}
          <div className='d-flex flex-row justify-content-end align-item-center mb-2'>
            <label className='mr-3 mb-0 custom-styled-label'>
              Client specific project
            </label>
            <label className='mb-0 custom-toggle custom-parent-toggler'>
              <input
                type='checkbox'
                onClick={() => {
                  setClientSpecificFlag(!clientSpecificFlag);
                  setDidUpdateButtonClick(false);
                  setUpdatedFlag(true);
                }}
                checked={clientSpecificFlag}
              />
              <span className='custom-toggle-slider rounded-circle custom-child-toggler' />
            </label>
          </div>

          <Label className='custom-styled-label'>{`${
            endDateFlag ? 'Duration' : 'Commenced date'
          }`}</Label>

          <CustomFormGroup
            type='date'
            name='startDate'
            id='startDate'
            placeholder='project name'
            value={projectStartDate}
            setValue={(value) => {
              setProjectStartDate(value);
              setDidUpdateButtonClick(false);
              setUpdatedFlag(true);
              setValidatedDateRange({
                date1: value,
                date2: projectEndDate,
                setDate2: setProjectEndDate,
              });
            }}
            iconClassName='fas fa-calendar-day'
            invalidText='Project start date is empty'
            didSubmitButtonClick={didUpdateButtonClick}
            layout='admin'
          />

          {endDateFlag ? (
            <CustomFormGroup
              type='date'
              name='endDate'
              id='endDate'
              placeholder='project name'
              value={projectEndDate}
              setValue={(value) => {
                setProjectEndDate(value);
                setDidUpdateButtonClick(false);
                setUpdatedFlag(true);
              }}
              iconClassName='fas fa-calendar-week'
              invalidText='Project end date is empty'
              didSubmitButtonClick={didUpdateButtonClick}
              layout='admin'
              min={projectStartDate}
            />
          ) : (
            ''
          )}
          <div className='d-flex flex-row justify-content-end align-item-center mb-2'>
            <label className='mr-4 mb-0 custom-styled-label'>
              Close ended project
            </label>
            <label className='mb-0 custom-toggle custom-parent-toggler'>
              <input
                type='checkbox'
                onClick={() => setEndDateFlagHandler(!endDateFlag)}
                checked={endDateFlag}
              />
              <span className='custom-toggle-slider rounded-circle custom-child-toggler' />
            </label>
          </div>
          {!updatedFlag && didUpdateButtonClick ? (
            <div className='custom-invalid-feedback-text'>
              You haven't updated any value. Please change any value to update
              the project.
            </div>
          ) : (
            ''
          )}
        </Form>
      </CustomModal>
    </>
  );
};

export default EditModal;
