import React, { useState } from 'react';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';

import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { updatePassword } from 'utils/api/api-requests/auth';

const ChangePassword = () => {
  const [message, setMessage] = useState('Password is empty');
  const [passwordUpdatedFlag, setPasswordUpdatedFlag] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const history = useHistory();

  const setNewPasswordHandler = (value) => {
    setNewPassword(value);
    setIsSubmitButtonClicked(false);
  };
  const setOldPasswordHandler = (value) => {
    setOldPassword(value);
    setIsSubmitButtonClicked(false);
  };
  const setConfirmPasswordHandler = (value) => {
    setConfirmPassword(value);
    setIsSubmitButtonClicked(false);
  };
  const changePasswordHandler = (e) => {
    setIsSubmitButtonClicked(true);
    e.preventDefault();
    if (newPassword === confirmPassword && oldPassword) {
      setIsSubmitButtonClicked(false);
      updatePassword({
        new_password: newPassword,
        old_password: oldPassword,
      })
        .then(() => {
          setPasswordUpdatedFlag(true);
          setIsSubmitButtonClicked(false);
          localStorage.clear();
        })
        .catch(() => toast.error('Failed to reset password'));
    } else setMessage('Passwords must match');
  };
  const loginHandler = (e) => {
    e.preventDefault();
    history.push('/auth/login');
  };

  return (
    <>
      {passwordUpdatedFlag ? (
        <>
          <div className='confirmation-page'>
            <div className='confirmation-page-content'>
              <div class='confirmation-page__sub_heading mb-4'>
                Your password has been successfully updated. Please login again
                to verify.
              </div>
              <div>
                <input
                  className='custom-fw-btn'
                  type='submit'
                  value='Login'
                  onClick={(e) => loginHandler(e)}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <CustomFormGroup
            type='password'
            name='oldPassword'
            id='oldPassword'
            placeholder='Old Password'
            value={oldPassword}
            setValue={setOldPasswordHandler}
            iconClassName='fas fa-lock'
            invalidText='Password is empty'
            didSubmitButtonClick={isSubmitButtonClicked}
          />
          <CustomFormGroup
            type='password'
            name='newPassword'
            id='newPassword'
            placeholder='New Password'
            value={newPassword}
            setValue={setNewPasswordHandler}
            iconClassName='fas fa-key'
            invalidText={message}
            didSubmitButtonClick={isSubmitButtonClicked}
          />
          <CustomFormGroup
            type='password'
            name='confirmPassword'
            id='confirmPassword'
            placeholder='Confirm Password'
            value={confirmPassword}
            setValue={setConfirmPasswordHandler}
            iconClassName='ni ni-lock-circle-open'
            invalidText={message}
            didSubmitButtonClick={isSubmitButtonClicked}
          />
          <div className='custom-input-group'>
            <input
              className='custom-fw-btn'
              type='submit'
              value='Change Password'
              onClick={(e) => changePasswordHandler(e)}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ChangePassword;
