import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useParams,
} from 'react-router-dom';
import AdminNavbar from 'Components/Common/Navbars/Admin';
import Sidebar from 'Components/Common/Sidebar/Sidebar.js';
import routes from 'routes';
import Toast from 'Components/Common/Toast/Toast';
import sideBarLinks from 'sidebar-links';
import { whoamiApiRequest } from 'utils/api/api-requests/admin';
import { toast } from 'react-toastify';
import { setMploUser } from '../redux/actions/general';
import { useDispatch, useSelector } from 'react-redux';

const AdminLayout = (props) => {
  const dispatch = useDispatch();
  const mainContent = React.useRef(null);
  const history = useHistory();
  const { workspace } = useParams();
  const { mploUser, employeeRoleModal } = useSelector((state) => state);

  useEffect(() => {
    if (!localStorage.getItem('jwt-token')) {
      history.push('/auth/login');
    }
    whoamiApiRequest(workspace)
      .then(
        ({ data: { name, roles, uid, email, workspace: workspaceJson } }) => {
          //let { name, roles, uid, email } = data;
          const mploUser = {
            name,
            roles,
            uid,
            email,
          };
          dispatch(setMploUser(mploUser));
          document.title = workspaceJson?.name;
          if (roles?.includes('ADMIN')) {
            if (
              localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            )
              localStorage.setItem('currentUserRole', 'admin');
            else if (roles?.includes('EMPLOYEE'))
              localStorage.setItem('currentUserRole', 'user');
          } else if (roles?.includes('EMPLOYEE')) {
            if (
              localStorage.getItem('currentUserRole')?.toLowerCase() === 'user'
            )
              localStorage.setItem('currentUserRole', 'user');
            else if (
              localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            )
              localStorage.setItem('currentUserRole', 'admin');
          }
        }
      )
      .catch(() => {
        toast.error('Failed to get logged in user data');
      });
    const rolesArray = mploUser?.roles;
    if (
      rolesArray?.length > 0 &&
      rolesArray?.includes('EMPLOYEE') &&
      localStorage?.getItem('currentUserRole')?.toLowerCase() === 'user'
    )
      history.push(`/${workspace}/user/index`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, localStorage, employeeRoleModal]);

  const getRoutes = (routes) => {
    return routes?.map((prop, key) => {
      if (prop.layout === `/:workspace/admin`) {
        return (
          <Route
            path={`${prop.layout}/${prop.path}`}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return '';
  };

  return (
    <>
      <Toast />

      <Sidebar
        {...props}
        routes={sideBarLinks?.filter(
          ({ layout, views, name }) =>
            (layout?.includes(`/admin`)
              ? name === 'Integration'
                ? mploUser?.roles?.includes('OWNER')
                  ? true
                  : false
                : true
              : false) ||
            views?.map((view) =>
              view?.layout?.includes(`/${workspace}/admin`)
            ) ||
            name === 'Logout'
        )}
        logo={{
          innerLink: `/${workspace}/admin/index`,
          imgSrc: require('assets/svg/mplo_horizontal_grey.svg').default,
          imgAlt: '...',
        }}
        screenLayout='admin'
      />
      <div className='main-content mb-5' ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from='*' to='' />
        </Switch>
      </div>
    </>
  );
};

export default AdminLayout;
