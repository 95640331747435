import React, { useEffect, useState } from 'react';
import AdminHeader from 'Components/Headers/Admin/Admin';
import { inviteUser } from 'utils/api/api-requests/admin';
import { toast } from 'react-toastify';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Form,
  Spinner,
} from 'reactstrap';
import { validate } from 'email-validator';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import UserTable from './Table/Table';
import { useSelector } from 'react-redux';

const User = () => {
  const { userList } = useSelector((state) => state);

  const [inviteModal, setInviteModal] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [spinnerBackDrop, setSpinnerBackDrop] = useState(false);
  const [didInviteButtonClick, setDidInviteButtonClick] = useState(false);
  const [showArchivedEmployees, setShowArchivedEmployees] = useState(false);
  const [activeUsers, setActiveUsers] = useState([]);
  const [inActiveUsers, setInActiveUsers] = useState([]);
  const handleInvite = (e) => {
    setDidInviteButtonClick(true);
    e?.preventDefault();
    if (validate(userEmail)) {
      setDidInviteButtonClick(false);
      setSpinnerBackDrop(true);
      inviteUser({ email: userEmail })
        .then(() => {
          toast.success('Invite sent successfully');
          setInviteModal(!inviteModal);
          setUserEmail('');
          setSpinnerBackDrop(false);
        })
        .catch(() => {
          toast.error('Unable to send invite');
          setInviteModal(!inviteModal);
          setUserEmail('');
          setSpinnerBackDrop(false);
        });
    }
  };

  const toggleInviteModal = () => {
    setInviteModal(!inviteModal);
    setUserEmail('');
    setSpinnerBackDrop(false);
  };
  const updateUsersStatus = (data) => {
    setActiveUsers(
      data?.filter(
        ({ user_type, is_archived }) =>
          !['ADMIN', 'SUPER_ADMIN']?.includes(user_type) && !is_archived
      )
    );
    setInActiveUsers(
      data?.filter(
        ({ user_type, is_archived }) =>
          !['ADMIN', 'SUPER_ADMIN']?.includes(user_type) && is_archived
      )
    );
  };
  useEffect(() => {
    updateUsersStatus(userList);
  }, [userList]);
  return (
    <>
      <div className='main-content'>
        <AdminHeader />
        <Container className='mt--7' fluid>
          <Row className='mt-5 mb-5'>
            <Col className='mb-5' xl='12'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <Row className='align-items-center'>
                    <div className='col'>
                      <h3 className='mb-0'>Employees</h3>
                    </div>

                    <div className='col text-right'>
                      <Button
                        className='admin-theme-button custom-btn-size'
                        color='primary'
                        onClick={() => setInviteModal(true)}
                      >
                        <span>
                          <i className='fas fa-plus' /> Invite
                        </span>
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <UserTable
                  areArchivedEmployees={false}
                  data={activeUsers}
                  updateUsersStatus={updateUsersStatus}
                />

                <div className='d-flex justify-content-center '>
                  <div
                    className='archive-toggle'
                    onClick={() =>
                      setShowArchivedEmployees(!showArchivedEmployees)
                    }
                  >
                    {!showArchivedEmployees ? 'Show' : 'Hide'} archived users
                  </div>
                </div>

                {showArchivedEmployees ? (
                  <UserTable
                    areArchivedEmployees={true}
                    data={inActiveUsers}
                    updateUsersStatus={updateUsersStatus}
                  />
                ) : (
                  ''
                )}
              </Card>
            </Col>
          </Row>
          <Modal isOpen={inviteModal} toggle={toggleInviteModal} centered>
            <ModalHeader className='p-3' toggle={toggleInviteModal}>
              <h2>Invite user</h2>
            </ModalHeader>
            <ModalBody className='p-3'>
              <Form
                onKeyPress={(e) => (e?.key === 'Enter' ? handleInvite(e) : {})}
              >
                <CustomFormGroup
                  type='email'
                  name='userEmail'
                  placeholder='Email'
                  value={userEmail}
                  iconClassName='ni ni-email-83'
                  setValue={setUserEmail}
                  invalidText='Invalid email address'
                  didSubmitButtonClick={didInviteButtonClick}
                  layout='admin'
                />
              </Form>
            </ModalBody>
            <ModalFooter className='p-3'>
              {spinnerBackDrop && userEmail ? (
                <div className='text-center'>
                  <Spinner color='success' size='sm' />
                </div>
              ) : (
                ''
              )}
              <Button
                className='admin-theme-button custom-btn-size2'
                color='primary'
                onClick={handleInvite}
              >
                Invite
              </Button>
              <Button
                color='secondary'
                onClick={toggleInviteModal}
                className='custom-btn-size2'
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default User;
