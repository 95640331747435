import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  setClientList,
  setClientObject,
  setClientEditModal,
} from 'redux/actions/client';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClients,
  deleteMultipleClients,
} from 'utils/api/api-requests/admin';
import { Spinner, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { updateStateArray } from 'utils/helper-functions';
import CustomModal from 'Components/Common/CustomModal/CustomModal';

const ClientTable = () => {
  const [areClientsBeingLoaded, setAreClientsBeingLoaded] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const { clientList, workspaceAlias } = useSelector((state) => state);

  useEffect(() => {
    setAreClientsBeingLoaded(true);
    getClients()
      .then(({ data }) => {
        dispatch(setClientList(data));
        setAreClientsBeingLoaded(false);
      })
      .catch((error) => {
        toast.error(error);
        setAreClientsBeingLoaded(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const editClientModalHandler = (clientObject) => {
    dispatch(setClientObject(clientObject));
    dispatch(setClientEditModal(true));
  };
  const deleteMultipleClientsHandler = () => {
    if (selectedClients.length > 0) {
      deleteMultipleClients(selectedClients)
        .then(() => {
          setDeleteModal(false);
          setSelectedClients([]);
          toast.success('Clients deleted successfully');
          getClients()
            .then(({ data }) => {
              dispatch(setClientList(data));
              setAreClientsBeingLoaded(false);
            })
            .catch((error) => {
              toast.error(error);
              setAreClientsBeingLoaded(false);
            });
        })
        .catch(() => {
          toast.error('Unable to delete selected Clients');
        });
    }
  };
  return (
    <>
      {areClientsBeingLoaded ? (
        <div className='logs-spinner'>
          <Spinner color='admin' />
        </div>
      ) : clientList?.length > 0 ? (
        <>
          {selectedClients?.length > 0 ? (
            <div className='admin-action-bar actions-bar'>
              <div className='delete-div'>
                <i
                  className='fas fa-trash-alt p-1'
                  title='delete'
                  onClick={() => setDeleteModal(true)}
                />
              </div>
            </div>
          ) : (
            ''
          )}
          <Table
            className='align-items-center table-flush clients-table'
            responsive
          >
            <thead className='thead-light'>
              <tr>
                <th className='pl-pt-0'>
                  <div className='custom-control custom-checkbox'>
                    <input
                      className='custom-control-input'
                      id='customCheck'
                      type='checkbox'
                      checked={clientList?.length === selectedClients?.length}
                      onClick={(e) => {
                        setSelectedClients(
                          e?.target?.checked
                            ? clientList?.map(({ uid }) => uid)
                            : []
                        );
                      }}
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='customCheck'
                    ></label>
                  </div>
                </th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Secondary Email</th>
                <th>Secondary Phone</th>
                <th className='w-5' scope='col' />
              </tr>
            </thead>
            <tbody>
              {clientList?.map(
                ({
                  uid,
                  name,
                  email,
                  contact_number,
                  secondary_email,
                  secondary_contact_number,
                }) => (
                  <tr key={uid}>
                    <td className='pl-pt-0'>
                      <div className='custom-control custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id={uid}
                          type='checkbox'
                          checked={selectedClients?.includes(uid)}
                          onClick={() =>
                            updateStateArray({
                              array: selectedClients,
                              setArray: setSelectedClients,
                              value: uid,
                            })
                          }
                        />
                        <label
                          className='custom-control-label'
                          htmlFor={uid}
                        ></label>
                      </div>
                    </td>
                    <td>
                      <Link
                        className='custom-link-class admin-theme-color'
                        to={`/${workspaceAlias}/admin/clients/${uid}`}
                      >
                        {name}
                      </Link>
                    </td>
                    <td>{email || '-'}</td>
                    <td>{contact_number || '-'}</td>
                    <td>{secondary_email || '-'}</td>
                    <td>{secondary_contact_number || '-'}</td>
                    <td className='client-edit-button'>
                      <div>
                        <i
                          className='fas fa-pencil-alt fa-lg edit-btn client-edit-btn text-admin'
                          onClick={() =>
                            editClientModalHandler({
                              uid,
                              name,
                              email,
                              contact_number,
                              secondary_email,
                              secondary_contact_number,
                            })
                          }
                        />
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </>
      ) : (
        <div className='no-data-div'>There are no clients yet</div>
      )}
      <CustomModal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        headerText='Delete Clients'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={deleteMultipleClientsHandler}
      >
        Are you sure you want to delete selected clients?
      </CustomModal>
    </>
  );
};

export default ClientTable;
