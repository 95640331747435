import React, { useState, useEffect } from 'react';
import Header from 'Components/Headers/Header';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  CardBody,
  Input,
  FormGroup,
  InputGroup,
} from 'reactstrap';
import { changeUsername } from 'utils/api/api-requests/general';
import { whoamiApiRequest } from 'utils/api/api-requests/admin';
import { setMploUser } from 'redux/actions/general';
import { useDispatch } from 'react-redux';

const UserProfile = () => {
  let lastWorkspace = localStorage.getItem('workspace');
  const history = useHistory();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [editNameFlag, setEditNameFlag] = useState(false);

  useEffect(() => {
    whoamiApiRequest(lastWorkspace)
      .then(({ data: { name, roles, uid, email } }) => {
        setUserEmail(email);
        setUserName(name);
        const mploUser = {
          name,
          roles,
          uid,
          email,
        };
        dispatch(setMploUser(mploUser));
      })
      .catch(() => {
        toast.error('Failed to get logged in user data');
        history.push('/auth/login');
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changeUsernameHandler = (e) => {
    e.preventDefault();
    let name = { name: userName };
    setEditNameFlag(false);
    changeUsername(name)
      .then(() => {
        toast.success('Username Changed');
        whoamiApiRequest(lastWorkspace)
          .then(({ data: { name, roles, uid, email } }) => {
            setUserEmail(email);
            const mploUser = {
              name,
              roles,
              uid,
              email,
            };
            dispatch(setMploUser(mploUser));
          })
          .catch(() => {
            toast.error('Failed to get logged in user data');
            history.push('/auth/login');
          });
      })
      .catch(() => toast.error('Failed to change username'));
  };
  return (
    <>
      <Header />
      <Container className='mt--7' fluid>
        <Row className='mt-5 mb-5'>
          <Col className='mb-5 mb-xl-0' xl='12'>
            <Card className='shadow'>
              <CardHeader>
                <Row className='align-items-center'>
                  <div className='col'>
                    <h3 className='mb-0'>Profile</h3>
                  </div>

                  <div className='col text-right'></div>
                </Row>
              </CardHeader>
              <CardBody className='profile-card-body'>
                <div className='custom-styled-label mb-label'>Display name</div>
                <FormGroup className='mb-5 position-relative'>
                  <InputGroup className='input-group-alternative'>
                    <Input
                      className='input-not-select form-control'
                      value={userName}
                      onChange={(e) => {
                        setUserName(e?.target?.value);
                        setEditNameFlag(true);
                      }}
                    />
                  </InputGroup>
                  {editNameFlag ? (
                    <span
                      color='primary'
                      className='save-name-btn admin-theme-color cursor-pointer'
                      onClick={changeUsernameHandler}
                    >
                      Save
                    </span>
                  ) : (
                    ''
                  )}
                </FormGroup>
                <div className='custom-styled-label mb-label'>Email</div>
                <FormGroup className='mb-3'>
                  <InputGroup className='input-group-alternative'>
                    <Input
                      className='input-not-select form-control'
                      value={userEmail}
                      disabled
                    />
                  </InputGroup>
                  <span className='email-warning'>
                    <i>Email cannot be changed.</i>
                  </span>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserProfile;
