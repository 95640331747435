import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from 'reactstrap';
import { setFromDateFilter, setToDateFilter } from 'redux/actions/projects';
import { setLogsViewMode } from 'redux/actions/logs';
import {
  setFilterDates,
  setValidatedDateRange,
  setFilterDatesInverse,
} from 'utils/helper-functions';
import moment from 'moment';

const ProjectLogTableFilter = ({ refreshData, currentView }) => {
  const { fromDateFilter, toDateFilter, selectedProjectId } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();
  const applyDateFilter = (e) => {
    e?.preventDefault();
    if (fromDateFilter <= toDateFilter) {
      refreshData(selectedProjectId, fromDateFilter, toDateFilter);
      dispatch(setLogsViewMode(currentView));
    }
  };
  useEffect(() => {
    setFilterDates({
      currentView,
      dispatch,
      setFromDateFilter,
      setToDateFilter,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView]);
  return (
    <>
      <Form
        onKeyPress={(e) => (e?.key === 'Enter' ? applyDateFilter(e) : {})}
        className='needs-validation'
        noValidate
      >
        <div className='date-input-container'>
          <div className='date-text'>From</div>
          <Input
            className='user-select-none'
            type='date'
            onChange={({ target: { value } }) => {
              dispatch(setFromDateFilter(value));
              setFilterDatesInverse({
                fromDate: value,
                toDate: toDateFilter,
                dispatch,
                setLogsViewMode,
              });
              setValidatedDateRange({
                date1: value,
                date2: toDateFilter,
                setDate2: (date) => dispatch(setToDateFilter(date)),
              });
            }}
            name='date'
            id='fromDate'
            value={fromDateFilter}
            placeholder='date placeholder'
          />
        </div>

        <div className='date-input-container'>
          <div className='date-text'>To</div>
          <Input
            className='user-select-none'
            onChange={({ target: { value } }) => {
              dispatch(setToDateFilter(value));

              setFilterDatesInverse({
                fromDate: fromDateFilter,
                toDate: value,
                dispatch,
                setLogsViewMode,
              });
            }}
            type='date'
            name='date'
            value={toDateFilter}
            min={fromDateFilter}
            max={moment().format('YYYY-MM-DD')}
            id='toDate'
            placeholder='date placeholder'
          />
        </div>
      </Form>
    </>
  );
};

export default ProjectLogTableFilter;
