export const setEmployeeDataHr = (employeeData) => ({
  type: 'SET_EMPLOYEE_DATA_HR',
  payload: employeeData,
});
export const setEmployeeEmergencyContactInfo = (
  employeeEmergencyContactInfo
) => ({
  type: 'SET_EMPLOYEE_EMERGENCY_CONTACT_INFO',
  payload: employeeEmergencyContactInfo,
});
export const setEmployeeBankInfo = (employeeBankInfo) => ({
  type: 'SET_EMPLOYEE_BANK_INFO',
  payload: employeeBankInfo,
});
export const setEmployeeRoleCustomSelectArray = (employeeRole) => ({
  type: 'SET_EMPLOYEE_ROLE_CUSTOM_SELECT_ARRAY',
  payload: employeeRole,
});
export const setProjectTeamCustomSelectArray = (projectTeam) => ({
  type: 'SET_PROJECT_TEAM_CUSTOM_SELECT_ARRAY',
  payload: projectTeam,
});

export const setUserList = (userList) => ({
  type: 'SET_USER_LIST',
  payload: userList,
});

export const setWelcomeStatus = (status) => ({
  type: 'SET_WELCOME_STATUS',
  payload: status,
});
export const setEmployeeData = (employeeData) => ({
  type: 'SET_EMPLOYEE_DATA',
  payload: employeeData,
});
