import { makeAPICall } from 'utils/api/api-utils';

/* PROJECT*/
import {
  PROJECT_URL,
  LOG_URL,
  ADD_LOG_URL,
  UPDATE_LOG_URL,
  DID_MISS_LOGS_TODAY,
  DID_MISS_LOGS_YESTERDAY,
  GET_USER_DASHBOARD,
  UPDATE_WELCOME_STATUS_URL,
  DELETE_MULTIPLE_LOGS,
} from 'utils/api/api-urls/user';
import {
  ADD_TRACKING_LOG_URL,
  GET_LOG_URL,
  GET_MANAGER_PROJECTS_ACTIVITY,
  GET_MANAGER_PROJECTS_LOGS,
  GET_TRACKING_LOGS_URL,
  UPDATE_TRACKING_LOG_URL,
  USER_ACTIVTY_URL,
} from '../api-urls/user';

export const getProjectsUser = () =>
  makeAPICall({
    url: PROJECT_URL,
    authorizedRequest: true,
  });

export const getLogsUser = (projectID, from, to) =>
  makeAPICall({
    url: LOG_URL(projectID, from, to),
    authorizedRequest: true,
  });

export const getLog = ({ projectId, logId }) =>
  makeAPICall({
    url: GET_LOG_URL({ projectId, logId }),
    authorizedRequest: true,
  });
export const addLogs = (projectID, projectLog) =>
  makeAPICall({
    method: 'POST',
    url: ADD_LOG_URL(projectID),
    authorizedRequest: true,
    data: projectLog,
  });

export const updateLog = (logID, selectedProjectId, projectLog) =>
  makeAPICall({
    method: 'PATCH',
    url: UPDATE_LOG_URL(logID, selectedProjectId),
    authorizedRequest: true,
    data: projectLog,
  });

export const deleteLog = (logID, projectId) =>
  makeAPICall({
    method: 'DELETE',
    url: UPDATE_LOG_URL(logID, projectId),
    authorizedRequest: true,
  });

export const deleteMultipleLogs = (logIds, projectId) =>
  makeAPICall({
    method: 'DELETE',
    url: DELETE_MULTIPLE_LOGS(projectId),
    authorizedRequest: true,
    data: logIds,
  });

export const updateWelcomeStatus = () =>
  makeAPICall({
    method: 'PATCH',
    url: UPDATE_WELCOME_STATUS_URL,
    authorizedRequest: true,
  });

export const didMissLogsToday = () =>
  makeAPICall({
    url: DID_MISS_LOGS_TODAY,
    authorizedRequest: true,
  });

export const didMissLogsYesterday = () =>
  makeAPICall({
    url: DID_MISS_LOGS_YESTERDAY,
    authorizedRequest: true,
  });

export const getUserDashboard = () =>
  makeAPICall({
    url: GET_USER_DASHBOARD,
    authorizedRequest: true,
  });

export const getTrackingLogs = () =>
  makeAPICall({
    url: GET_TRACKING_LOGS_URL,
    authorizedRequest: true,
  });
export const addTrackingLog = ({ projectId, log }) =>
  makeAPICall({
    method: 'POST',
    url: ADD_TRACKING_LOG_URL(projectId),
    authorizedRequest: true,
    data: log,
  });
export const updateTrackingLog = ({ projectId, trackingId, log, finish }) =>
  makeAPICall({
    method: 'PUT',
    url: `${UPDATE_TRACKING_LOG_URL({ projectId, trackingId })}${
      finish ? '?finish=true' : ''
    }`,
    authorizedRequest: true,
    data: log,
  });

export const editTrackingLog = (trackingId, projectId, workedOnWhat, finish) =>
  makeAPICall({
    method: 'PATCH',
    url: `${UPDATE_TRACKING_LOG_URL({ projectId, trackingId })}${
      finish ? '?finish=true' : ''
    }`,
    authorizedRequest: true,
    data: workedOnWhat,
  });
export const getManagerProjects = () =>
  makeAPICall({
    url: GET_MANAGER_PROJECTS_LOGS,
    authorizedRequest: true,
  });
export const getManagerProjectsActivity = (projectId, from, to) =>
  makeAPICall({
    url: GET_MANAGER_PROJECTS_ACTIVITY(projectId, from, to),
    authorizedRequest: true,
  });
export const getUserActivity = (from, to) =>
  makeAPICall({
    url: USER_ACTIVTY_URL(from, to),
    authorizedRequest: true,
  });
