import { API_URL } from 'utils/api/api-constants';
import { WORKSPACE } from 'constants/index';
const USER = `${API_URL}/${WORKSPACE}`;
const PROJECT_USER = `${API_URL}/${WORKSPACE}/project`;
const MANAGE = `${API_URL}/${WORKSPACE}/manage`;
export const PROJECT_URL = PROJECT_USER;

export const GET_LOG_URL = ({ projectId, logId }) =>
  `${PROJECT_USER}/${projectId}/log/${logId}`;
export const LOG_URL = (projectID, from, to) =>
  `${PROJECT_USER}/${projectID}/log?from=${from}&to=${to}`;
export const ADD_LOG_URL = (projectID) => `${PROJECT_USER}/${projectID}/log`;
export const UPDATE_LOG_URL = (logId, projectId) =>
  `${PROJECT_USER}/${projectId}/log/${logId}`;

export const GET_USER_DASHBOARD = `${USER}/dashboard`;
export const UPDATE_WELCOME_STATUS_URL = `${USER}/config/new_user/tips/disable`;
export const DID_MISS_LOGS_TODAY = `${USER}/logged/today`;
export const DID_MISS_LOGS_YESTERDAY = `${USER}/logged/yesterday`;

export const ADD_TRACKING_LOG_URL = (projectId) =>
  `${PROJECT_URL}/${projectId}/log/tracking`;
export const UPDATE_TRACKING_LOG_URL = ({ projectId, trackingId }) =>
  `${PROJECT_URL}/${projectId}/log/tracking/${trackingId}`;
export const GET_TRACKING_LOGS_URL = `${USER}/log/incomplete`;
export const DELETE_MULTIPLE_LOGS = (projectId) =>
  `${PROJECT_USER}/${projectId}/log`;

export const GET_MANAGER_PROJECTS_LOGS = `${MANAGE}/project`;
export const GET_MANAGER_PROJECTS_ACTIVITY = (projectId, from, to) =>
  `${MANAGE}/project/${projectId}/activity?from=${from}&to=${to}`;
export const USER_ACTIVTY_URL = (from, to) =>
  `${API_URL}/${WORKSPACE}/activity?from=${from}&to=${to}`;
